import { Box, Stack, useMediaQuery } from '@mui/material';
import theme from '../../../theme';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { companyLogosGreaterThanGreat, companyLogosLessThanGreat } from '../utils';

const CompanyLogos = () => {
  const isLessThanGreat = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Stack
      py={{ xs: 5, md: 7 }}
      sx={{
        backgroundColor: 'primaryDark.150',
        // position: 'relative',
        mt: { xs: 5, md: 6 },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ ...LAYOUT_DEFAULTS }}
      >
        {isLessThanGreat ? (
          <>
            {companyLogosLessThanGreat.map((logo) => (
              <Box
                key={logo.src}
                component="img"
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
              />
            ))}
          </>
        ) : (
          <>
            {companyLogosGreaterThanGreat.map((logo) => (
              <Box
                key={logo.src}
                component="img"
                src={logo.src}
                alt={logo.alt}
                width={logo.width}
              />
            ))}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default CompanyLogos;
