import CreateInRealTimeSellerImage from '../../../../assets/img/public-pages/create-in-real-time-seller.svg';
import EliminateLegalFeesSellerImage from '../../../../assets/img/public-pages/eliminate-legal-fees-seller.svg';
import SignDigitallySellerImage from '../../../../assets/img/public-pages/sign-digitally-seller.svg';
import TransparentEditingSellerImage from '../../../../assets/img/public-pages/transparent-editing-seller.svg';
import AccessAtAnyTimeImage from '../../../../assets/img/public-pages/access-at-any-time-seller.svg';
import TransparentPaymentsSellerImage from '../../../../assets/img/public-pages/transparent-payments-seller.svg';
import StayInSyncSellerImage from '../../../../assets/img/public-pages/stay-in-sync-seller.svg';
import SecureProcessingSellerImage from '../../../../assets/img/public-pages/secure-processing-seller.svg';
import TransparentTransactionsSellerImage from '../../../../assets/img/public-pages/transparent-transactions-seller.svg';
import GetInsightsSellerImage from '../../../../assets/img/public-pages/get-insights-seller.svg';
import StandardizeYourProcessesSellerImage from '../../../../assets/img/public-pages/standardize-your-processes-seller.svg';
import ShareYourProgressSellerImage from '../../../../assets/img/public-pages/share-your-progress-seller.svg';
import ResolveIssuesSellerImage from '../../../../assets/img/public-pages/resolve-issues-seller.svg';
import CompleteMilestonesSellerImage from '../../../../assets/img/public-pages/complete-milestones-seller.svg';
import TrackProjectHealthSellerImage from '../../../../assets/img/public-pages/track-project-health-seller.svg';
import OnePlatformSellerImage from '../../../../assets/img/public-pages/one-platform-seller.svg';
import QuickUpdatesSellerImage from '../../../../assets/img/public-pages/quick-updates-seller.svg';
import AccessibleSupportSellerImage from '../../../../assets/img/public-pages/accessible-support-seller.svg';
import RecordYourActivitiesSellerImage from '../../../../assets/img/public-pages/record-your-activities-seller.svg';
import MakeDataDrivenDecisionsSellerImage from '../../../../assets/img/public-pages/make-data-driven-decisions-seller.svg';
import EasyResourcePlanningSellerImage from '../../../../assets/img/public-pages/easy-resource-planning-seller.svg';
import ClearCommunicationSellerImage from '../../../../assets/img/public-pages/clear-communication-seller.svg';
import ConsolidateYourProjectsSellerImage from '../../../../assets/img/public-pages/consolidate-your-projects-seller.svg';
import ReduceDataMicromanagementSellerImage from '../../../../assets/img/public-pages/reduce-data-micromanagement-seller.svg';
import { TextAndImageProps } from '../utils';

export const textsAndImagesDigitalContracts: TextAndImageProps[] = [
  {
    title: 'Create in Real Time',
    content:
      'Predefined fields let you quickly build your contract from the ground up - you only need to follow the terms and add details to finalize the process. Cooperate in real time and get inspired with industry standards for similar projects.',
    image: CreateInRealTimeSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of a contract review on spona',
  },
  {
    title: 'Eliminate Legal Fees',
    content:
      'There is no need to get a lawyer to mediate the negotiations process - our contracts are fully legally binding. All you need is to agree to the terms and conditions stated in the contract and sign it.',
    image: EliminateLegalFeesSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Illustration showing contract terms agreement on Spona platform.',
  },
  {
    title: 'Sign Digitally',
    content:
      'No more printing and scanning your documents. Once you’re satisfied with your contract terms, your contract will be available for signing through DocuSign.',
    image: SignDigitallySellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration showing digital signage on Spona',
  },
  {
    title: 'Transparent Editing',
    content:
      'Even after the contract has been signed, project milestones are fully editable through annexes. Whenever a change is made, the buyer will be notified to review and submit it. After signing your annex, all changes will be applied to your project dashboard.',
    image: TransparentEditingSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Illustration of editing contracts on Spona platform',
  },
  {
    title: 'Access at any Time ',
    content:
      'All of your contracts and contract annexes are stored and available for review and download from your account dashboard. Both parties get equal access to signed contracts, promoting transparent communication.',
    image: AccessAtAnyTimeImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of reviewing and downloading contracts on Spona platform',
  },
];

export const textsAndImagesPaymentProcessing: TextAndImageProps[] = [
  {
    title: 'Transparent Payments',
    content:
      'Predefined fields let you quickly build your contract from the ground up - you only need to follow the terms and add details to finalize the process. Cooperate in real time and get inspired with industry standards for similar projects.',
    image: TransparentPaymentsSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of the budget allocation  on Spona',
  },
  {
    title: 'Stay in Sync',
    content:
      'The buyer will be alerted when it’s time for them to make their payment, ensuring that they don’t lose track of their invoices. You will also receive notifications for every step of the process so you have the peace of mind of being completely up to date with your invoicing',
    image: StayInSyncSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Notification on Spona',
  },
  {
    title: 'Secure Processing',
    content:
      'With Spona, you can be sure that your money is in safe hands. We are a guarantee for both parties and protect payment execution to make sure you get your payments on time.',
    image: SecureProcessingSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Secured payments illustration',
  },
  {
    title: 'Transparent Transactions',
    content:
      'Both you and the buyer have an overview of all transactions with invoices available for reference and download on your account dashboards.',
    image: TransparentTransactionsSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Illustration of transactions overview on Spona',
  },
  {
    title: 'Get Insights',
    content:
      'Check your current and forecasted earnings across your current project (or multiple, if you’re handling more than one at the same time) to review your revenue immediately.',
    image: GetInsightsSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of a graph on Spona',
  },
];

export const textsAndImagesMilestonesAndTasks: TextAndImageProps[] = [
  {
    title: 'Standardize Your Processes',
    content:
      'One platform for you, your buyers and all your task management. No need for multitasking or learning new tools: Spona is a powerful tool for both beginner and experienced managers.',
    image: StandardizeYourProcessesSellerImage,
    contentOrder: 'textThenImage',
    alt: 'illustration_Standardize Your Processes',
  },
  {
    title: 'Share Your Progress',
    content:
      'Segmenting your milestones into smaller tasks and updating status progress automatically reflects on your milestones, letting you easily determine your short-term and long-term goal progress. Your activity is visible to the buyer, effortlessly keeping everyone on the same page.',
    image: ShareYourProgressSellerImage,
    contentOrder: 'imageThenText',
    alt: 'illustration_Share Your Progress',
  },
  {
    title: 'Resolve Issues',
    content:
      'In case of a problem, notify the buyer immediately by flagging the associated issues. Handle roadblocks before they escalate and keep track of the impact they have on your work.',
    image: ResolveIssuesSellerImage,
    contentOrder: 'textThenImage',
    alt: 'illustration_Resolve Issues',
  },
  {
    title: 'Complete Milestones',
    content:
      'Progressing your project is a seamless and intuitive process designed to facilitate information sharing in the most convenient way possible. Simply mark a milestone as ready for buyer review to start the payment process.',
    image: CompleteMilestonesSellerImage,
    contentOrder: 'imageThenText',
    alt: 'illustration_Complete Milestones',
  },
  {
    title: 'Track Project Health',
    content:
      'You don’t have to organize data yourself to get an overview into your progress. Our platform consolidates all major and minor updates to your tasks and milestones into your overall project health, visible to the buyer for quick reference.',
    image: TrackProjectHealthSellerImage,
    contentOrder: 'textThenImage',
    alt: 'illustration_Track Project Health',
  },
];

export const textsAndImagesCommunication: TextAndImageProps[] = [
  {
    title: 'One Platform',
    content:
      'Get a direct line to buyers with Spona’s messaging tool. If you’re handling multiple projects at once, get the benefit of gathering all of your contacts in one place.',
    image: OnePlatformSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of integrated messaging on Spona',
  },
  {
    title: 'Quick Updates',
    content:
      'Reduce back-and-forth with our automated systems for project progress, task management and payments execution.',
    image: QuickUpdatesSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Illustration of automated system for project progress on Spona',
  },
  {
    title: 'Accessible Support',
    content:
      'Reduce your waiting times and get quick solutions by messaging our support team at Spona.',
    image: AccessibleSupportSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Support chat illustration od Spona',
  },
  {
    title: 'Record Your Activities',
    content:
      'It’s easy for data to get lost when you’re using multiple sources of communication. Spona provides the answer to this problem - a digital record of all your messages, always accessible through your platform.',
    image: RecordYourActivitiesSellerImage,
    contentOrder: 'imageThenText',
    alt: 'Illustration of the digital record of Spona user’s data',
  },
];

export const textsAndImagesMonitorYourKPIs: TextAndImageProps[] = [
  {
    title: 'Make Data-Driven Decisions',
    content:
      'Eliminate guesswork with detailed, user-friendly reports that let you know exactly how your project is progressing. Pinpoint your strong and weak points and fuel your improvement with Spona.',
    image: MakeDataDrivenDecisionsSellerImage,
    contentOrder: 'textThenImage',
    alt: 'illustration_Make Data-Driven Decisions',
  },
  {
    title: 'Easy Resource Planning',
    content:
      'Improve your scheduling and team management by keeping track of your key metrics. Increase your efficiency and optimize your resource utilization across the board.',
    image: EasyResourcePlanningSellerImage,
    contentOrder: 'imageThenText',
    alt: 'illustration_Easy Resource Planning',
  },
  {
    title: 'Clear Communication',
    content:
      'Stay aligned with your stakeholders, your internal team and the buyer while cutting down on meetings, phone calls and emails.',
    image: ClearCommunicationSellerImage,
    contentOrder: 'textThenImage',
    alt: 'illustration_Easy communication',
  },
  {
    title: 'Consolidate Your Projects',
    content:
      'Get all your project data in one place. Get a detailed overview of all activities on one project, or compare different projects (across one or multiple buyers) to gain powerful insights.',
    image: ConsolidateYourProjectsSellerImage,
    contentOrder: 'imageThenText',
    alt: 'illustration_Consolidate Your Projects',
  },
  {
    title: 'Reduce Data Micromanagement',
    content:
      'Instead of managing multiple data sources, view all your key insights on one platform. Export your reports to easily share them with your team and external partners. ',
    image: ReduceDataMicromanagementSellerImage,
    contentOrder: 'textThenImage',
    alt: 'Illustration of  reviewing key insights on Spona',
  },
];
