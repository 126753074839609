import Stack from '@mui/material/Stack';
import { Box, SxProps, Theme } from '@mui/system';
import EllipseImage from '../../../../assets/img/login-ellipse.svg';
import NewPasswordSideImage from '../../../../assets/img/public-pages/new-password-side-image.jpg';

export interface SideRegisterProps {
  sx?: SxProps<Theme> | undefined;
  image?: string;
}

const PasswordChangeSection = ({ sx, image = NewPasswordSideImage }: SideRegisterProps) => (
  <Stack height="100%">
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        objectFit: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'cover',
      }}
    >
      <Box
        component="img"
        src={EllipseImage}
        sx={{
          position: 'absolute',
          zIndex: 50,
          left: { xs: '-35%', lg: -100 },
          top: { xs: '35%', lg: '55%' },
          maxWidth: { xs: '90%', lg: '100%' },
          opacity: '85%',
        }}
      />
    </Box>
  </Stack>
);

export default PasswordChangeSection;
