import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { StepIconProps } from '@mui/material/StepIcon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TimeAgo from 'react-timeago';
import { Stack, StepConnector, stepConnectorClasses, SxProps, Theme, Tooltip } from '@mui/material';
import { CompanyRole, EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { MilestoneSnakeDto } from 'tdc-web-backend/milestones/schemas';
import { format } from 'date-fns';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { useParams } from 'react-router-dom';
import { ReactComponent as FlagIcon } from '../../../assets/icons/layout-icons/FlagIcon.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/content/FolderIcon.svg';
import { ReactComponent as BookmarkIcon } from '../../../assets/icons/content/BookmarkIcon.svg';
import { ReactComponent as FolderIconWithCheck } from '../../../assets/icons/content/FolderIconWithCheck.svg';
import { ReactComponent as CircularArrowsIcon } from '../../../assets/icons/content/CircularArrowsIcon.svg';
import { ReactComponent as HourglassIcon } from '../../../assets/icons/layout-icons/Hourglass.svg';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import theme from '../../../theme';
import { APPBAR_HEIGHT } from '../../../layout/dashboard-navigation/NavigationDashboard';
import Link from '../../../components/link/Link';
import CustomNoRowsOverlay from '../../../components/custom-no-rows-overlay/CustomNoRowsOverlay';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { primaryLight, secondaryGreen, secondaryPink } from '../../../color';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';

interface LinearProgressStepperProps {
  milestones: MilestoneSnakeDto[] | null;
  sx?: SxProps<Theme> | undefined;
  adjacentLayoutHeight?: number;
}

const activeColor = theme.palette.primaryDark[300];
const completedColor = theme.palette.secondaryGreen[500];
const disabledColor = theme.palette.primaryDark[300];

// statuses that are considered in which in a step should still be marked as in progress,
// so marked with blue colors
const OneOfStatuses = (status: MilestoneSnakeDto['status']): boolean =>
  status === EnumProjectMilestoneStatus.InProgress ||
  status === EnumProjectMilestoneStatus.UnderReview;

const LinearProgressStepper = ({
  milestones,
  sx,
  adjacentLayoutHeight,
}: LinearProgressStepperProps) => {
  const params = useParams();
  const currentStepRef = useRef<HTMLDivElement | null>(null);

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);
  const isMilestoneFlagged = (buyerFlagged: boolean, sellerFlagged: boolean): boolean =>
    isUserBuyer ? buyerFlagged : sellerFlagged;

  const { data: projectData } = useReferenceOne<ProjectDto>({
    resource: 'projects',
    id: params.projectId,
  });

  useEffect(() => {
    if (currentStepRef.current) {
      currentStepRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [milestones, currentStepRef.current]);

  const CustomStepIcon = (
    props: StepIconProps,
    milestoneProps: {
      tasksCount: number;
      tasksCompletedCount: number;
      tasksCanceledCount: number;
      milestoneStatus: EnumProjectMilestoneStatus;
    },
  ) => {
    const { milestoneStatus, tasksCompletedCount, tasksCount, tasksCanceledCount } = milestoneProps;

    const percentageOfMilestoneCompletion = tasksCount
      ? Math.round((tasksCompletedCount / (tasksCount - tasksCanceledCount)) * 100)
      : 0;

    switch (milestoneStatus) {
      case EnumProjectMilestoneStatus.InProgress:
      case EnumProjectMilestoneStatus.UnderReview:
        return (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: 'secondaryBlue.100',
              borderRadius: '3px',
              color: 'secondaryBlue.700',
              p: '4px',
              pl: percentageOfMilestoneCompletion === 100 ? '9px' : '4px',
            }}
          >
            <Typography variant="heading4">{percentageOfMilestoneCompletion}%</Typography>
          </Stack>
        );
      case EnumProjectMilestoneStatus.Completed:
      case EnumProjectMilestoneStatus.Approved:
        return <BookmarkIcon style={{ width: 26, height: 20 }} />;
      default:
        return <StatusChipField text="in_pending" componentReturnType="dot" sx={{ ml: '3px' }} />;
    }
  };

  const CustomConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderLeft: `1px solid ${activeColor}`,
        borderLeftStyle: (() => {
          if (milestones?.findIndex((milestone) => OneOfStatuses(milestone.status)) === -1) {
            return 'dashed';
          }

          return 'solid';
        })(),
      },
    },

    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderLeft: `1px solid ${completedColor}`,
      },
    },

    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderLeft: `1px solid ${disabledColor}`,
        borderLeftStyle: 'dashed',
      },
    },

    [`& .${stepConnectorClasses.line}`]: {
      width: 50,
      backgroundColor: 'transparent',
      borderLeft: '1px solid',
    },
  }));

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Stepper
        orientation="vertical"
        connector={<CustomConnector />}
        sx={{
          flexDirection: 'column-reverse',
          height: !milestones?.length
            ? '30vh'
            : (milestones?.length as number) <= 2
            ? '50vh'
            : `calc(100vh - ${adjacentLayoutHeight}px - ${APPBAR_HEIGHT}px)`,
          overflow: 'scroll',
          pb: 3,
          pr: 2,
          '& .MuiStepConnector-line': {
            height: '100%',
          },
        }}
        activeStep={(() => {
          // if none milestones are in in_progress or under_review status, set the activeStep
          // to the last milestone + 1
          if (milestones?.findIndex((milestone) => OneOfStatuses(milestone.status)) === -1) {
            return milestones.length + 1;
          }

          return (
            (milestones?.findIndex((milestone) => OneOfStatuses(milestone.status)) as number) + 1
          );
        })()}
      >
        {/* first step */}
        {/* it is the bottom one because column-reverse direction */}
        <Step
          expanded
          sx={{
            zIndex: 999999,
            '&.Mui-completed .MuiStepContent-root': { borderLeft: `1px solid ${completedColor}` },
          }}
        >
          <StepLabel
            StepIconComponent={() =>
              !milestones?.length ? (
                <HourglassIcon style={{ width: 20, height: 20, marginLeft: '4px' }} />
              ) : (
                <FolderIcon style={{ width: 20, height: 20, marginLeft: '4px' }} />
              )
            }
            sx={{
              '&.MuiStepLabel-root .MuiStepLabel-iconContainer': { width: '40px' },
            }}
          >
            {!milestones?.length ? (
              <Typography sx={{ typography: { md: 'body2', lg: 'body1' } }} color="primaryDark.600">
                Negotiation
              </Typography>
            ) : (
              <Typography sx={{ typography: { md: 'body2', lg: 'body1' } }} color="primaryDark.600">
                Project created
              </Typography>
            )}
          </StepLabel>

          <StepContent
            sx={{
              borderLeft: 'none !important',
              '&.MuiStepContent-root': { pl: '30px' },
            }}
          >
            <Typography variant="body3" color="primaryDark.500">
              <TimeAgo date={new Date(projectData?.created ?? '')} />
            </Typography>
          </StepContent>
        </Step>

        {milestones?.map(
          ({
            id,
            name,
            start,
            status,
            tasksCompletedCount,
            tasksCanceledCount,
            tasksCount,
            end,
            relatedMilestones,
            currentMilestone,
            activeMilestoneIndex,
            buyerFlagged,
            sellerFlagged,
          }) => (
            <Step
              key={id}
              ref={status === EnumProjectMilestoneStatus.Completed ? currentStepRef : null}
              expanded
              sx={{
                // bgcolor: '#f3f1f9',
                '&.Mui-completed .MuiStepContent-root': {
                  borderLeft: `1px solid ${completedColor}`,
                },
                '& .MuiStepContent-root': {
                  borderLeft: OneOfStatuses(status)
                    ? `1px solid ${activeColor}`
                    : `1px dashed ${disabledColor}`,
                },
              }}
            >
              <StepLabel
                StepIconComponent={(props) =>
                  CustomStepIcon(props, {
                    milestoneStatus: status,
                    tasksCompletedCount,
                    tasksCount,
                    tasksCanceledCount,
                  })
                }
                sx={{
                  '&.MuiStepLabel-root .MuiStepLabel-iconContainer': {
                    width: '40px',
                    justifyContent: OneOfStatuses(status) ? 'center' : 'normal',
                  },
                }}
              >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Link
                    color="grey.500"
                    to={(() => {
                      if (relatedMilestones && relatedMilestones.length > 0) {
                        if (currentMilestone) {
                          return `./end-to-end/milestone/${currentMilestone}`;
                        }

                        return `./end-to-end/milestone/${relatedMilestones[0]?.id}`;
                      }

                      return `./end-to-end/milestone/${id}`;
                    })()}
                    underline="none"
                    sx={{ lineHeight: '1px' }}
                  >
                    <Typography
                      color={
                        OneOfStatuses(status)
                          ? 'primaryLight.500'
                          : status === EnumProjectMilestoneStatus.Completed ||
                            status === EnumProjectMilestoneStatus.Approved
                          ? 'secondaryGreen.700'
                          : 'primaryDark.600'
                      }
                      sx={{
                        typography: { md: 'body2', lg: 'body1' },
                        textDecoration:
                          status === EnumProjectMilestoneStatus.Completed ||
                          status === EnumProjectMilestoneStatus.Approved
                            ? 'line-through'
                            : 'none',
                        opacity:
                          status === EnumProjectMilestoneStatus.Completed ||
                          status === EnumProjectMilestoneStatus.Pending
                            ? 0.5
                            : 1,
                      }}
                    >
                      {name}
                    </Typography>
                  </Link>

                  {isMilestoneFlagged(buyerFlagged, sellerFlagged) && (
                    <Tooltip
                      title={<Typography fontWeight="600">Flagged</Typography>}
                      arrow
                      placement="top"
                    >
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          bgcolor: 'secondaryPink.100',
                          width: 'fit-content',
                          height: 'fit-content',
                          borderRadius: '6px',
                          p: 0.75,
                        }}
                      >
                        <FlagIcon fill={secondaryPink[700]} style={{ width: 18, height: 18 }} />
                      </Stack>
                    </Tooltip>
                  )}

                  {relatedMilestones && relatedMilestones?.length > 0 && (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        backgroundColor: 'common.white',
                        border: '1px solid',
                        borderColor: 'primaryLight.100',
                        px: 0.8,
                        py: 0.5,
                        borderRadius: '3px',
                        color:
                          status === EnumProjectMilestoneStatus.Completed
                            ? 'secondaryGreen.700'
                            : 'primaryLight.500',
                        textDecoration:
                          status === EnumProjectMilestoneStatus.Completed ? 'line-through' : 'none',
                        opacity: status === EnumProjectMilestoneStatus.Completed ? 0.5 : 1,
                      }}
                    >
                      <Stack spacing={0.3} direction="row" alignItems="center">
                        <CircularArrowsIcon
                          style={{ width: 18, height: 18 }}
                          fill={
                            status === EnumProjectMilestoneStatus.Completed
                              ? secondaryGreen[700]
                              : primaryLight[500]
                          }
                        />

                        <Typography
                          variant="body3"
                          color={
                            status === EnumProjectMilestoneStatus.Completed
                              ? 'secondaryGreen.700'
                              : 'primaryLight.500'
                          }
                        >
                          {OneOfStatuses(status) || status === EnumProjectMilestoneStatus.Completed
                            ? activeMilestoneIndex
                            : '-'}
                        </Typography>

                        <Typography
                          variant="body3"
                          color={
                            status === EnumProjectMilestoneStatus.Completed
                              ? 'secondaryGreen.700'
                              : 'primaryLight.500'
                          }
                        >
                          /
                        </Typography>
                        <Typography
                          variant="body3"
                          color={
                            status === EnumProjectMilestoneStatus.Completed
                              ? 'secondaryGreen.700'
                              : 'primaryLight.500'
                          }
                        >
                          {relatedMilestones ? relatedMilestones.length : 0}
                        </Typography>
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </StepLabel>

              <StepContent
                sx={{
                  borderLeft: '1px solid red',
                  pl: '30px',
                  pr: 0,
                  '&.MuiStepContent-root .MuiCollapse-root': {
                    opacity:
                      status === EnumProjectMilestoneStatus.Completed ||
                      status === EnumProjectMilestoneStatus.Pending
                        ? 0.5
                        : 1,
                  },
                }}
              >
                <Stack>
                  <Stack spacing={0.75}>
                    {/* tasks */}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="heading5" color="primaryDark.500">
                        Tasks
                      </Typography>

                      <Typography variant="heading4" color="primaryDark.600">
                        {tasksCount}
                      </Typography>
                    </Stack>

                    {/* start */}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="heading5" color="primaryDark.500">
                        Start date
                      </Typography>

                      <Typography variant="heading4" color="primaryDark.600">
                        {start ? format(new Date(start), 'MM.dd.yyyy') : '-'}
                      </Typography>
                    </Stack>

                    {/* due date */}
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="heading5" color="primaryDark.500">
                        End date
                      </Typography>

                      <Typography variant="heading4" color="primaryDark.600">
                        {end ? format(new Date(end), 'MM.dd.yyyy') : '-'}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </StepContent>
            </Step>
          ),
        )}

        {/* last step */}
        <Step
          expanded
          sx={{
            '& .MuiStepContent-root': { borderLeft: `1px dashed ${disabledColor}` },
          }}
        >
          <StepLabel
            StepIconComponent={() => (
              <FolderIconWithCheck style={{ width: 20, height: 20, marginLeft: '4px' }} />
            )}
            sx={{
              '&.MuiStepLabel-root .MuiStepLabel-iconContainer': { width: '40px' },
            }}
          >
            <Typography sx={{ typography: { md: 'body2', lg: 'body1' } }} color="primaryDark.600">
              End date
            </Typography>
          </StepLabel>

          <StepContent
            sx={{
              '&.MuiStepContent-root': { pl: '30px' },
            }}
          >
            <Typography variant="body3" color="primaryDark.500">
              {projectData?.end && format(new Date(projectData?.end ?? ''), 'MM.dd.yyyy')}
            </Typography>
          </StepContent>
        </Step>
      </Stepper>

      {!milestones?.length && (
        <CustomNoRowsOverlay sx={{ backgroundColor: 'primaryDark.150', opacity: 0.95 }} />
      )}
    </Box>
  );
};

export default LinearProgressStepper;
