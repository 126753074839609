import { Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { CompanyRole, EnumTutorialStatus } from 'tdc-web-backend/enums/enums';
import { useLocation } from 'react-router-dom';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { convertDate } from '../../../../utils/helpers';
import useDetermineCompanyRoles from '../../../../utils/hooks/useDetermineCompanyRoles';
import ContractDetailsDrawer from './ContractDetailsDrawer';
import useJoyride from '../../../../utils/hooks/joyride-hooks/useJoyride';

const cardStyles = (item: string, active: string) => {
  const isActive = item === active;
  return {
    p: 2,
    mb: 2,
    borderRadius: '0px 8px 8px 8px',
    transition: '0.2s all ease-in',
    border: '1px solid',
    borderColor: isActive ? 'secondaryBlue.200' : 'secondaryBlue.100',
    position: 'relative',
    overflow: 'visible',
    background: '#FFF',
    boxShadow: isActive ? '1px 10px 30px rgba(56, 69, 114, 0.1)' : 'none',
    '&:before': {
      position: 'absolute',
      top: 'calc(-10% - 2px)',
      left: '-1px',
      content: '""',
      width: '30%',
      height: '10%',
      clipPath: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%)',
      backgroundColor: isActive ? 'secondaryBlue.200' : 'secondaryBlue.100',
      border: '1px solid',
      borderColor: isActive ? 'secondaryBlue.200' : 'secondaryBlue.100',
      borderTopLeftRadius: '8px',
      zIndex: 0,
    },
    '&:hover': {
      backgroundColor: 'secondaryBlue.50',
      cursor: 'pointer',
    },
  };
};

type ContractCardItemProps = {
  item: ContractDto;
  onCardClick: (open: boolean) => void;
};

const ContractCardItem = ({ item, onCardClick }: ContractCardItemProps) => {
  const isBuyer = useDetermineCompanyRoles()?.includes(CompanyRole.Buyer);
  const [activeCard, setActiveCard] = useState('');
  const [open, setIsOpen] = useState(false);
  const location = useLocation();
  const data = location?.state as ContractDto;
  const { tutorials } = useJoyride();

  useEffect(() => {
    if (data) {
      if (data.name.includes('Annex') && item.id === data.originalContract) {
        setActiveCard(data.originalContract);
        setIsOpen(true);
      }
      if (item.id === data.id) {
        if (tutorials?.includes(EnumTutorialStatus.ContractDetail) && data && item.id === data.id) {
          setActiveCard(data.id);
          setIsOpen(true);
        }
      }
    }
  }, [data]);

  return (
    <Card
      elevation={0}
      onClick={() => {
        setIsOpen(true);
        onCardClick(true);
        setActiveCard(item.id);
      }}
      sx={cardStyles(item.id, activeCard)}
    >
      <Stack spacing={1.5} className="react-joyride-step-7-contracts">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <StatusChipField text={item?.status} />
          <Typography color="primaryLight.500" variant="heading5">
            View all
          </Typography>
        </Stack>
        <Stack>
          <CustomReferenceField
            resource="projects"
            id={item.project}
            displayName="name"
            variant="body2"
          />
          {isBuyer ? (
            <Typography color="primaryDark.400" variant="body3">
              {item.body.seller.companyName}
            </Typography>
          ) : (
            <Typography color="primaryDark.400" variant="body3">
              {item.body.buyer.companyName}
            </Typography>
          )}
        </Stack>
        <Stack spacing={0.2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primaryDark.400" variant="heading5">
              documents
            </Typography>
            <CustomReferenceField
              id={item.project}
              resource="projects"
              displayName="documents"
              color="primaryDark.600"
              variant="heading5"
            />
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primaryDark.400" variant="heading5">
              created
            </Typography>
            <Typography color="primaryDark.600" variant="heading5">
              {convertDate(item.created)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography color="primaryDark.400" variant="heading5">
              last signed
            </Typography>
            <Typography color="primaryDark.600" variant="heading5">
              {convertDate(item.signed)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <ContractDetailsDrawer
        open={open}
        setIsOpen={setIsOpen}
        item={item}
        setActiveCard={setActiveCard}
      />
    </Card>
  );
};
export default ContractCardItem;
