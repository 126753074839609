import flwPr from '../../../assets/img/public-pages/flw-pr.svg';
import gruntek from '../../../assets/img/public-pages/gruntek.png';
import { Testimonial } from '../../../components/public-screen-components/Testimonials';

const testimonialsArray: Testimonial[] = [
  {
    icon: flwPr,
    subject:
      "We have never had middleman support like the support that we had with Spona. Your project managers provided us with answers to several questions that we had been unable to obtain for months. It's a great support system and a good buffer; if something goes wrong, paying someone 10% more to do your work than you would be worthwhile.",
    author: 'Jennifer Peros',
    title: 'Director of Talent at Famous Last Words PR',
  },
  {
    icon: gruntek,
    subject:
      'The platform offers a very quick and efficient service that really does save your time and money. We were a bit strapped for time as the project was being planned during the holiday season and we wanted to kick it off as soon as possible, but thanks to Spona, everything worked out as fast as possible for us.',
    author: 'Nora Mlinarić',
    title: 'PR & Marketing Specialist at Gruntek',
  },
];

export default testimonialsArray;
