import { Stack, Typography } from '@mui/material';
import CheckedItem from './CheckedItem';
import CustomButton from '../../../../components/button/CustomButton';

const SponaConnectHire = () => (
  <Stack gap={5} alignItems="center" justifyContent="space-between" height="100%" width="100%">
    <Stack gap={2} alignItems="center" width="100%">
      <Stack gap={1} alignItems="center" width="100%">
        <Typography variant="publicHeading4" color="primaryLight.500">
          Spona Connect
        </Typography>
        <Typography variant="publicBody4" color="primaryDark.900" my={2}>
          Everything in self-service, plus:
        </Typography>
      </Stack>
      <Stack gap={2} width="80%">
        <CheckedItem title="Market analysis" />
        <CheckedItem title="Competitor benchmarking" />
        <CheckedItem title="Project creation" />
        <CheckedItem title="Tailored seller outreach" />
        <CheckedItem title="Pitch analysis" />
        <CheckedItem title="Project creation" />
        <CheckedItem title="Budget negotiation" />
        <CheckedItem title="Priority support" />
        <CheckedItem title="Dedicated success manager" />
      </Stack>
      <Typography variant="publicBody5Light" maxWidth="95%" color="primaryDark.900" mt={3}>
        5% fee per project to cover payment processing and Spona services when successfully matched
        with a digital service seller
      </Typography>
    </Stack>
    <CustomButton href={process.env.REACT_APP_CALENDLY_URL as string}>Talk to us</CustomButton>
  </Stack>
);

export default SponaConnectHire;
