/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
import { Box, Stack } from '@mui/system';
import { Divider, Grid } from '@mui/material';
import { BaseCaseStudy } from 'tdc-web-backend/case-studies/schemas';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import FilterSection from './FilterSection';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import useQueryState from '../../../../utils/hooks/useQueryState';
import CardListPagination from '../components/CardListPagination';
import CaseStudyCard from '../components/CaseStudyCard';

const CaseStudySubarchive = () => {
  const [queryState, , , searchParams] = useQueryState();

  const queryBuilder = () => {
    const params = new URLSearchParams(searchParams);
    params.set('limit', queryState.limit ?? '12');
    params.set('offset', queryState.offset ?? '0');
    params.set('orderBy', queryState.orderBy ?? '-created');
    params.set('status', 'verified');
    params.set('visibility', 'true');
    params.append('status', 'under_review');
    params.append('status', 'published');
    return params.toString();
  };

  const { data: response } = useGetMany<BaseCaseStudy>({
    resource: `/case-studies?${queryBuilder()}`,
  });

  const caseStudies = response?.data.results;
  const count = response?.data.count;

  return (
    <>
      <Box>
        <Stack
          overflow="hidden"
          maxWidth="100%"
          alignSelf="center"
          mt={`${PUBLIC_APPBAR_HEIGHT}px`}
          sx={{
            px: { xs: '1.5rem', lg: '2.5rem' },
            mx: { gr: 'auto' },
            width: { gr: '85rem' },
          }}
        >
          <FilterSection />
        </Stack>
      </Box>
      <Box sx={{ backgroundColor: 'primaryDark.100' }}>
        <Stack
          overflow="hidden"
          maxWidth="100%"
          alignSelf="center"
          sx={{
            px: { xs: '1.5rem', lg: '2.5rem' },
            mx: { gr: 'auto' },
            width: { gr: '85rem' },
          }}
        >
          <Grid container spacing={3} rowSpacing={5} pt={0} mt={-1.5} gridAutoRows="max-content">
            {caseStudies?.map((item: any, index) => (
              <Grid
                key={item?.id}
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={4}
                gridAutoRows="max-content"
              >
                <CaseStudyCard showFocusArea data={item} />
              </Grid>
            ))}
          </Grid>
          <Divider
            sx={{
              mt: 5,
            }}
          />
          <CardListPagination count={count} />
        </Stack>
      </Box>
    </>
  );
};

export default CaseStudySubarchive;
