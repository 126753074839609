import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as CheckmarkIcon } from '../../assets/img/public-pages/checkmark.svg';

export interface PunctationMarkedTextProps {
  content: string[];
}
const PunctationMarkedText = ({ content }: PunctationMarkedTextProps) => {
  const Icon = () => (
    <Box>
      <CheckmarkIcon fill="primaryLight.500" style={{ width: 24, height: 24 }} />
    </Box>
  );

  return (
    <Stack spacing={2.75}>
      {content.map((text) => (
        <Stack spacing={2} direction="row" alignItems="center">
          <Icon />

          <Typography color="secondaryGray.600" variant="publicBody4Regular">
            {text}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default PunctationMarkedText;
