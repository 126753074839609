import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Box from '@mui/material/Box';
import { Theme, SxProps, Typography } from '@mui/material';
import { HTMLContent } from '@tiptap/react';
import Editor from '../editor/Editor';
import { MenuItem } from '../editor/EditorCommand';
import BaseInputLabel from './BaseInputLabel';

type Props = {
  name: string;
  required?: boolean;
  isEditable?: boolean;
  label?: string;
  labelAdornment?: JSX.Element | null;
  items: MenuItem[];
  sx?: SxProps<Theme>;
};

function ControlledTextEditor({
  name,
  label,
  required,
  isEditable = true,
  labelAdornment,
  items,
  sx,
}: Props) {
  const { control, getFieldState, trigger } = useFormContext();
  const [isTouched, setIsTouched] = useState(false); // Workaround to RHF 'isTouched' bug in Editor
  const fieldState = getFieldState(name);
  const hasError = !!fieldState.error;
  const errorMsg = fieldState.error?.message?.length ? 'This field is required' : '';

  const onBlur = async () => {
    if (!isTouched) {
      setIsTouched(true);
    }
    await trigger(name);
  };

  return (
    <Box sx={sx}>
      {label && (
        <BaseInputLabel text={label as string} adornment={labelAdornment} required={required} />
      )}
      <Controller
        name={name}
        control={control}
        rules={required ? { required: 'This field is required' } : { required: false }}
        render={({ field }) => (
          <Editor
            {...field}
            items={items}
            onBlur={onBlur}
            content={field.value}
            isEditable={isEditable}
            hasError={hasError}
            uniqueKey={name}
            onUpdateContent={(data: HTMLContent) => field.onChange(data)}
          />
        )}
      />
      {hasError && (
        <Typography
          color="secondaryPink.500"
          variant="body4"
          sx={{ display: 'block', margin: '12px 0' }}
        >
          {errorMsg}
        </Typography>
      )}
    </Box>
  );
}

export default ControlledTextEditor;
