import { Stack, Typography } from '@mui/material';
import CheckedItem from './CheckedItem';
import CustomButton from '../../../../components/button/CustomButton';

const SelfServiceHire = () => (
  <Stack gap={5} alignItems="center" width="100%" justifyContent="space-between">
    <Typography variant="publicHeading4" color="primaryLight.500">
      Self-service
    </Typography>
    <Stack gap={2} width="80%">
      <CheckedItem title="Unlimited pitches" />
      <CheckedItem title="Seller evaluation tools" />
      <CheckedItem title="Built-in selection tool" />
      <CheckedItem title="Built-in chat & video calls" />
      <CheckedItem title="Company calendar" />
      <CheckedItem title="Ready-to-use contracts" />
      <CheckedItem title="Milestone & task management" />
      <CheckedItem title="Invoice management" />
      <CheckedItem title="Payment processing" />
      <CheckedItem title="Expense tracking" />
    </Stack>
    <Typography variant="publicBody5Light" maxWidth="95%" color="primaryDark.900">
      3% fee per project to cover payment processing and Spona services when successfully matched
      with a digital service seller
    </Typography>
    <CustomButton href="/register">Get started</CustomButton>
  </Stack>
);

export default SelfServiceHire;
