import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import EbookImg from '../../../assets/img/public-pages/e-book.png';
import CustomButton from '../../../components/button/CustomButton';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import theme from '../../../theme';

const Ebook = () => {
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Stack width="100%" overflow="hidden">
      <Stack
        spacing={12.5}
        direction={isLessThanMedium ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="flext-start"
        sx={{
          ...LAYOUT_DEFAULTS,
          marginTop: `${PUBLIC_APPBAR_HEIGHT + 130}px`,
          marginBottom: { xs: '10rem' },
          overflow: 'hidden',
        }}
      >
        {/* text */}
        <Stack spacing={3} justifyContent="flex-start">
          <Typography variant="publicBody3Regular" color="primaryLight.500">
            Free Download
          </Typography>
          <Typography variant="publicHeading3">
            Embracing AI:
            <br />A New Era in User Experience and Growth
          </Typography>

          <Typography variant="publicBody2Regular">
            In this e-book, you'll learn why AI is the next big revolution and how it offers an
            unprecedented opportunity to lead in innovation and efficiency.
          </Typography>
          <Stack direction="row" justifyContent={{ xs: 'center', lg: 'flex-start' }}>
            <CustomButton
              variant="primary"
              sx={{
                py: { xs: '1.125rem' },
                width: 'fit-content',
                marginTop: { xs: '1.625rem', lg: 0, gr: '1.265rem' },
              }}
              href="https://tdc-production.ams3.cdn.digitaloceanspaces.com/_public/ebooks/embracing_aii.pdf"
            >
              Download for free
            </CustomButton>
          </Stack>
        </Stack>

        {/* image */}
        <Stack alignItems="center" spacing={3} justifyContent="space-around">
          <Box
            component="img"
            src={EbookImg}
            alt="Embrasing AI e-book cover"
            sx={{
              maxWidth: '559px',
              maxHeight: '505px',
              borderRadius: '12px',
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Ebook;
