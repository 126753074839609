import { Box, Stack, Typography, Link } from '@mui/material';
import {
  CompanyApprovalStatus,
  CompanyRole,
  mapCompanySize,
  mapProjectSize,
} from 'tdc-web-backend/enums/enums';
import { useNavigate } from 'react-router-dom';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import useTable from '../../../../components/table/useTable';
import BaseTable from '../../../../components/table/BaseTable';
import CustomButton from '../../../../components/button/CustomButton';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import TableWithChipFilters from '../../../../components/table/TableWithChipFilters';

interface AdminCompanyListProps {
  companyType: string;
}

const AdminCompanyList = ({ companyType }: AdminCompanyListProps) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => <TaskStatus text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'companySize',
      headerName: 'Company size',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography>{mapCompanySize.get(params.value)}</Typography>
      ),
    },
    {
      field: 'averageProjectSize',
      headerName: 'Avg. project size',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography>{mapProjectSize.get(params.value)}</Typography>
      ),
    },
    {
      field: 'url',
      headerName: 'Website',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Link
          underline="none"
          href={params.value}
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          rel="noreferrer"
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'Navigate',
      headerName: 'Created by admin',
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridValueGetterParams) =>
        params.row.createdByAdmin ? <CheckIcon /> : <CloseIcon />,
    },
    {
      field: 'agreementDate',
      headerName: 'Agreement date',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) =>
        params.row.agreement_date === null ? (
          <CloseIcon />
        ) : (
          <Typography>{format(new Date(params.row.agreement_date), 'MM.dd.yyyy')}</Typography>
        ),
    },
    {
      field: 'originId',
      headerName: 'TDA ID',
      editable: false,
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => <Typography>{params.value}</Typography>,
    },
  ];

  const { resourceLimit, chipFilters, ...props } = useTable({
    resourceUrl: 'companies',
    queryParams: `role=${companyType}`,
    withTabs: companyType !== CompanyRole.Buyer,
    chipFilterQueryParams: [
      {
        title: 'All',
      },
      {
        title: 'approved',
        search: { status: CompanyApprovalStatus.Approved },
      },
      {
        title: 'Pending',
        search: { status: CompanyApprovalStatus.Pending },
      },
      {
        title: 'Rejected',
        search: { status: CompanyApprovalStatus.Rejected },
      },
    ],
  });

  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column" spacing={1}>
        {companyType !== CompanyRole.Buyer ? (
          <TableWithChipFilters
            numberOfRowsPerPage={resourceLimit}
            columns={columns}
            onRowClick={(params) => navigate(`../${params.id}`)}
            withElements={{
              withTitle: 'Companies',
              withCta: (
                <CustomButton
                  onClick={() =>
                    navigate('../create-company', {
                      state: { fromBuyerPage: companyType === 'buyer' },
                    })
                  }
                >
                  Add new
                </CustomButton>
              ),
            }}
            chipFilters={
              chipFilters as { label: string; isLoading: boolean; count: number | undefined }[]
            }
            {...props}
          />
        ) : (
          <BaseTable
            numberOfRowsPerPage={resourceLimit}
            columns={columns}
            onRowClick={(params) => navigate(`../${params.id}`)}
            withElements={{
              withTitle: 'Companies (tabs & filters will be added)',
              withCta: (
                <CustomButton
                  onClick={() =>
                    navigate('../create-company', {
                      state: { fromBuyerPage: companyType === 'buyer' },
                    })
                  }
                >
                  Add new
                </CustomButton>
              ),
            }}
            {...props}
          />
        )}
      </Stack>
    </Box>
  );
};

export default AdminCompanyList;
