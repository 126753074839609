import { Stack, Box, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import AboutOfficeImage from '../../../assets/img/public-pages/about-office.png';
import theme from '../../../theme';
import LAYOUT_DEFAULTS from '../../../layout/utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import CustomButton from '../../../components/button/CustomButton';

const sponaInNumbers: { value: string; title: string }[] = [
  {
    value: '10K+',
    title: 'Sellers',
  },
  {
    value: '133+',
    title: 'Countries',
  },
  {
    value: '40+',
    title: 'Digital Services',
  },
];
const HomepageHero = () => {
  const hasUser = localStorage.getItem('AccessToken') !== null;
  const navigate = useNavigate();

  const isLessThanGreat = useMediaQuery(theme.breakpoints.down('gr'));
  const isLessThanMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Stack
      spacing={6}
      justifyContent="center"
      sx={{ ...LAYOUT_DEFAULTS, marginTop: `${PUBLIC_APPBAR_HEIGHT + 40}px` }}
    >
      {/* the keep it together text */}
      <Stack justifyContent="center" alignItems="center" width="fit-content">
        <Typography
          display={{ xs: 'grid', md: 'block' }}
          sx={{
            typography: { xs: 'publicHeadline7', md: 'publicHeadline5', lg: 'publicHeadline1' },
          }}
        >
          Keep it{' '}
          <Typography
            component="span"
            sx={{
              position: 'relative',
              typography: { xs: 'publicHeadline7', md: 'publicHeadline5', lg: 'publicHeadline1' },
            }}
          >
            together
            <Box
              sx={{
                backgroundColor: 'primaryLight.500',
                width: '100%',
                height: isLessThanMedium ? '0.5rem' : '1rem',
                borderRadius: '7rem',
                position: 'absolute',
                bottom: isLessThanGreat ? '-15%' : '-5%',
                left: 0,
                zIndex: -1,
              }}
            />
          </Typography>
        </Typography>
      </Stack>

      <Stack
        spacing={{ md: 8 }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mt: { xs: '6rem', md: '9rem', xl: '8.5rem' } }}
      >
        {/* about us text & company icons */}
        <Stack spacing={6} maxWidth={{ xs: '100%', xl: '40%' }}>
          <Stack spacing={4}>
            <Typography variant="publicBody3Regular">
              Streamline digital project collaboration, management, and financing within a single
              platform.
            </Typography>

            {!hasUser && (
              <CustomButton
                variant="primary"
                withNextArrow
                sx={{
                  width: { xs: '55%', md: '45%' },
                  py: { xs: '18px' },
                }}
                onClick={() => navigate('/register')}
              >
                Get started
              </CustomButton>
            )}
          </Stack>

          <Stack spacing={4} direction="row" justifyContent="flex-start" alignItems="start">
            {sponaInNumbers.map((item) => (
              <Stack
                spacing={1}
                sx={{
                  // width: { xs: '100%', sm: '45%', lg: '20%' },
                  textAlign: { xs: 'left', sm: 'left' },
                }}
              >
                <Typography variant="publicHeading5">{item.value}</Typography>

                <Typography width="100%" variant="publicBody6Regular">
                  {item.title}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>

        {/* image */}
        <Stack
          display={{ xs: 'none', md: 'flex' }}
          sx={{ width: { xs: '100%', xl: '50%' } }}
          alignItems="center"
        >
          <Box
            component="img"
            src={AboutOfficeImage}
            alt="Team discussion by the desk. Casual meeting in a modern office."
            sx={{ width: { md: '35vw', xl: '30vw' } }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomepageHero;
