import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  EnumCountry,
  EnumCurrency,
  EnumService,
  EnumServiceProviderScope,
  EnumTechLanguage,
  EnumTechPlatform,
  EnumTechTool,
  EnumTimedProjectStatus,
} from 'tdc-web-backend/enums/enums';
import {
  CreateTimedProjectContentDto,
  MaterialsDto,
  TimedProjectContentDto,
} from 'tdc-web-backend/timed-project-contents/schemas';
import { BaseTimedProjectPitch } from 'tdc-web-backend/timed-project-pitches/schemas';
import { Typography } from '@mui/material';
import useCreate from '../../../../utils/hooks/crud-hooks/useCreate';
import useUpdate from '../../../../utils/hooks/crud-hooks/useUpdate';
import useMultiStep from '../../../../components/multisteps/useMultiStep';
import useGetOne from '../../../../utils/hooks/crud-hooks/useGetOne';
import MultiStep from '../../../../components/multisteps/Multistep';
import Overview from './Overview';
import About from './About';
import Timeline from './Timeline';
import Seller from './Seller';
import Budget from './Budget';
import Preview from './Preview';
import PreviewSidebar from './PreviewSidebar';
import Materials from '../../../Pitch/create/steps/Materials';
import { ReactComponent as ErrorAlertIcon } from '../../../../assets/icons/layout-icons/ErrorAlertIcon.svg';
import { ReactComponent as FileMagnifier } from '../../../../assets/icons/layout-icons/FileMagnifier.svg';
import { ReactComponent as SaveIcon } from '../../../../assets/icons/layout-icons/SaveIcon.svg';
import {
  getFileTypeFromUrl,
  printHelperTextForSpecificCurrentStep,
} from '../../../../utils/helpers';
import ConfirmModal from '../../../../components/confirm-modal/ConfirmModal';
import { secondaryBlue, secondaryPink } from '../../../../color';
import { is } from 'date-fns/locale';
import { Project } from 'tdc-web-backend/projects/entities';
import { BaseTimedProject } from 'tdc-web-backend/timed-projects/schemas';
import TimedProjectCreateModal from '../components/TimedProjectCreateModal';

const initialState = {
  overview: {
    name: '',
    description: '',
  },
  about: {
    goals: '',
    targetMarkets: [] as EnumCountry[],
    audience: '',
    competitors: [],
    industries: [],
  },
  skills: {
    services: [] as EnumService[],
    platforms: [] as EnumTechPlatform[],
    tools: [] as EnumTechTool[],
    languages: [] as EnumTechLanguage[],
    providerScope: 'undefined' as EnumServiceProviderScope,
  },
  timeline: {
    pitchDeadline: null,
    projectStart: null,
  },
  budget: {
    budget: null,
    recurring: false,
    currency: 'usd' as EnumCurrency,
    budgetDescription: '',
  },
  materialsPreview: null, // remove this field on the last step
  materials: [],
  userAgreement: false,
};

const labels = ['Overview', 'About', 'Skills', 'Timeline', 'Budget', 'Materials', 'Preview'];

const modalTexts = {
  create: {
    title: 'Project under review',
    text: 'Your project needs to be verified by Spona before it is active on the platform. You will be notified when the review process is complete.',
  },
  edit: {
    title: 'Project edit under review',
    text: 'Spona will be reviewing your edit request shortly.',
  },
};

const helperTexts = [
  'In your company description, outline your general goals and direction without referring explicitly to your company name, as your identity will only be shared with sellers in the second round of negotiations.',
  'For project requirements, feel free to go as detailed as you desire. The more information you can provide, the bigger the chance that pitches will address your specific needs.',
  'Define the core service and technical expertise you expect your digital service seller to provide, if applicable to your project type.',
  'Projects with a flexible pitching deadline will be closed once you have selected a seller to cooperate with, or after multiple months of inactivity.',
  'If your project has a separate budget amount for its execution (e.g. marketing budget), please specify the amount of the budget allotted to the execution and to the seller in the budget breakdown section.',
  'Additional materials will be shared only with sellers who are accepted into the second round of negotiations. The files will be automatically uploaded into your shared chat space.',
];

const editableStatuses = ['draft', 'active'];

const setMaterialStatus = (file: File) => ({
  file,
  isUploadInProgress: false,
  uploadProgress: 100,
  error: null,
});

const extendMaterials = (materials: MaterialsDto[] | undefined) => {
  if (materials) {
    return Promise.all(
      materials.map(async (material) => {
        const fileBits = await fetch(material.file).then((res) => res.blob());
        const fileType = getFileTypeFromUrl(material.file);

        return new File([fileBits], material.fileName, {
          type: fileType,
        });
      }),
    );
  }
  return null;
};

const CreateTimedProject = () => {
  const { currentStep, steps, onStepChange, form } = useMultiStep({ labels, initialState });
  const navigate = useNavigate();
  const params = useParams();
  const projectId = params?.projectId || '';
  const editMode = !!projectId.length;
  const [openModal, setIsOpenModal] = useState(false);
  const [openEditModal, setIsOpenEditModal] = useState(false);
  const [openDraftModal, setIsOpenDraftModal] = useState(false);
  const [openErrorModal, setIsOpenErrorModal] = useState(false);
  const [openCancelModal, setIsOpenCancelModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const { data: timedProject } = useGetOne<BaseTimedProject>({
    resource: `timed-projects/${projectId}`,
    enabled: !!projectId,
    refetchOnWindowFocus: false,
  });
  const firstDraft: boolean =
    editMode &&
    timedProject?.data.edit === null &&
    timedProject?.data.status === EnumTimedProjectStatus.Draft;

  const contentId =
    timedProject?.data.edit === null ? timedProject?.data.content : timedProject?.data.edit;

  const { data: timedProjectContent } = useGetOne<TimedProjectContentDto>({
    resource: `timed-project-contents/${contentId}`,
    enabled: !!contentId,
    refetchOnWindowFocus: false,
  });

  const { mutate: createTimedProject, isLoading: createTimedProjectIsLoading } = useCreate<
    CreateTimedProjectContentDto,
    Partial<CreateTimedProjectContentDto>
  >({
    resource: '/timed-projects',
  });

  const { mutate: updateTimedProject, isLoading: updateTimedProjectIsLoading } = useUpdate({
    resource: `/timed-projects`,
  });

  const initialValues = timedProjectContent?.data;

  const onSubmit = (data: any, isDraft?: boolean) => {
    const { materialsPreview, userAgreement, materials, ...rest } = data;
    const formData = {
      body: {
        ...rest,
        budget: { ...rest.budget, budget: Number(rest.budget.budget) },
      },
      buyerApproved: !isDraft,
      materials: materials?.map((material: MaterialsDto) => material.id),
    };
    const eventHandlers = {
      onSuccess: () => {
        if (!isDraft) {
          params?.projectId ? setIsOpenEditModal(true) : setIsOpenModal(true);
        }
      },
      onError: (err: any) => {
        if (!isDraft) {
          setErrorMsg(err.response?.data.message);
          setIsOpenErrorModal(true);
        }
      },
    };
    if (editMode) {
      updateTimedProject({ id: projectId, data: formData }, eventHandlers);
    } else {
      createTimedProject(formData, eventHandlers);
    }
  };

  useEffect(() => {
    if (initialValues) {
      // prevent editing if the user went through the url directly
      if (!editableStatuses.includes(initialValues.status)) {
        navigate('../');
      }

      const setInitialValues = async () => {
        const { body, ...rest } = initialValues;
        const extendedMaterials = await extendMaterials(initialValues.materials);
        const materialsPreview = extendedMaterials?.map(setMaterialStatus);

        form.reset({
          ...body,
          materials: rest.materials,
          materialsPreview,
        });
      };
      setInitialValues();
    }
  }, [initialValues]);

  return (
    <>
      <Box sx={{ width: '70%' }}>
        <MultiStep
          form={form}
          steps={steps}
          currentStep={currentStep}
          helperText={printHelperTextForSpecificCurrentStep(currentStep, helperTexts)}
          layoutOrientation={currentStep === 7 ? 'row' : 'column'}
          onChange={onStepChange}
          onSubmit={(data) => onSubmit(data, false)}
          onSaveAsDraft={() => setIsOpenDraftModal(true)}
          rightColumnRenderProp={() => <PreviewSidebar />}
          isSubmitLoading={
            !!(updateTimedProjectIsLoading === true || createTimedProjectIsLoading === true)
          }
        >
          <>
            {
              // Workaround to fix Editor issue with initialValue
              initialValues
                ? currentStep === 1 && form.getValues('overview.description') && <Overview />
                : currentStep === 1 && <Overview />
            }
            {currentStep === 2 && <About />}
            {currentStep === 3 && <Seller />}
            {currentStep === 4 && <Timeline />}
            {currentStep === 5 && <Budget />}
            {currentStep === 6 && <Materials resource="/timed-projects/upload-materials" />}
            {currentStep === 7 && <Preview />}
          </>
        </MultiStep>
        <TimedProjectCreateModal type="create" isOpen={openModal} setIsOpen={setIsOpenModal} />
        <TimedProjectCreateModal
          type="edit"
          isOpen={openEditModal}
          setIsOpen={setIsOpenEditModal}
        />
        <TimedProjectCreateModal
          type="draft"
          isOpen={openDraftModal}
          setIsOpen={setIsOpenDraftModal}
          onConfirm={() => {
            onSubmit(form.getValues(), true);
          }}
        />
        <TimedProjectCreateModal
          type="error"
          isOpen={openErrorModal}
          setIsOpen={setIsOpenErrorModal}
          errorMessage={errorMsg}
        />
        <TimedProjectCreateModal
          type="cancel"
          isOpen={openCancelModal}
          setIsOpen={setIsOpenCancelModal}
        />
      </Box>
    </>
  );
};

export default CreateTimedProject;
