import {
  Card, Divider, Stack, Typography,
} from '@mui/material';
import { ProjectDto } from 'tdc-web-backend/projects/schemas';
import { EnumCurrency } from 'tdc-web-backend/enums/enums';
import { convertDate, formatCurrency } from '../../../../../utils/helpers';

type CardDetailProps = {
  property: string;
  value: string;
}

interface GeneralDetailsProps {
  projectData: ProjectDto;
}

const CardDetail = (props: CardDetailProps) => {
  const {
    property,
    value,
  } = props;

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        sx={{
          justifyContent: 'space-between',
          marginY: '12px',
        }}
      >
        <Typography sx={{
          fontWeight: 500,
        }}
        >
          {property}
        </Typography>

        <Typography>{value ?? '-'}</Typography>

      </Stack>
    </>
  );
};

const GeneralDetailsSection = ({ projectData }: GeneralDetailsProps) => (
  <Stack
    spacing={4}
    width="30%"
    justifyContent="space-between"
  >
    <Typography sx={{
      fontWeight: 500,
      fontSize: '2em',
    }}
    >
      {projectData?.name}
    </Typography>
    <Typography sx={{
      fontSize: '1em',
    }}
    >
      {projectData?.description}
    </Typography>
    <Card sx={{
      padding: '16px',
      borderRadius: '8px',
      width: '100%',
    }}
    >
      <CardDetail
        property="Budget"
        value={projectData ? formatCurrency(projectData.budget as number, projectData.currency as EnumCurrency) : '-'}
      />
      <Divider />
      <CardDetail property="Start date" value={convertDate(projectData?.start)} />
      <Divider />
      <CardDetail property="End date" value={convertDate(projectData?.end)} />
    </Card>
  </Stack>
);

export default GeneralDetailsSection;
