import { Stack, Box } from '@mui/material';
import { useState } from 'react';
import NavigationDashboard from '../../layout/dashboard-navigation/NavigationDashboard';
import { adminMenuItem } from '../../layout/dashboard-navigation/MenuItem';

export interface MainProps {
  outlet: React.ReactElement | null
}

const AdminMain = ({ outlet }: MainProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

  return (
    <Box sx={{ flexGrow: 1, mt: '90px' }}>

      <Stack direction="row">
        <NavigationDashboard
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          items={adminMenuItem}
          isAdmin
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: '24px',
            px: '24px',
            height: '100%',
          }}
        >
          {outlet}
        </Box>
      </Stack>
    </Box>
  );
};

export default AdminMain;
