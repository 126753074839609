import { Avatar, Paper, Rating, Stack, Typography } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import {
  EnumTimedProjectPitchRejectFeedbackScope,
  EnumTimedProjectPitchStatus,
} from 'tdc-web-backend/enums/enums';
import {
  TimedProjectPitchContentCustomDto,
  TimedProjectPitchFeedbackDto,
} from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { useContext } from 'react';
import CustomChip from '../../../../../components/custom-chip/CustomChip';
import { convertDate } from '../../../../../utils/helpers';
import CustomButton from '../../../../../components/button/CustomButton';
import { DrawerContext } from '../DrawerContext';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';

type PitchesPanelCardProps = {
  pitch: TimedProjectPitchContentCustomDto;
};

const PitchesPanelCard = ({ pitch }: PitchesPanelCardProps) => {
  const supports = [];
  if (pitch.status === EnumTimedProjectPitchStatus.SecondRound) {
    supports.push('round2', 'round3');
  }
  if (pitch.status === EnumTimedProjectPitchStatus.FirstRound) {
    supports.push('round1', 'round2', 'round3');
  }

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: pitch.id,
    data: {
      supports,
      selected: false,
    },
  });

  const feedbackOverall = pitch.feedback?.find(
    (a: TimedProjectPitchFeedbackDto) =>
      a.type === EnumTimedProjectPitchRejectFeedbackScope.Overall,
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: 'white',
    borderRadius: '8px',
    cursor: 'pointer',
    opacity: isDragging ? 0.3 : 1,
  };

  const rejectedSellerStyle = {
    textDecoration: 'none',
    color: 'primaryDark.600',
  };

  const customChipProps = {
    label: 'rejected',
    boldText: true,
    sx: {
      height: '24px',
      width: 'fit-content',
      color: 'secondaryPink.700',
      bgcolor: 'secondaryPink.100',
      borderColor: 'secondaryPink.100',
      borderRadius: '3px',
      textTransform: 'uppercase',
      fontSize: '0.8125rem',
      letterSpacing: '0.05em',
      visibility: 'hidden',
    },
  };

  if (
    pitch.status === EnumTimedProjectPitchStatus.FirstRoundRejected ||
    pitch.status === EnumTimedProjectPitchStatus.SecondRoundRejected
  ) {
    rejectedSellerStyle.textDecoration = 'line-through';
    rejectedSellerStyle.color = 'primaryDark.500';

    customChipProps.sx.visibility = 'visible';
  } else if (pitch.status === EnumTimedProjectPitchStatus.Chosen) {
    style.background = ' linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)';
  } else if (pitch.isNew) {
    customChipProps.label = 'new';
    customChipProps.sx.color = 'secondaryBlue.700';
    customChipProps.sx.bgcolor = 'secondaryBlue.100';
    customChipProps.sx.borderColor = 'secondaryBlue.100';
    customChipProps.sx.visibility = 'visible';
  }

  const { mutate: markSeenMutate } = useUpdate({
    resource: `/timed-project-pitches`,
  });

  const markSeen = () => {
    if (pitch.isNew) {
      markSeenMutate({
        id: pitch.timedProjectPitch,
        suffix: 'seen',
      });
    }
  };

  const { isDrawerOpen, setIsDrawerOpen, setTimedProjectPitchId } = useContext(DrawerContext);

  return (
    <Paper ref={setNodeRef} style={style} {...listeners} {...attributes} variant="outlined">
      <Stack padding={2} paddingTop={2.5} spacing={1.5}>
        {/* View button which triggers the PitchDetailsDrawer */}
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <CustomChip {...customChipProps} />
          <CustomButton
            variant="primaryText"
            onClick={(e) => {
              setTimedProjectPitchId(pitch.id);
              setIsDrawerOpen(!isDrawerOpen);
              markSeen();
            }}
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
          >
            View
          </CustomButton>
        </Stack>

        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1}>
            {pitch.seller.avatar ? (
              <Avatar sx={{ width: 20, height: 20 }} variant="rounded" src={pitch.seller.avatar} />
            ) : (
              <Avatar sx={{ width: 20, height: 20 }} variant="rounded">
                {pitch.seller.name[0]}
              </Avatar>
            )}
            <Typography sx={rejectedSellerStyle} variant="body2">
              {pitch.seller.name}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="heading4" color="primaryDark.500" fontSize="0.8215rem">
              pitched
            </Typography>
            <Typography variant="heading4" color="primaryDark.600">
              {pitch.published && convertDate(pitch.published)}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="heading4" color="primaryDark.500" fontSize="0.8215rem">
              score
            </Typography>
            {feedbackOverall && (
              <Rating size="small" name="read-only" value={feedbackOverall.rate} readOnly />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default PitchesPanelCard;
