import Box from '@mui/material/Box';
import { Stack, Typography } from '@mui/material';
import Image from '../../../assets/img/public-pages/grid-project-benefits.png';
import AccordionGroups from '../../../components/public-screen-components/accordion-groups/AccordionGroups';
import { projectBenefitsArray } from '../utils';
import LAYOUT_DEFAULTS from '../../../layout/utils';

const ProjectBenefits = () => (
  <Stack
    direction="row"
    spacing={{ md: 8 }}
    my={{ xs: 10, md: 10 }}
    sx={{ ...LAYOUT_DEFAULTS }}
    justifyContent="space-between"
    alignItems="center"
  >
    <Box
      src={Image}
      alt="Reviewing metrics on iPad with the Apple pen"
      component="img"
      sx={{ width: '37%', display: { xs: 'none', md: 'block' } }}
    />

    <Stack
      justifyContent="start"
      alignItems="start"
      spacing={{ xs: 3, lg: 6 }}
      sx={{ width: { xs: '100%', lg: '50%' } }}
    >
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        Project Benefits
      </Typography>

      <Box
        alt="Reviewing metrics on iPad with the Apple pen"
        src={Image}
        component="img"
        sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}
      />

      <AccordionGroups group={projectBenefitsArray} image={Image} />
    </Stack>
  </Stack>
);

export default ProjectBenefits;
