import {
  Box,
  CSSObject,
  Stack,
  styled,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import 'animate.css';
import MuiDrawer from '@mui/material/Drawer';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { MenuItem } from './MenuItem';
import DashboardAppbar from './appbar/DashboardAppbar';
import Button from '../../components/button/Button';
import { ReactComponent as ArrowLeftFilled } from '../../assets/icons/layout-icons/ArrowLeftFilled.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/layout-icons/LogoutIcon.svg';
import FloatingHelpButton from '../../views/EndToEnd/ProjectDetail/FloatingHelpButton/FloatingHelpButton';
import { DRAWER_WIDTH_OPEN, DRAWER_WIDTH_CLOSED } from '../../views/Dashboard/Main';
import { primaryDark, primaryLight } from '../../color';

export interface NavigationDashboardProps {
  items: Array<MenuItem>;
  sx?: SxProps<Theme> | undefined;
  isAdmin?: boolean;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  showSidebar?: boolean;
}

export const APPBAR_HEIGHT = 90;
const sidebarBoxShadow = '6px 6px 15px rgba(56, 69, 114, 0.1)';

const openedMixin = (theme: Theme, shouldExtend?: boolean): CSSObject => ({
  width: DRAWER_WIDTH_OPEN,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  outline: 0,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: DRAWER_WIDTH_CLOSED,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: APPBAR_HEIGHT,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    borderRight: '1px solid #DCDCFF',
    marginTop: 20,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, true),
      '& .MuiDrawer-paper': openedMixin(theme, false),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const NavigationDashboard = ({
  sx,
  isAdmin,
  items,
  isSidebarOpen,
  setIsSidebarOpen,
}: NavigationDashboardProps) => {
  const theme = useTheme();
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsSidebarOpen(!isLessThanLarge);
  }, [isLessThanLarge]);

  const findActiveMenuLink = (name: string) => {
    if (name === 'home' && (pathname === '/dashboard/' || pathname === '/dashboard')) return true;
    return pathname.includes(name.toLowerCase());
  };
  const onCloseTransitionStyle = 'width 0.5s, padding 0.5s, all 0.2s ease';

  const handleSignOut = () => {
    localStorage.removeItem('AccessToken');
    localStorage.removeItem('userData');
    navigate('../');
  };

  const menuLinkStyle = {
    background: 'none',
    gap: '10px',
    justifyContent: 'start',
    fontSize: '14px',
    height: '25px',
    alignItems: 'center',
    borderRadius: 0,
    transition: onCloseTransitionStyle,
    pl: isSidebarOpen ? '25px' : '14px',
    minWidth: 0,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        zIndex: 1,
        height: '100vh',
        ...sx,
      }}
    >
      <DashboardAppbar
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        drawerWidth={DRAWER_WIDTH_OPEN}
        appbarHeight={APPBAR_HEIGHT}
      />
      <Drawer
        variant="permanent"
        open={isSidebarOpen}
        sx={{
          display: { xs: 'none', md: isSidebarOpen ? 'block' : 'none', bg: 'block' },
          position: { md: 'fixed', bg: 'fixed', lg: 'relative' },
          '.MuiDrawer-paperAnchorLeft': {
            boxShadow: {
              md: sidebarBoxShadow,
              bg: isSidebarOpen ? sidebarBoxShadow : 'none',
              lg: 'none',
            },
            overflow: 'visible',
          },
        }}
      >
        <DrawerHeader
          sx={{
            '&.MuiPaper-root': {
              p: 0,
              m: 0,
            },
            '&.MuiDrawer-paper': { borderRight: 0 },
          }}
        />
        <Stack justifyContent="space-between" height="calc(100% - 90px)">
          <Stack>
            <Button
              onButtonClick={() => navigate('../')}
              setUpperCase
              fontWeight="600"
              sx={{
                background: '#EEE9FF',
                gap: '10px',
                fontSize: '12px',
                height: '50px',
                justifyContent: 'start',
                alignItems: 'center',
                borderRadius: 0,
                transition: onCloseTransitionStyle,
                pt: '11px',
                pl: isSidebarOpen ? '25px' : '14px',
                mb: 6,
                borderLeft: '5px solid #EEE9FF',
                minWidth: 'auto',
              }}
            >
              <ArrowLeftFilled fill={theme.palette.primaryDark[600]} />
              {isSidebarOpen && (
                <Typography variant="body3" fontWeight="500" color="primaryDark.600">
                  to spona public
                </Typography>
              )}
            </Button>

            <Stack spacing={isAdmin ? 1.5 : 3}>
              {items?.map(({ Icon, ...menu }: MenuItem) => (
                <Button
                  onButtonClick={() => null}
                  linkTo={menu?.to}
                  setUpperCase
                  disabled={menu.disabled}
                  fontWeight="600"
                  color={findActiveMenuLink(menu?.name) ? primaryLight[700] : primaryDark[500]}
                  sx={{
                    ...menuLinkStyle,
                    borderLeft: findActiveMenuLink(menu?.name)
                      ? '5px solid #6D51F6'
                      : '5px solid #FFF',
                  }}
                >
                  <Icon
                    fill={findActiveMenuLink(menu?.name) ? theme.palette.secondary.main : '#79829B'}
                  />
                  {isSidebarOpen && menu.title}
                </Button>
              ))}
            </Stack>
          </Stack>
          <Button
            onButtonClick={() => handleSignOut()}
            setUpperCase
            fontWeight="600"
            sx={{
              ...menuLinkStyle,
              borderLeft: '5px solid #FFF',
              pointerEvents: 'all',
              color: primaryDark[500],
            }}
          >
            <LogoutIcon fill="#79829B" />
            {isSidebarOpen && 'Log out'}
          </Button>
        </Stack>

        <Stack
          height="100%"
          width="100%"
          alignItems="center"
          justifyContent="center"
          sx={{
            mt: 2,
            width: 20,
            height: 20,
            backgroundColor: '#F7F7F7',
            p: 3.5,
            boxShadow: isSidebarOpen ? '6px 6px 15px rgba(56, 69, 114, 0.1)' : 'none',
            borderRadius: isSidebarOpen ? '0 8px 0px 0' : 'none',
          }}
        >
          <FloatingHelpButton />
        </Stack>
      </Drawer>
    </Box>
  );
};

export default NavigationDashboard;
