import { Box, Chip, ChipProps, Stack, SxProps, Theme } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';
import { ReactComponent as TrophyIcon } from '../../assets/icons/layout-icons/trophy2-fill.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/verified-icon.svg';
import { concatinatedAllStatusesMap, EnumAllStatuses } from '../../utils/helpers';
import CustomTooltip from '../tooltip/CustomTooltip';
import { primaryDark, secondaryBlue } from '../../color';

interface StatusChipFieldProps {
  text: string;
  componentReturnType?: 'chip' | 'dot' | string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  showChevronIcons?: boolean;
  isChipClicked?: boolean;
  setIsChipClicked?: Dispatch<SetStateAction<boolean>>;
  sx?: SxProps<Theme> | undefined;
  className?: string;
  chipProps?: ChipProps;
}

const chipStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: 'fit-content',
  borderRadius: '3px',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '11px',
  letterSpacing: '0.07rem',
  '.MuiChip-label': {
    maxHeight: '18px',
  },
};

const StatusChipField = ({
  text,
  componentReturnType = 'chip',
  showChevronIcons = false,
  isChipClicked,
  setIsChipClicked,
  onClick,
  sx,
  className,
  chipProps,
}: StatusChipFieldProps) => {
  const setStatusBackgroundAndTextColor = () => {
    switch (text) {
      // GREEN
      case EnumAllStatuses.Active:
      case EnumAllStatuses.Accepted:
      case EnumAllStatuses.Approved:
      case EnumAllStatuses.Completed:
      case EnumAllStatuses.Paid:
      case EnumAllStatuses.Published:
      case EnumAllStatuses.FirstRound:
      case EnumAllStatuses.SecondRound:
        if (componentReturnType === 'chip') {
          return {
            backgroundColor: 'secondaryGreen.100',
            textColor: 'secondaryGreen.700',
            borderColor: 'secondaryGreen.200',
          };
        }

        return {
          backgroundColor: 'secondaryGreen.500',
          borderColor: 'secondaryGreen.200',
        };
      // BLUE
      case EnumAllStatuses.InProgress:
      case EnumAllStatuses.UnderReview:
        if (componentReturnType === 'chip') {
          return {
            backgroundColor: 'secondaryBlue.100',
            textColor: 'secondaryBlue.700',
            borderColor: 'secondaryBlue.100',
          };
        }

        return {
          backgroundColor: 'secondaryBlue.700',
          borderColor: 'secondaryBlue.100',
        };
      // ORANGE
      case EnumAllStatuses.Pending:
      case EnumAllStatuses.Late:
        if (componentReturnType === 'chip') {
          return {
            backgroundColor: 'secondaryYellow.100',
            textColor: 'secondaryYellow.700',
            borderColor: 'secondaryYellow.200',
          };
        }

        return {
          backgroundColor: 'secondaryYellow.700',
          borderColor: 'secondaryYellow.200',
        };
      // ORANGE-YELLOW
      case EnumAllStatuses.FirstRoundWithdrawn:
      case EnumAllStatuses.SecondRoundWithdrawn:
        if (componentReturnType === 'chip') {
          return {
            backgroundColor: 'secondaryOrange.201',
            textColor: 'secondaryOrange.501',
            borderColor: 'secondaryOrange.101',
          };
        }

        return {
          backgroundColor: 'secondaryOrange.501',
          borderColor: 'secondaryOrange.201',
        };
      // GREY
      case EnumAllStatuses.Draft:
      case EnumAllStatuses.Closed:
      case EnumAllStatuses.Negotiation:
        if (componentReturnType === 'chip') {
          return { backgroundColor: 'primaryDark.200', textColor: 'primaryDark.500' };
        }

        return {
          backgroundColor: 'primaryDark.500',
          borderColor: 'primaryDark.300',
        };
      // RED
      case EnumAllStatuses.Canceled:
      case EnumAllStatuses.FirstRoundRejected:
      case EnumAllStatuses.SecondRoundRejected:
      case EnumAllStatuses.Rejected:
        if (componentReturnType === 'chip') {
          return {
            backgroundColor: '#FFF2F4',
            textColor: 'secondaryPink.500',
            borderColor: 'secondaryPink.200',
          };
        }

        return {
          backgroundColor: 'secondaryPink.700',
          borderColor: 'secondaryPink.200',
        };
      case EnumAllStatuses.Hired:
        return {
          background: 'linear-gradient(240.98deg, #E6E8FF 0%, #FFEAE1 68.46%, #FFFBEA 99.58%)',
          textColor: 'primaryDark.600',
          icon: <TrophyIcon fill={primaryDark[600]} />,
        };
      case EnumAllStatuses.Verified:
        return {
          backgroundColor: 'secondaryBlue.50',
          textColor: 'secondaryBlue.700',
          icon: <VerifiedIcon fill={secondaryBlue[700]} />,
        };
      // GREY is default
      default:
        if (componentReturnType === 'chip') {
          return { background: 'primaryDark.200', textColor: 'primaryDark.700' };
        }

        return {
          backgroundColor: 'primaryDark.500',
          borderColor: 'primaryDark.300',
        };
    }
  };

  return (
    <>
      {componentReturnType === 'chip' ? (
        <>
          {showChevronIcons ? (
            <Stack
              direction="row"
              alignItems="center"
              onClick={(e) => {
                onClick?.(e);
                setIsChipClicked?.((prevState) => !prevState);
              }}
              sx={{
                px: 1,
                pl: 1.5,
                color: setStatusBackgroundAndTextColor().textColor,
                bgcolor: setStatusBackgroundAndTextColor().backgroundColor,
                borderRadius: '3px',
                ...sx,
              }}
            >
              <Chip
                clickable={false}
                className={className || undefined}
                sx={{
                  ...chipStyles,
                  bgcolor: 'transparent',
                  cursor: 'pointer',
                  color: setStatusBackgroundAndTextColor().textColor,
                }}
                label={concatinatedAllStatusesMap.get(text)}
                {...chipProps}
              />

              {showChevronIcons && (
                <>
                  {isChipClicked ? (
                    <ExpandLessIcon sx={{ width: 20, height: 20 }} />
                  ) : (
                    <ExpandMoreIcon sx={{ width: 20, height: 20 }} />
                  )}
                </>
              )}
            </Stack>
          ) : (
            <CustomTooltip
              sx={{ bgcolor: 'primaryDark.700', fontSize: '14px' }}
              arrow={false}
              title={concatinatedAllStatusesMap.get(text) as string}
            >
              <Chip
                icon={setStatusBackgroundAndTextColor().icon || undefined}
                className={className || undefined}
                sx={{
                  ...chipStyles,
                  color: setStatusBackgroundAndTextColor().textColor,
                  backgroundColor: setStatusBackgroundAndTextColor().backgroundColor,
                  background: setStatusBackgroundAndTextColor().background,
                  ...sx,
                }}
                label={concatinatedAllStatusesMap.get(text)}
                {...chipProps}
              />
            </CustomTooltip>
          )}
        </>
      ) : (
        <CustomTooltip placement="top" title={concatinatedAllStatusesMap.get(text) as string}>
          <Box
            sx={{
              backgroundColor:
                setStatusBackgroundAndTextColor().background !== null
                  ? setStatusBackgroundAndTextColor().backgroundColor
                  : 'secondaryGreen.500',
              borderRadius: '50%',
              width: 18,
              height: 18,
              border: '3px solid',
              borderColor:
                setStatusBackgroundAndTextColor().borderColor !== ''
                  ? setStatusBackgroundAndTextColor().borderColor
                  : 'secondaryGreen.200',
              ...sx,
            }}
          />
        </CustomTooltip>
      )}
    </>
  );
};
export default StatusChipField;
