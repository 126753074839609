/* eslint-disable jsx-a11y/anchor-is-valid */
import { Drawer, Typography, MenuItem, Divider } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { Dispatch, SetStateAction, useState } from 'react';
import Link from '@mui/material/Link';
import { useLocation, useNavigate } from 'react-router-dom';
import LAYOUT_DEFAULTS from '../../../utils';
import { PUBLIC_APPBAR_HEIGHT } from '../Navigation';
import { NavPage } from '../utils';

const ANIMATION_DURATION = 300;

interface MobileMenuProps {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  pages: NavPage[];
}

const MobileMenu = ({ isMobileMenuOpen, setIsMobileMenuOpen, pages }: MobileMenuProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isAnimating, setIsAnimating] = useState<Record<string, boolean>>({});

  const isActiveLink = (link: string): boolean => location.pathname === link;

  const onMobileMenuLinkClick = (link: string, id: string) => {
    // if already animating, prevent further clicks
    if (isAnimating[id]) return;

    const clone = { ...(isAnimating as Record<string, boolean>) };
    clone[id] = !clone[id];

    // start the animation by updating the state
    setIsAnimating(clone);

    // simulate a delay for the animation to finish (adjust the time as needed)
    setTimeout(() => {
      // after the animation is complete, reset the state
      // setIsAnimating(false);
      setIsAnimating({ [id]: false });
      navigate(link);

      setIsMobileMenuOpen(false);
      // adjust the time to match animation duration
    }, ANIMATION_DURATION);
  };

  return (
    <Box sx={{ zIndex: 1000000, position: 'relative' }}>
      <Drawer
        anchor="bottom"
        variant="persistent"
        open={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        sx={{ '.MuiPaper-root': { border: 'none !important' } }}
      >
        <Stack
          spacing={3}
          justifyContent="start"
          alignItems="start"
          sx={{
            height: `calc(100vh - ${PUBLIC_APPBAR_HEIGHT}px)`,
            pt: 3,
            ...LAYOUT_DEFAULTS,
          }}
        >
          {pages.map((page, index) => (
            <Stack spacing="6px">
              <Typography variant="headline5" color="primaryLight.500">
                {page.title}
              </Typography>

              {page.links?.map((link) => (
                <Box sx={{ pl: 2 }}>
                  <Link
                    underline="none"
                    key={link.id}
                    onClick={() => onMobileMenuLinkClick(link.to, link.id)}
                    sx={{
                      display: 'inline-block',
                      width: 'fit-content',
                      '&.MuiLink-root:after': {
                        display: 'block',
                        content: '""',
                        // start with a transparent border
                        borderBottom: '2px solid',
                        borderColor:
                          isAnimating[link.id] || isActiveLink(link.to) ? 'black' : 'transparent',
                        // change the transform origin to 'left'
                        transformOrigin: 'left',
                        // transform: 'scaleX(0)', // Initially scale to 0 width
                        // control the animation state
                        transform:
                          isAnimating[link.id] || isActiveLink(link.to) ? 'scaleX(1)' : 'scaleX(0)',
                        transition: `transform ${ANIMATION_DURATION}ms ease-in`,
                      },
                    }}
                  >
                    <MenuItem
                      disableRipple
                      disableTouchRipple
                      sx={{ p: 0, minHeight: 'fit-content', width: 'fit-content' }}
                    >
                      <Typography
                        variant="publicHeadline10"
                        fontSize="1.25rem !important"
                        fontFamily="Gellix-Regular"
                        fontWeight={600}
                        lineHeight="181%"
                      >
                        {link.title}
                      </Typography>
                    </MenuItem>
                  </Link>
                </Box>
              ))}
            </Stack>
          ))}

          <Divider sx={{ width: '100%', borderColor: 'primaryLight.100' }} />

          {/* login and register buttons */}
          <Stack spacing="6px">
            <Link
              key="login"
              underline="none"
              onClick={() => onMobileMenuLinkClick('/login', '-1')}
              sx={{
                pl: 2,
                display: 'inline-block',
                width: 'fit-content',
                '&.MuiLink-root:after': {
                  display: 'block',
                  content: '""',
                  borderBottom: '2px solid',
                  // start with a transparent border
                  borderColor:
                    isAnimating['-1'] || isActiveLink('/login') ? 'black' : 'transparent',
                  // change the transform origin to 'left'
                  transformOrigin: 'left',
                  // initially scale to 0 width
                  // control the animation state
                  transform:
                    isAnimating['-1'] || isActiveLink('/login') ? 'scaleX(1)' : 'scaleX(0)',
                  transition: `transform ${ANIMATION_DURATION}ms ease-in`,
                },
              }}
            >
              <Typography
                variant="publicHeadline10"
                fontSize="1.25rem !important"
                fontFamily="Gellix-Regular"
                fontWeight={600}
                color="common.black"
                lineHeight="181%"
              >
                Log in
              </Typography>
            </Link>

            <Link
              key="register"
              underline="none"
              onClick={() => onMobileMenuLinkClick('/register', '-2')}
              sx={{
                pl: 2,
                display: 'inline-block',
                width: 'fit-content',
                '&.MuiLink-root:after': {
                  display: 'block',
                  content: '""',
                  borderBottom: '2px solid',
                  // start with a transparent border
                  borderColor:
                    isAnimating['-2'] || isActiveLink('/register') ? 'black' : 'transparent',
                  // change the transform origin to 'left'
                  transformOrigin: 'left',
                  // initially scale to 0 width
                  // control the animation state
                  transform:
                    isAnimating['-2'] || isActiveLink('/register') ? 'scaleX(1)' : 'scaleX(0)',
                  transition: `transform ${ANIMATION_DURATION}ms ease-in`,
                },
              }}
            >
              <Typography
                variant="publicHeadline10"
                fontSize="1.25rem !important"
                fontFamily="Gellix-Regular"
                fontWeight={600}
                color="common.black"
                lineHeight="181%"
              >
                Sign up
              </Typography>
            </Link>
          </Stack>
        </Stack>
      </Drawer>
    </Box>
  );
};

export default MobileMenu;
