import { Box, Stack, SxProps, Theme, useMediaQuery } from '@mui/material';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import { useParams } from 'react-router-dom';
import CompanyProfileBannerDefaultImage from '../../../assets/img/public-pages/company-profile-banner-default.svg';
import useGetOne from '../../../utils/hooks/crud-hooks/useGetOne';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import CompanyProfileDesktopView from './components/CompanyProfileDesktopView';
import theme from '../../../theme';
import CompanyProfileMobileView from './components/CompanyProfileMobileView';

export const cardStyles: SxProps<Theme> = {
  border: '1px solid',
  borderColor: 'secondaryBlue.100',
  padding: '24px',
  borderRadius: '12px',
};

const CompanyProfilePublicScreen = () => {
  const params = useParams();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { data: company } = useGetOne<CompanyDto>({
    resource: `companies/${params?.companyId}`,
    enabled: !!params?.companyId,
    refetchOnWindowFocus: false,
  });

  const companyData = company?.data;
  return (
    <Stack>
      <Box
        mt={`${PUBLIC_APPBAR_HEIGHT}px`}
        sx={{ height: '10.75rem', backgroundColor: 'primaryLight.500' }}
      >
        <Box
          sx={{
            backgroundImage: `url(${CompanyProfileBannerDefaultImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            height: '100%',
          }}
        />
      </Box>
      {isMobileView ? (
        <CompanyProfileMobileView company={companyData} />
      ) : (
        <CompanyProfileDesktopView company={companyData} />
      )}
    </Stack>
  );
};

export default CompanyProfilePublicScreen;
