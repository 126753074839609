import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import DynamicMultistep from '../../../../components/multi-step/DynamicMultistep';
import theme from '../../../../theme';
import StepFirst from './steps/StepFirst';
import { StepSecond } from './steps/StepSecond';
import useCreateProjectStore from './useCreateProjectStore';

const CreateProject = () => {
  const deleteEverything = useCreateProjectStore((state) => state.deleteEverything);
  const [isCurrentStepPreview, setIsCurrentStepPreview] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSubmitForm = (type: boolean) => {
    setIsModalOpen(false);
  };

  useEffect(() => () => deleteEverything(), []);

  return (
    <Stack spacing={20} height="100%" direction="row" justifyContent="space-between">
      <DynamicMultistep
        stepComponents={[
          {
            component: <StepFirst />,
            label: 'Overview',
          },
          {
            component: <StepSecond
              shouldModalOpen={isModalOpen}
              handleCloseModal={(type) => handleSubmitForm(type)}
            />,
            label: 'Overview',
          },
        ]}
        showSaveDraftButton
        setIsCurrentStepPreview={setIsCurrentStepPreview}
        width="100%"
        onFinish={() => setIsModalOpen(true)}
        // onSaveDraft={() => onSaveDraft()}
        // onSaveDraft={() => { }}
        // finishComponent={(
        //   <SuccessScreen {...successScreenProps} />
        // )}
        isSubmitButtonLoading={false}
        minHeight={800}
        pushButtonsToBottom
        // isSubmitSuccessful={isSubmitSuccessful}
        buttonsHaveRadius={false}
        horizontalLineColor={theme.palette.grey[400]}
        buttonsTopPadding={10}
        shouldShowStepper={false}
      />

      {
        !isCurrentStepPreview
        && (
          <Box sx={{ width: '28%', color: 'common.white' }}>
            <Box sx={{
              position: 'sticky', top: 15, height: '88vh', bgcolor: 'primary.main',
            }}
            >
              <Stack height="100%" justifyContent="center" alignItems="center">
                <Stack spacing={3} width="100%" sx={{ px: 4 }}>
                  <Typography variant="h4" sx={{ fontSize: '2rem', maxWidth: 100 }}>
                    Placeholder
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Box>
        )
      }
    </Stack>
  );
};

export default CreateProject;
