import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import BuyerManageView from './views/BuyerManageView';
import CustomButton from '../../../components/button/CustomButton';
import BuyerHireView from './views/BuyerHireView ';
import SellerManageView from './views/SellerManageView';

type Props = {
  type?: string;
};

const PricingBuyer = ({ type }: Props) => {
  const [activeBtn, setActiveBtn] = useState(type);

  const handleTabVariants = (btnName: string) =>
    activeBtn === btnName ? 'primary' : 'primaryLight';

  return (
    <Stack
      mt={`${PUBLIC_APPBAR_HEIGHT}px`}
      pt={10}
      gap={3}
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="publicHeading2" align="center" color="primaryDark.900">
        Plans & Pricing
      </Typography>
      <Typography
        variant="publicBody5Regular"
        width={{ lg: '30%' }}
        align="center"
        color="primaryDark.900"
      >
        A plan for every company's project needs. Get started for free.
      </Typography>
      {type !== 'seller' && (
        <Stack direction="row">
          <CustomButton
            sx={{ width: { lg: '15rem' }, borderRadius: '20px 0px 0px 20px' }}
            variant={handleTabVariants('hire')}
            onClick={() => setActiveBtn('hire')}
          >
            Hire and manage
          </CustomButton>
          <CustomButton
            sx={{ width: { lg: '15rem' }, borderRadius: '0px 20px 20px 0px' }}
            variant={handleTabVariants('manage')}
            onClick={() => setActiveBtn('manage')}
          >
            Manage
          </CustomButton>
        </Stack>
      )}
      {activeBtn === 'hire' ? (
        <BuyerHireView />
      ) : activeBtn === 'manage' ? (
        <BuyerManageView />
      ) : (
        <SellerManageView />
      )}
    </Stack>
  );
};

export default PricingBuyer;
