import { OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useCustomInputContext } from '../CustomInputContext';

const CustomInputText = () => {
  const { field, hasError, adornment, placeholder, type, disabled } = useCustomInputContext();

  return (
    <OutlinedInput
      {...field}
      placeholder={placeholder}
      disabled={disabled}
      type={type}
      endAdornment={
        hasError || adornment?.icon ? (
          <InputAdornment position="end">
            {hasError && <ErrorIcon sx={{ color: 'secondaryPink.500' }} />}

            {adornment?.icon && (
              <IconButton onClick={adornment?.onClick}>{adornment?.icon}</IconButton>
            )}
          </InputAdornment>
        ) : null
      }
    />
  );
};

export default CustomInputText;
