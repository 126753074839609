import { useState, ReactNode, MouseEvent } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Stack,
  Button as MUIButton,
  Paper,
  Box,
  MenuItem,
  Popper,
  ClickAwayListener,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { primaryLight } from '../../color';

interface RenderDropdownButtonElementProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

interface CustomDropdownProps {
  renderDropdownButtonElement?: ({ onClick }: RenderDropdownButtonElementProps) => ReactNode;
  menuItemsContents: {
    key: string;
    icon?: JSX.Element;
    text?: string;
    disabled?: boolean;
    onClick: () => void;
  }[];
  defaultValue?: string;
  onClickAway?: () => void;
  dropdownSx?: SxProps<Theme> | undefined;
  dropdownOrientation?: 'horizontal' | 'vertical';
  classNames?: string;
}

const CustomDropdown = ({
  renderDropdownButtonElement,
  menuItemsContents,
  defaultValue,
  onClickAway,
  dropdownSx,
  dropdownOrientation = 'vertical',
  classNames,
}: CustomDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);

    // additional clickaway function
    if (onClickAway) {
      onClickAway();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classNames}>
        {renderDropdownButtonElement ? (
          renderDropdownButtonElement({ onClick: (e) => handleClick(e) })
        ) : (
          // the default dropdown button
          <MUIButton
            onClick={(e) => {
              handleClick(e);
            }}
            sx={{ p: 0, minWidth: 'unset' }}
          >
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                borderRadius: '6px',
                height: 32,
                width: 26,
                '&:hover': {
                  bgcolor: '#E5E8FF',
                  '&>*': {
                    color: 'primaryLight.500',
                  },
                },
              }}
            >
              {dropdownOrientation === 'vertical' ? (
                <MoreVertIcon sx={{ color: 'primaryDark.400' }} />
              ) : (
                <MoreHorizIcon sx={{ color: 'primaryDark.400' }} />
              )}
            </Stack>
          </MUIButton>
        )}

        {/* the actual dropdown and its contents */}
        <Popper anchorEl={anchorEl} open={open} placement="bottom-start">
          <Paper
            elevation={0}
            sx={{
              mt: 0.85,
              width: 130,
              border: 'none',
              borderColor: '#E5E8FF',
              borderRadius: '8px',
              overflowY: 'auto',
              boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
              '& .MuiList-root': {
                py: 0,
              },
              '& .MuiList-root>*': {
                borderBottom: 'none',
              },
              ...dropdownSx,
            }}
          >
            {menuItemsContents.map(({ key, text, icon, disabled, onClick }) => (
              <MenuItem
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                  setTimeout(() => {
                    setAnchorEl(null);
                  }, 180);
                }}
                sx={{
                  height: 40,
                  bgcolor: defaultValue === key ? '#E5E8FF' : 'transparent',
                  '&:hover': {
                    bgcolor: '#E5E8FF',
                    '&>*>*:first-child>*': {
                      fill: primaryLight[500],
                    },
                  },
                }}
              >
                <Stack spacing={1.5} alignItems="center" direction="row">
                  {icon}

                  {/* {text} */}
                  <Typography color="primaryDark.500" fontWeight={500}>
                    {text}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default CustomDropdown;
