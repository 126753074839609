import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { EnumBlogStatus } from 'tdc-web-backend/enums/enums';
import AddIcon from '@mui/icons-material/Add';
import useTable from '../../../components/table/useTable';
import CustomButton from '../../../components/button/CustomButton';
import TableWithChipFilters from '../../../components/table/TableWithChipFilters';
import CustomChip from '../../../components/custom-chip/CustomChip';
import { convertDate } from '../../../utils/helpers';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';

const BlogList = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'headline',
      headerName: 'title',
      editable: false,
      flex: 0.3,
    },
    {
      field: 'author',
      headerName: 'created by',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {`${params.row.author.firstName} ${params.row.author.lastName}`}
        </Typography>
      ),
    },
    {
      field: 'categories',
      headerName: 'categories',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Stack direction="row" spacing={1}>
          {params.row.categories.map((category: string) => (
            <CustomChip label={category} />
          ))}
        </Stack>
      ),
    },
    {
      field: 'published',
      headerName: 'published',
      editable: false,
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{convertDate(params.value)}</Typography>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => <StatusChipField text={params.value} />,
    },
  ];

  const { resourceLimit, chipFilters, ...props } = useTable({
    resourceUrl: 'blogs',
    withTabs: true,
    chipFilterQueryParams: [
      {
        title: 'All',
      },
      {
        title: 'published',
        search: { status: EnumBlogStatus.Published },
      },
      {
        title: 'draft',
        search: { status: EnumBlogStatus.Draft },
      },
    ],
  });

  return (
    <TableWithChipFilters
      numberOfRowsPerPage={resourceLimit}
      columns={columns}
      checkboxSelection
      // TODO: enable once edit page is done
      // onRowClick={() => navigate('./edit')}
      withElements={{
        withTitle: 'Blogs',
        withCta: (
          <CustomButton
            startIcon={<AddIcon sx={{ fontSize: '25px !important' }} />}
            // TODO: enable once edit page is done
            // onClick={() => navigate('./new')}
          >
            Add new
          </CustomButton>
        ),
      }}
      chipFilters={
        chipFilters as { label: string; isLoading: boolean; count: number | undefined }[]
      }
      sx={{
        '& .MuiDataGrid-cell': {
          overflowX: 'auto',
        },
      }}
      {...props}
    />
  );
};

export default BlogList;
