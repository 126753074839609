import { FunctionComponent, SVGProps } from 'react';
import { ReactComponent as HomeLinkIcon } from '../../assets/icons/layout-icons/HomeLinkIcon.svg';
import { ReactComponent as SuitcaseCheckIcon } from '../../assets/icons/layout-icons/SuitcaseCheckIcon.svg';
import { ReactComponent as PieChartIcon } from '../../assets/icons/layout-icons/PieChartIcon.svg';
import { ReactComponent as JournalRichtextIcon } from '../../assets/icons/layout-icons/JournalRichtextIcon.svg';
import { ReactComponent as LifePreserverIcon } from '../../assets/icons/layout-icons/LifePreserverIcon.svg';
import { ReactComponent as Blogs } from '../../assets/icons/layout-icons/blogs.svg';
import { ReactComponent as RequestProject } from '../../assets/icons/project-icons/RequestProject.svg';
import { ReactComponent as TimedProject } from '../../assets/icons/project-icons/TimedProject.svg';
import { ReactComponent as StarIcon } from '../../assets/icons/star.svg';

export interface MenuItem {
  id?: number;
  title: string;
  to?: string;
  Icon: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  name: string;
  children?: MenuItem[];
  disabled?: boolean;
}

export const dashboardMenuItemsBuyer: MenuItem[] = [
  {
    title: 'Home',
    to: '/dashboard',
    Icon: HomeLinkIcon,
    name: 'home',
  },
  {
    title: 'Projects',
    to: './timed-projects',
    Icon: SuitcaseCheckIcon,
    name: 'timed-projects',
    disabled: false,
  },
  {
    title: 'End-to-end',
    to: './end-to-end',
    Icon: LifePreserverIcon,
    name: 'end-to-end',
    disabled: false,
  },
  // {
  //   title: 'Insights',
  //   to: '/insights',
  //   Icon: PieChartIcon,
  //   name: 'insights',
  //   disabled: true,
  // },
];

export const dashboardMenuItemsSeller: (dissableAll?: boolean) => MenuItem[] = (
  disableAll = false,
) => [
  {
    title: 'Home',
    to: '/dashboard',
    Icon: HomeLinkIcon,
    name: 'home',
  },
  // {
  //   title: 'Content',
  //   to: './content',
  //   Icon: JournalRichtextIcon,
  //   name: 'timed-projects',
  //   disabled: true,
  // },
  {
    title: 'Case studies',
    to: './case-studies/',
    Icon: JournalRichtextIcon,
    name: 'case-studies',
    disabled: !!disableAll,
  },
  {
    title: 'Pitches',
    to: './pitches',
    Icon: SuitcaseCheckIcon,
    name: 'pitches',
    disabled: !!disableAll,
  },
  {
    title: 'End-to-end',
    to: './end-to-end',
    Icon: LifePreserverIcon,
    name: 'end-to-end',
    disabled: !!disableAll,
  },
  // {
  //   title: 'Insights',
  //   to: '/insights',
  //   Icon: PieChartIcon,
  //   name: 'insights',
  //   disabled: true, // This is temp on true ether way
  // },
];

export const adminMenuItem: MenuItem[] = [
  {
    title: 'Home',
    to: '../admin',
    Icon: HomeLinkIcon,
    name: 'home',
    disabled: true,
  },
  {
    title: 'Projects (E2E)',
    to: '../admin/end-to-end/projects',
    Icon: SuitcaseCheckIcon,
    name: 'projects',
    disabled: false,
  },
  {
    title: 'Payments',
    to: '../admin/end-to-end/payments',
    Icon: PieChartIcon,
    name: 'payments',
    disabled: false,
  },
  {
    title: 'Seller (service)',
    to: '../admin/companies/sos',
    Icon: PieChartIcon,
    name: 'sos',
    disabled: false,
  },
  {
    title: 'Seller (tool)',
    to: '../admin/companies/tos',
    Icon: PieChartIcon,
    name: 'tos',
    disabled: false,
  },
  {
    title: 'Buyer',
    to: '../admin/companies/buyer',
    Icon: PieChartIcon,
    name: 'buyer',
    disabled: false,
  },
  {
    title: 'Projects (Hire)',
    to: '../admin/timed-projects/',
    Icon: TimedProject,
    name: 'timed-projects',
    disabled: false,
  },
  {
    title: 'Case study',
    to: '../admin/case-study/',
    Icon: RequestProject,
    name: 'case-study',
    disabled: false,
  },
  {
    title: 'Study review',
    to: '../admin/case-study-review/',
    Icon: StarIcon,
    name: 'review',
    disabled: false,
  },
  {
    title: 'Pitching',
    to: '../admin/pitching/',
    Icon: LifePreserverIcon,
    name: 'pitching',
    disabled: false,
  },
  {
    title: 'Seller Archive',
    to: '../admin/seller-archive/',
    Icon: PieChartIcon,
    name: 'archive',
    disabled: false,
  },
  {
    title: 'C. Study Archive',
    to: '../admin/case-study-archive/',
    Icon: PieChartIcon,
    name: 'archive',
    disabled: false,
  },
  {
    title: 'Footer seller',
    to: '../admin/footer/seller_archive',
    Icon: SuitcaseCheckIcon,
    name: 'footer_seller',
    disabled: false,
  },
  {
    title: 'Footer c. study',
    to: '../admin/footer/case_study_archive',
    Icon: SuitcaseCheckIcon,
    name: 'footer_case',
    disabled: false,
  },
  {
    title: 'Blog',
    to: '../admin/blogs',
    Icon: Blogs,
    name: 'blog',
    disabled: false,
  },
];
