import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface CreateProjectState {
  createProjectInput: Record<string, any>;
  setCreateProjectInput: (value: Record<string, any>) => void;
  deleteEverything: () => void;
}

const useCreateProjectStore = create<CreateProjectState>(devtools((set) => ({
  createProjectInput: {},
  setCreateProjectInput: (value: Record<string, any>) => set((state) => (
    { createProjectInput: { ...state.createProjectInput, ...value } }
  )),
  deleteEverything: () => set({
    createProjectInput: {},
  }),
})));

export default useCreateProjectStore;
