import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Card, Stack,
  Typography,
} from '@mui/material';
import { EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { convertDate } from '../../../../../utils/helpers';
import { TaskStatus } from '../../../../../components/task-status/TaskStatus';
import Link from '../../../../../components/link/Link';

type TablePanelGrid = {
  rows: ContractDto[];
}

const ContractDataGrid = ({
  rows,
}: TablePanelGrid) => (

  <Card sx={{
    padding: '16px',
    borderRadius: '16px',
    width: '40%',
  }}
  >

    <Stack direction="row" justifyContent="space-between">
      <Typography sx={{
        fontWeight: 500,
        fontSize: '1.2em',
        marginBottom: '12px',
      }}
      >
        Contracts
      </Typography>

      <Link to="./contracts">
        <Typography fontSize="0.865rem" color="grey.600">view all</Typography>
      </Link>
    </Stack>
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(e) => e.id}
      disableSelectionOnClick
      hideFooter
      sx={{ minHeight: '284px', maxHeight: '90%' }}
    />
  </Card>
);

export default ContractDataGrid;

export const mapTempMilestoneStatus = new Map([
  [EnumProjectMilestoneStatus.Approved, 'Approved'],
  [EnumProjectMilestoneStatus.Completed, 'Completed'],
  [EnumProjectMilestoneStatus.InProgress, 'In progress'],
  [EnumProjectMilestoneStatus.Negotiation, 'Negotiation'],
  [EnumProjectMilestoneStatus.Pending, 'Pending'],
  [EnumProjectMilestoneStatus.UnderReview, 'Under review'],

]);

const columns: GridColDef[] = [

  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <TaskStatus text={params.value} />
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{
        fontSize: '0.875rem',
      }}
      >
        {params.row.name ?? '-'}
      </Typography>
    ),

  },
  {
    field: 'signed',
    headerName: 'Signed',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{
        fontSize: '0.875rem',
        paddingLeft: !params.value ? '38px' : 0,
      }}
      >
        {convertDate(params.row.signed) ?? '-'}
      </Typography>
    ),
  },
];
