import { IconButton, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TaskDto } from 'tdc-web-backend/tasks/schemas';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { useEffect, useState } from 'react';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import { red } from '@mui/material/colors';
import SubtaskList from './SubTask/SubtaskList';
import TaskStatusCard from './TaskStatusCard/TaskStatusCard';
import EndToEndDetailScreenTypeEnum from '../../../utils/enums/end-to-end-detail-screen-type';
import { MilestoneBudgetComponent } from '../Milestone/MilestoneBudgetComponent';
import TaskEditCard from './TaskEditCard/TaskEditCard';
import { convertDate } from '../../../utils/helpers';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import useDetermineCompanyRoles from '../../../utils/hooks/useDetermineCompanyRoles';
import FlagTypeEnum from '../../../utils/enums/flag-type';
import FlagAndUnflagModal from '../../../components/flag-modal-and-unflag-modal/FlagAndUnflagModal';
import TaskDropdownChip from './TaskDropdownChip';
import SubtaskAddModal from './Modals/SubtasksAddModal';
import DashboardCard from '../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';

const TaskDetailScreen = () => {
  const { taskId } = useParams();
  const { data: taskData } = useReferenceOne<TaskDto>({ resource: 'tasks', id: taskId });

  const [isTaskFlagged, setIsTaskFlagged] = useState<boolean>(false);

  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const [isFlagAndUnflagModalOpen, setIsFlagAndUnflagModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const isFlagged = isUserBuyer ? taskData?.buyerFlagged : taskData?.sellerFlagged;
    setIsTaskFlagged(isFlagged as boolean);
  }, [taskData]);

  return (
    <>
      {taskData && (
        <>
          <FlagAndUnflagModal
            entityId={taskData.id as string}
            entityType={EndToEndDetailScreenTypeEnum.Task}
            actionType={(() => {
              // if user is buyer
              if (isUserBuyer) {
                // if buyer flagged a milestone, action will be to unflag it
                if (taskData.buyerFlagged) return FlagTypeEnum.Unflag;

                // if buyer did not flag a milestone, action will be to flag it
                return FlagTypeEnum.Flag;
              }

              // if user is seller
              // if seller flagged a milestone, action will be to unflag it
              if (taskData.sellerFlagged) return FlagTypeEnum.Unflag;

              // if seller did not flag a milestone, action will be to flag it
              return FlagTypeEnum.Flag;
            })()}
            isModalOpen={isFlagAndUnflagModalOpen}
            setIsModalOpen={setIsFlagAndUnflagModalOpen}
          />

          <Stack spacing={8} sx={{ width: '100%' }}>
            <Stack
              spacing={{ xs: 2, xl: 3 }}
              direction={{ xs: 'column', xl: 'row' }}
              justifyContent="space-between"
            >
              {/* task name, description, MilestoneBudgetComponent  */}
              <Stack
                spacing={{ xs: 2, xl: 3 }}
                width={{ xs: '100%', xl: '35%' }}
                direction={{ xs: 'row', xl: 'column' }}
                justifyContent="space-between"
              >
                {/* task name, description  */}
                <Stack spacing={2} width={{ xs: '50%', xl: '100%' }}>
                  {/* milestone name, cooperation with  */}
                  <Stack spacing={0.5}>
                    <Typography variant="h3" fontSize="2rem" fontWeight="500">
                      {taskData?.name}
                    </Typography>
                  </Stack>

                  <Typography>{taskData.description}</Typography>
                </Stack>

                <MilestoneBudgetComponent
                  detailScreenType={EndToEndDetailScreenTypeEnum.Task}
                  start={convertDate(taskData?.start) || '-'}
                  end={convertDate(taskData?.end) || '-'}
                  sx={{ width: { xs: '50%', xl: '100%' } }}
                />
              </Stack>

              <Stack
                direction={{ xs: 'row', xl: 'row' }}
                spacing={{ xs: 2, xl: 3 }}
                width={{ xs: '100%', xl: '65%' }}
              >
                <DashboardCard
                  sx={{ width: { xs: '50%', xl: '50%' } }}
                  renderComponent={<TaskEditCard taskData={taskData as TaskDto} />}
                />

                <DashboardCard
                  mainCardClassName="react-joyride-step-1-task"
                  headline="Status"
                  headlineSx={{ fontSize: '1rem', color: '#938F99', fontWeight: '500' }}
                  sx={{ width: { xs: '50%', xl: '50%' } }}
                  renderComponent={
                    <TaskStatusCard
                      taskData={taskData as TaskDto}
                      taskStatus={taskData.status}
                      isTaskFlagged={isUserBuyer ? taskData.buyerFlagged : taskData.sellerFlagged}
                      setIsModalOpen={setIsFlagAndUnflagModalOpen}
                    />
                  }
                  headlineEndIcon={
                    isTaskFlagged && (
                      <IconButton
                        disableRipple
                        color="primary"
                        component="span"
                        sx={{
                          '&.MuiIconButton-root': {
                            backgroundColor: red[50],
                            padding: 1.75,
                            width: '1rem',
                            height: '1rem',
                          },
                        }}
                      >
                        <AssistantPhotoOutlinedIcon sx={{ height: '1.1rem', color: red[700] }} />
                      </IconButton>
                    )
                  }
                  headlineEndElement={
                    <TaskDropdownChip task={taskData} fontSize="0.875rem !important" />
                  }
                />
              </Stack>
            </Stack>

            {/* subtasks heading, button & task data grid */}
            <Stack spacing={1} direction="column" sx={{ height: 'fit-content', width: '100%' }}>
              <Stack direction="row" sx={{ justifyContent: 'space-between', my: 'auto' }}>
                <Typography sx={{ fontWeight: 500, fontSize: '1.7em' }}>Subtasks</Typography>

                <SubtaskAddModal task={taskData} />
              </Stack>

              <SubtaskList task={taskData as TaskDto} />
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

export default TaskDetailScreen;
