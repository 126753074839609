import Stack from '@mui/material/Stack';
import { Box, SxProps, Theme } from '@mui/system';
import { IconButton } from '@mui/material';
import ConfirmImageSeller from '../../../assets/img/confirm-account-seller.png';
import ConfirmImageSellerXL from '../../../assets/img/confirm-account-sellerxl.png';
import ConfirmImageBuyer from '../../../assets/img/confirm-account-buyer.png';
import ConfirmImageBuyerXL from '../../../assets/img/confirm-account-buyerxl.png';
import { primaryDark } from '../../../color';
import { ArrowLeft } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

export interface ConfirmAccountLeftSideSectionProps {
  sx?: SxProps<Theme> | undefined;
}

const ConfirmAccountLeftSideSection = ({ sx }: ConfirmAccountLeftSideSectionProps) => {
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);
  const company = queryParams.get('company');

  return (
    <Stack
      sx={{
        position: 'relative',
        height: '100%',
        width: '100%',
        ...sx,
      }}
    >
      <Box
        component="img"
        sx={{
          height: '100%',
          objectFit: 'cover',
          content: {
            xs: `url(${company === 'seller' ? ConfirmImageSellerXL : ConfirmImageBuyerXL})`,
            lg: `url(${company === 'seller' ? ConfirmImageSeller : ConfirmImageBuyer})`,
          },
        }}
      />
    </Stack>
  );
};

export default ConfirmAccountLeftSideSection;
