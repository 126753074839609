import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import CaseStudyCard, {
  CaseStudyCardBase,
} from '../../../../components/case-study-card/CaseStudyCard';
import StarBadge from '../../../../components/star-badge/StarBadge';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import useGetLocalStorageUserData from '../../../../utils/hooks/useGetLocalStorageUserData';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';

const Portfolio = () => {
  const userData = useGetLocalStorageUserData();
  const companyId = userData?.membership?.company;
  const [showMore, setShowMore] = useState(false);

  const { reset, getValues } = useFormContext();

  const { data: caseStudiesContentData } = useGetMany<CaseStudyContentDto>({
    enabled: !!companyId,
    resource: `case-studies-content?company=${companyId}&status=published&status=under_review&status=verified`,
  });

  const caseStudiesContents = caseStudiesContentData?.data.results;

  const caseStudiesContentsSliced = showMore
    ? caseStudiesContents
    : caseStudiesContents?.slice(0, 3);

  return (
    <Stack spacing={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack spacing={3}>
          <Typography variant="heading4" color="primaryDark.500">
            Case study portfolio{' '}
            <Typography
              component="span"
              variant="heading4"
              color={getValues('caseStudies').length > 0 ? 'secondaryGreen.700' : 'primaryDark.500'}
            >
              {getValues('caseStudies').length} selected
            </Typography>
          </Typography>

          <Typography color="primaryDark.500">
            Attach case studies from your profile that show your expertise in similar projects or
            fields.
          </Typography>
        </Stack>

        <Typography
          variant="heading4"
          color="primaryLight.500"
          sx={{ '&:hover': { textDecoration: 'underline', cursor: 'pointer' } }}
          onClick={() => setShowMore(!showMore)}
        >
          {showMore ? 'View less' : 'View all'}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" sx={{ flexWrap: 'wrap', gap: '25px' }}>
        {caseStudiesContents &&
          caseStudiesContents.length > 0 &&
          caseStudiesContentsSliced?.map((caseStudyContent) => (
            <CaseStudyCard
              id={caseStudyContent.caseStudy}
              sx={{ width: { md: '46%', bg: '47%', xl: '30%' } }}
              toggleMode={{
                condition: getValues('caseStudies').some(
                  (rhfId: string) => rhfId === caseStudyContent.caseStudy,
                ),
              }}
              onClick={({ id, ...rest }: CaseStudyCardBase) => {
                const rhfCaseStudies = getValues('caseStudies');
                const rhfCaseStudiesReviews = getValues('caseStudiesPreview');

                // if clicked CaseStudyCard's id is already
                // in caseStudies rhf state
                // (if repeated, then remove that item)
                if (
                  rhfCaseStudies.length > 0 &&
                  rhfCaseStudies.some((rhfId: string) => rhfId === id)
                ) {
                  const filteredRhfCaseStudies = rhfCaseStudies.filter(
                    (rhfId: string) => rhfId !== id,
                  );

                  const filteredRhfCaseStudiesPreview = rhfCaseStudiesReviews.filter(
                    (rhfObj: CaseStudyCardBase) => rhfObj.id !== id,
                  );

                  reset({
                    ...getValues(),
                    caseStudies: [...filteredRhfCaseStudies],
                    caseStudiesPreview: [...filteredRhfCaseStudiesPreview],
                  });
                } else {
                  // if not repeated, append the id to the caseStudies rhf state
                  reset({
                    ...getValues(),
                    caseStudies: [...rhfCaseStudies, id],
                    caseStudiesPreview: [...rhfCaseStudiesReviews, { id, ...rest }],
                  });
                }
              }}
              topChip={<StatusChipField text={caseStudyContent.status} />}
              bottomComponent={
                caseStudyContent.review && (
                  <CustomReferenceField
                    displayName={'score'}
                    id={caseStudyContent.caseStudy}
                    resource="case-studies"
                    renderComponent={(data) => <StarBadge rating={data.score} />}
                  />
                )
              }
              coverImg={caseStudyContent.file}
              caseStudyName={caseStudyContent.title}
              companyLogo={caseStudyContent.seller.avatar}
              companyName={caseStudyContent.seller.name}
            />
          ))}
      </Stack>
    </Stack>
  );
};

export default Portfolio;
