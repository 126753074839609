import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as Check } from '../../../../assets/icons/check-icon.svg';

type Props = {
  title: string;
};

const CheckedItem = ({ title }: Props) => (
  <Stack direction="row" alignItems="center" gap={1.5}>
    <Box sx={{ width: 24, height: 24 }}>
      <Check />
    </Box>
    <Typography variant="publicBody4Regular" color="primaryDark.900">
      {title}
    </Typography>
  </Stack>
);

export default CheckedItem;
