import { Typography, Stack, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../components/button/CustomButton';
import DoItYourselfImage from '../../../../assets/img/public-pages/do-it-yourself.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import LAYOUT_DEFAULTS from '../../../../layout/utils';

const DoItYourself = () => {
  const navigate = useNavigate();

  return (
    // main row
    <Stack
      spacing={{ xs: 0, lg: 4 }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ ...LAYOUT_DEFAULTS, mb: 10 }}
    >
      {/* left column */}
      <Stack spacing={{ xs: 3, md: 6 }} sx={{ maxWidth: { xs: '100%', lg: '60%' } }}>
        <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
          Do it Yourself
        </Typography>

        <Stack spacing={2}>
          <Typography variant="publicBody2Regular" color="common.black">
            Post a project
          </Typography>

          <Typography variant="publicBody4Regular" color="common.black">
            If you want to handle the hiring process yourself, Spona provides you with a
            step-by-step project template to help you structure your requirements and easily reach
            out to your preferred audience of digital service sellers.
          </Typography>

          <Box
            src={DoItYourselfImage}
            component="img"
            sx={{ display: { xs: 'block', md: 'none' } }}
          />
        </Stack>

        <Stack spacing={2}>
          <Typography variant="publicBody2Regular" color="common.black">
            Choose a seller
          </Typography>

          <Typography variant="publicBody4Regular" color="common.black">
            Sellers will pitch for your project by filling out your project requirements. The next
            step is simple: pick your favorite candidates, chat with them over video or messaging on
            the Spona platform and proceed with one seller.
          </Typography>
        </Stack>

        <CustomButton
          onClick={() => navigate('./timed-projects')}
          sx={{ maxWidth: 'fit-content' }}
          endIcon={<ArrowRightIcon fill="#fff" />}
        >
          List a Project
        </CustomButton>
      </Stack>

      {/* right column */}
      <Box
        src={DoItYourselfImage}
        component="img"
        sx={{ display: { xs: 'none', md: 'block' }, mr: '-22px !important' }}
        alt="Illustration of the process for posting a project on Spona"
      />
    </Stack>
  );
};

export default DoItYourself;
