import {
  Autocomplete,
  Avatar,
  Box, createFilterOptions, Grid, InputAdornment, Stack, TextField, Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { v1 as uuidv1 } from 'uuid';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState } from 'react';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import useDynamicMultistepStore from '../../../../../components/multi-step/useDynamicMultistepStore';
import useCreateProjectStore from '../useCreateProjectStore';
import useGetMany from '../../../../../utils/hooks/crud-hooks/useGetMany';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';

interface CompanyOptionType {
  inputValue?: string;
  id: string;
  name: string;
  Profile: Partial<CompanyDto>;
}

const filter = createFilterOptions<CompanyOptionType>();

const StepFirst = () => {
  const [companies, setCompanies] = useState<any>([]);

  const createProjectInput = useCreateProjectStore((state) => state.createProjectInput);
  const setCreateProjectInput = useCreateProjectStore((state) => state.setCreateProjectInput);
  const setCanGoNext = useDynamicMultistepStore((state) => state.setCanGoNext);

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      title: createProjectInput.title ?? '',
      company: createProjectInput.company ?? '',
      companyUrl: createProjectInput.companyUrl ?? '',
      contactName: createProjectInput.contactName ?? '',
      jobTitle: createProjectInput.jobTitle ?? '',
      email: createProjectInput.email ?? '',
      number: createProjectInput.number ?? '',
    },
  });

  const {
    watch, control, formState: { isValid }, setValue, formState,
  } = formMethods;

  const { data } = useGetMany<CompanyDto>({ resource: '/companies', refetchOnMount: true });

  useEffect(() => {
    if (!data?.data.results) return;
    setCompanies(data?.data.results);
  }, [data]);

  useEffect(() => {
    setCanGoNext(isValid);
  }, [isValid, formState]);

  useEffect(() => {
    const subscription = watch(() => setCreateProjectInput(watch()));
    return () => subscription.unsubscribe();
  }, [watch()]);

  return (
    <Box component="form">
      <FormProvider {...formMethods}>
        <Stack spacing={5}>
          <Box>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>General</Typography>
                <Typography variant="body1">
                  Search for the company you want to work with or enter information for a new company.
                </Typography>
              </Box>

              <ControlledTextInput
                name="title"
                required
                fullWidth
                type="text"
                variant="outlined"
                placeholder="Project name"
              />
            </Stack>
          </Box>

          <Box>
            <Grid container columns={2} columnSpacing={{ xs: 2, lg: 4 }}>
              <Grid item xs={1}>
                <Controller
                  control={control}
                  name="company"
                  rules={{ required: 'This field is required' }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      id="autocomplete-create-project"
                      freeSolo
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        const isExisting = options.some((option) => inputValue === option.name);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            id: uuidv1(),
                            name: `Add "${inputValue}"`,
                            Profile: {
                              avatar: '',
                            },
                          });
                        }
                        return filtered;
                      }}
                      getOptionLabel={(option: any) => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option: any, { selected }) => (
                        <li {...props}>
                          <Stack spacing={3} direction="row" alignItems="center">
                            <Avatar
                              sx={{ width: 40, height: 40 }}
                              src={option?.avatar || undefined}
                            />
                            <Typography fontWeight="bold">
                              {option.name}
                            </Typography>
                          </Stack>
                        </li>
                      )}
                      onChange={(event, item) => {
                        if (item === null) {
                          onChange('');
                        } else if (item && item.inputValue) {
                          // Create a new value from the user input
                          // uncomment line below if we want to store id of a non existing company
                          // onChange(item.id);
                          onChange(item.inputValue);
                        } else {
                          companies.forEach((company: Record<string, string>) => {
                            if (company.name === item.name) {
                              onChange(company.name);
                              setValue('companyUrl', item.url, { shouldValidate: true });
                              // TODO: add other values for contact person when data available
                            }
                          });
                        }
                      }}
                      options={companies.length > 0 ? companies : []}
                      sx={{ background: '#fff' }}
                      renderInput={
                        (params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            type="text"
                            placeholder="Type company name"
                            InputLabelProps={{ shrink: false }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={1}>
                <ControlledTextInput
                  name="companyUrl"
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  placeholder="Company URL"
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <Stack spacing={5}>
              <Stack spacing={2}>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>Contact person</Typography>
                <Box>
                  <Grid container columns={2} columnSpacing={{ xs: 2, lg: 4 }}>
                    <Grid item xs={1}>
                      <ControlledTextInput
                        name="contactName"
                        required
                        fullWidth
                        type="text"
                        variant="outlined"
                        placeholder="Contact name"
                      />
                    </Grid>

                    <Grid item xs={1}>
                      <ControlledTextInput
                        name="jobTitle"
                        required
                        type="text"
                        variant="outlined"
                        placeholder="Job title"

                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container columns={2} columnSpacing={{ xs: 2, lg: 4 }}>
                    <Grid item xs={1}>
                      <ControlledTextInput
                        name="email"
                        required
                        fullWidth
                        type="email"
                        variant="outlined"
                        placeholder="Personal business email"

                      />
                    </Grid>

                    <Grid item xs={1}>
                      <ControlledTextInput
                        name="number"
                        required
                        fullWidth
                        type="text"
                        variant="outlined"
                        placeholder="Number (optional)"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Stack>
            </Stack>

          </Box>
        </Stack>
      </FormProvider>
    </Box>
  );
};

export default StepFirst;
