import {
  FormControl, FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectProps } from '@mui/material/Select/Select';
import { display } from '@mui/system';

type Props = {
  name: string;
  required?: boolean;
  choices: any;
  displayName: string;
  displayValue: string;
  label?: string;
} & SelectProps;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ControlledReferenceSelectInput = ({
  name,
  required,
  choices,
  displayName,
  displayValue,
  label = 'Select',
  ...props
}: Props) => {
  const { register, control, formState: { errors } } = useFormContext();

  return (
    <FormControl variant="standard">
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        {...register(
          name,
          required
            ? { required: 'This field is required' }
            : { required: false },
        )}
        displayEmpty
        value={useWatch({ control, name })}
        MenuProps={MenuProps}
        fullWidth
        {...props}
      >
        {choices?.map((choice: any, i: number) => (
          <MenuItem key={i} value={choice[displayValue]}>
            {choice[displayName]}
          </MenuItem>
        ))}
      </Select>
      {
        errors[name]
      && <FormHelperText error>{errors[name].message}</FormHelperText>
      }
    </FormControl>
  );
};

export default ControlledReferenceSelectInput;
