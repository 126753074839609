import {
  Avatar, Card, Stack, Typography,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import createProject_avatar_default from '../../../../assets/img/createProject_avatar_default.png';

export interface ProjectOverviewProps {
  createProjectInput: Record<string, any>
}

export function ProjectOverview({ createProjectInput }: ProjectOverviewProps) {
  return (
    <Card>
      <Stack sx={{ p: '2em' }} spacing={2}>
        <Stack direction="row">
          <Typography sx={{ fontSize: '1.5em', fontWeight: '600' }}>
            {createProjectInput?.title ?? 'Project name'}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Stack>
            <Avatar
              alt="Company logo"
              src={createProjectInput?.company?.files?.avatar?.url ?? createProject_avatar_default}
              sx={{ margin: '.5em', width: '4em', height: '4em' }}
            />
          </Stack>
          <Stack sx={{ my: 'auto' }}>
            <Typography sx={{ fontSize: '1.5em', fontWeight: '600' }}>
              {createProjectInput?.company ?? 'Company name'}
            </Typography>
            <Typography sx={{ fontSize: '.875em', fontWeight: '600', color: '#5C5F5C' }}>
              {createProjectInput?.companyUrl ?? 'Company url'}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row">
          <Typography sx={{ fontSize: '1.5em', fontWeight: '600' }}>
            Contact person
          </Typography>
        </Stack>
        <Stack direction="row">
          <Typography>
            {createProjectInput?.contactName ?? 'Contact name surname'}
          </Typography>
        </Stack>
        <Stack direction="row">
          <Typography>
            {createProjectInput.jobTitle ?? 'Contact job title'}
          </Typography>
        </Stack>
        <Stack direction="row">
          <PhoneIcon sx={{ mr: '.5em' }} />
          <Typography>
            {createProjectInput.number ?? 'Contact phone'}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
