import { Alert, Box, Snackbar, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRowId, GridValueGetterParams } from '@mui/x-data-grid';
import {
  EnumCurrency,
  EnumScreenList,
  EnumTimedProjectPitchStatus,
  EnumTimedProjectStatus,
} from 'tdc-web-backend/enums/enums';
import { BaseTimedProject } from 'tdc-web-backend/timed-projects/schemas';
import { useEffect, useState } from 'react';
import { TimedProjectPitchContentDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { TimedProjectContentDto } from 'tdc-web-backend/timed-project-contents/schemas';
import { useLocation, useNavigate } from 'react-router-dom';
import { DASHBOARD_ELEMENTS_SPACING } from '../../Dashboard/Main';
import CustomTable from '../../../components/custom-table/CustomTable';
import PitchesImage from '../../../assets/img/pitches/pitches.svg';
import { ReactComponent as ChatIcon } from '../../../assets/icons/chat.svg';
import CustomButton from '../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/layout-icons/ArrowRight.svg';
import { ReactComponent as ContractDocumentIcon } from '../../../assets/icons/project-icons/ContractDocumentIcon.svg';
import CustomList, { ColumnDefs } from '../../../components/custom-list/list/CustomList';
import useGetMany from '../../../utils/hooks/crud-hooks/useGetMany';
import PitchDetailsDrawer from './PitchDetailsDrawer';
import TimedProjectDrawer from '../../../components/timed-project-drawer/TimedProjectDrawer';
import { primaryLight, secondaryBlue } from '../../../color';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { convertDate, formatCurrency, truncate } from '../../../utils/helpers';
import useReferenceOne from '../../../utils/hooks/crud-hooks/useReferenceOne';
import { ExtendedTimedProjectPitchContentDto } from '../pitch.interface';
import IconWithToolTip from '../../../components/icon-with-tooltip/IconWithToolTip';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';
import DashboardCard from '../../../components/dashboard-card/EndToEndCard.tsx/DashboardCard';
import BannerNotificationMapper from '../../../components/notifications/bannerNotifications/BannerNotificationMapper';

const pitchesPanelConfig = [
  {
    id: 'pitches-all',
    title: 'All',
  },
  {
    id: 'pitches_1st-round',
    title: '1st round',
    search: { status: EnumTimedProjectPitchStatus.FirstRound },
  },
  {
    id: 'pitches_2st-round',
    title: '2nd round',
    search: { status: EnumTimedProjectPitchStatus.SecondRound },
  },
  {
    // FIXME: should the title here be chosen or hired?
    id: 'pitches_hired',
    title: 'Hired',
    search: { status: EnumTimedProjectPitchStatus.Chosen },
  },
  {
    id: 'pitches_rejected',
    title: 'Rejected',
    search: { status: EnumTimedProjectPitchStatus.FirstRoundRejected },
  },
  {
    id: 'pitches_pending',
    title: 'Pending',
    search: { status: EnumTimedProjectPitchStatus.Pending },
  },
  {
    id: 'pitches_draft',
    title: 'Draft',
    search: { status: EnumTimedProjectPitchStatus.Draft },
  },
];

const PitchDetailScreen = () => {
  //
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useGetLocalStorageUserData();

  const [isPitchDetailsDrawerOpen, setIsPitchDetailsDrawerOpen] = useState(!!location?.state);
  const [clickedPitchContentId, setClickedPitchContentId] = useState<string | null>(
    (location?.state as string) ?? null,
  );
  const [clickedPitchEditId, setClickedPitchEditId] = useState<string | null>(null);

  //
  const [isPitchSubmitDrawerOpen, setIsPitchSubmitDrawerOpen] = useState(false);
  const [clickedTimedProjectContentId, setClickedTimedProjectContentId] = useState<string | null>(
    null,
  );

  // serves the purpose of selecting/deselecting a clicked row in the data grid
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const timedProjectsColumns: ColumnDefs<BaseTimedProject>[] = [
    {
      field: 'name',
      icon: <ContractDocumentIcon fill={secondaryBlue[500]} />,
      columnsStyle: {
        flexGrow: 1,
        flexShrink: 1,
        fontWeight: 600,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        minWidth: 0,
      },
    },
    {
      field: 'budget',
      align: 'start',
      columnsStyle: {
        alignSelf: 'flex-start',
        textAlign: 'right',
        fontWeight: 300,
        variant: 'body2',
        color: 'primaryDark.500',
        width: 'auto',
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'auto',
      },
      valueFormatter: (params) =>
        formatCurrency(params.budget as number, params.currency as EnumCurrency),
    },
  ];

  const pitchesColumns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Stack spacing={2} direction="row" alignItems="center">
          <StatusChipField text={params.value} />

          {params.row.edit !== null && (
            <IconWithToolTip
              toolTipText="The pitch has been edited.
                  It needs to be approved by Spona
                  for the changes to be applied."
            />
          )}
        </Stack>
      ),
    },
    {
      field: 'projectName',
      headerName: 'Project name',
      flex: 1.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography fontWeight="600" color="primaryDark.600">
          {params.value}
        </Typography>
      ),
    },
    {
      // date when it got pitched
      field: 'published',
      headerName: 'Pitched',
      flex: 1,
      renderCell: (params: GridValueGetterParams) => (
        <Typography>{convertDate(params.value)}</Typography>
      ),
    },
    // {
    //   field: 'chat',
    //   headerName: '',
    //   disableColumnMenu: true,
    //   sortable: false,
    //   flex: 1,
    //   align: 'right',
    //   renderCell: (params: GridValueGetterParams) => {
    //     if (
    //       params.row.status === EnumTimedProjectPitchStatus.SecondRound ||
    //       params.row.status === EnumTimedProjectPitchStatus.Hired
    //     ) {
    //       return (
    //         <CustomButton
    //           onClick={(e) => {
    //             e.stopPropagation();
    //             // TODO: implement chat functionality once it gets done
    //             setShowSnackbar(true);
    //           }}
    //           endIcon={<ChatIcon fill={primaryLight[500]} />}
    //           variant="primaryText"
    //         >
    //           CHAT
    //         </CustomButton>
    //       );
    //     }

    //     return null;
    //   },
    // },
  ];

  // timed projects
  const { data: timedProjects } = useGetMany<BaseTimedProject>({
    resource: 'timed-projects',
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const field = 'name';
  const theme = useTheme();

  const [truncLength, setTruncLength] = useState(15);

  useEffect(() => {
    const updateTruncLength = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= theme.breakpoints.values.md) {
        setTruncLength(7);
      } else if (screenWidth <= theme.breakpoints.values.lg) {
        setTruncLength(11);
      } else if (screenWidth <= theme.breakpoints.values.xl) {
        setTruncLength(17);
      } else {
        // Adjust this formula as needed to fit your content and design
        const calculatedLength = Math.max(31, Math.floor(screenWidth / 50));
        setTruncLength(calculatedLength);
      }
    };

    updateTruncLength();
    window.addEventListener('resize', updateTruncLength);

    return () => window.removeEventListener('resize', updateTruncLength);
  }, []);

  const timedProjectsData = timedProjects?.data.results
    .filter((timedProject) =>
      [
        EnumTimedProjectStatus.Active,
        EnumTimedProjectStatus.Canceled,
        EnumTimedProjectStatus.Closed,
      ].includes(timedProject.status),
    )
    .map((timedProject) => ({
      ...timedProject,
      [field]: truncate(timedProject[field], truncLength),
    }));

  // pitch content
  const { data: pitchContentData } = useReferenceOne<TimedProjectPitchContentDto>({
    enabled: !!clickedPitchContentId,
    id: clickedPitchContentId as string,
    resource: 'timed-project-pitch-contents',
  });

  // pitch edit content
  const { data: pitchEditContentData } = useReferenceOne<TimedProjectPitchContentDto>({
    enabled: !!clickedPitchEditId,
    id: clickedPitchEditId as string,
    resource: 'timed-project-pitch-contents',
  });

  // timed project content
  const { data: timedProjectContentData } = useReferenceOne<TimedProjectContentDto>({
    enabled: !!clickedTimedProjectContentId,
    id: clickedTimedProjectContentId as string,
    resource: 'timed-project-contents',
  });

  // deselect row if drawer is closed
  useEffect(() => {
    if (isPitchDetailsDrawerOpen === false) {
      setSelectionModel([]);
    }
  }, [isPitchDetailsDrawerOpen]);

  return (
    <>
      {/* main column */}
      <Stack spacing={4}>
        <BannerNotificationMapper screen={EnumScreenList.PitchScreen} />
        {/* first row */}
        <Stack spacing={DASHBOARD_ELEMENTS_SPACING} direction="row" height="292px">
          <DashboardCard
            sx={{
              width: { sm: '100%', lg: '49%' },
              p: 0,
              pt: 0,
              pb: 4,
            }}
            renderComponentSx={{ m: '0 !important', mt: '0 important' }}
            renderComponent={
              <Stack>
                <Box
                  component="img"
                  src={PitchesImage}
                  sx={{
                    objectFit: 'cover',
                    height: 130,
                    width: '100%',
                  }}
                />

                <Typography
                  color="primaryDark.600"
                  alignSelf="center"
                  textAlign="center"
                  sx={{ px: 4, fontSize: '14px', py: { sm: 2, lg: 2, xl: 3 } }}
                >
                  Client-reviewed case studies are the first step to winning new projects on Spona.
                </Typography>

                <CustomButton
                  endIcon={<ArrowRightIcon fill="#fff" style={{ width: 16, height: 16 }} />}
                  sx={{ width: '25%', alignSelf: 'center' }}
                  onClick={() => navigate('/dashboard/case-studies/')}
                >
                  <Typography variant="heading4">get started</Typography>
                </CustomButton>
              </Stack>
            }
          />

          <DashboardCard
            headline="Open projects"
            sx={{ width: { sm: '100%', lg: '49%' }, pt: 2, pb: 1.5, p: 1 }}
            renderComponentSx={{ height: '225px', width: '100%' }}
            renderComponent={
              // list containing timed projects
              <CustomList<BaseTimedProject>
                sx={{ overflowY: 'auto' }}
                items={timedProjectsData || []}
                columns={timedProjectsColumns}
                onClick={(data) => {
                  setClickedTimedProjectContentId(data.content);
                  setIsPitchSubmitDrawerOpen(true);
                }}
              />
            }
          />
        </Stack>

        {/* data grid containing pitches */}
        <CustomTable
          resource="timed-project-pitches"
          searchParams={{
            company: currentUser?.membership?.company,
          }}
          columns={pitchesColumns}
          panelConfig={pitchesPanelConfig}
          onSelectionModelChange={(selectionModel) => setSelectionModel(selectionModel)}
          selectionModel={selectionModel}
          visualStyle="secondary"
          onRowClick={(data) => {
            // in the drawer always show the content's data
            setClickedPitchContentId(data.row.content);

            if (data.row.edit !== null) {
              setClickedPitchEditId(data.row.edit);
            } else {
              setClickedPitchEditId(null);
            }

            setIsPitchDetailsDrawerOpen(true);
          }}
          hideFooter
          sx={{
            minHeight: '16rem',
          }}
        />

        {/* actual pitch drawer */}
        <PitchDetailsDrawer
          pitchContent={
            // if there is edit, override pitchContentData's status
            // because buttons should reflect edit content's status
            pitchEditContentData !== undefined
              ? ({
                  ...pitchContentData,
                  editStatus: pitchEditContentData.status,
                } as ExtendedTimedProjectPitchContentDto)
              : (pitchContentData as ExtendedTimedProjectPitchContentDto)
          }
          isDrawerOpen={isPitchDetailsDrawerOpen}
          setIsDrawerOpen={setIsPitchDetailsDrawerOpen}
        />

        {/* timed project drawer */}
        <TimedProjectDrawer
          timedProject={timedProjectContentData}
          open={isPitchSubmitDrawerOpen}
          onClose={() => setIsPitchSubmitDrawerOpen(false)}
          withButtons={timedProjectContentData?.timedProject}
        />
      </Stack>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="info" sx={{ width: '100%' }}>
          Feature not yet implemented.
        </Alert>
      </Snackbar>
    </>
  );
};

export default PitchDetailScreen;
