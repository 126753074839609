/* eslint-disable no-use-before-define */
import { ClickAwayListener, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { ContractDto } from 'tdc-web-backend/contracts/schemas';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { APPBAR_HEIGHT } from '../../../../layout/dashboard-navigation/NavigationDashboard';
import useDetermineCompanyRoles from '../../../../utils/hooks/useDetermineCompanyRoles';
import StatusChipField from '../../../../components/StatusChipField/StatusChipField';
import { ReactComponent as BoxArrowUpRightLeft } from '../../../../assets/icons/project-icons/BoxArrowUpRightLeft.svg';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import { primaryDark, primaryLight, secondaryBlue } from '../../../../color';
import { ReactComponent as FileDownloadIcon } from '../../../../assets/icons/project-icons/FileDownloadIcon.svg';
import { ReactComponent as CalendarCheckIcon } from '../../../../assets/icons/project-icons/CalendarCheckIcon.svg';
import { ReactComponent as ShortArrowRightIcon } from '../../../../assets/icons/project-icons/ShortArrowRightIcon.svg';
import { ReactComponent as ContractDocumentIcon } from '../../../../assets/icons/project-icons/ContractDocumentIcon.svg';
import { ReactComponent as PenIcon } from '../../../../assets/icons/project-icons/PenIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/layout-icons/CalendarIcon.svg';
import { convertDate } from '../../../../utils/helpers';
import CustomTable from '../../../../components/custom-table/CustomTable';
import CancelContractModal from '../modals/CancelContractModal';

type ContractDetailsDrawerProps = {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setActiveCard: Dispatch<SetStateAction<string>>;
  item: ContractDto;
};

const ContractDetailsDrawer = ({
  open,
  setIsOpen,
  setActiveCard,
  item,
}: ContractDetailsDrawerProps) => {
  const isBuyer = useDetermineCompanyRoles()?.includes(CompanyRole.Buyer);
  const navigate = useNavigate();
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setIsOpen(false);
        setActiveCard('');
      }}
      hideBackdrop
      elevation={4}
      PaperProps={{
        sx: {
          mt: `${APPBAR_HEIGHT}px`,
        },
      }}
    >
      <ClickAwayListener
        onClickAway={(e) => {
          const target = e.target as Element;

          // setIsOpen(false) only if you click on elements that have
          // those classes; or in other words if you click outside the
          // joyride tooltip (modal)
          if (
            target.classList.contains('react-joyride__overlay') ||
            target.classList.contains('MuiModal-root')
          ) {
            setIsOpen(false);
            setActiveCard('');
          }
        }}
      >
        <Stack width={{ md: '50vw', lg: '40vw' }} p={2} pt={3}>
          <Stack direction="row" justifyContent="space-between">
            <StatusChipField text={item.status} />
            <Stack direction="row" spacing={1.5}>
              <IconButton
                sx={{ p: 0 }}
                disableRipple
                onClick={() =>
                  navigate(`../project/${item.project}/contract/${item.latestContract}`)
                }
                className="react-joyride-step-2-contract-drawer"
              >
                <PenIcon fill={primaryLight[500]} />
              </IconButton>
              <CancelContractModal data={item as ContractDto} />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
            <CustomReferenceField
              resource="projects"
              id={item.project}
              displayName="name"
              color="primaryDark.600"
              variant="heading2"
            />
            <IconButton
              sx={{
                borderRadius: '3px',
                p: 0.6,
                bgcolor: secondaryBlue[100],
              }}
              href={`../end-to-end/project/${item.project}`}
              target="_blank"
              className="react-joyride-step-1-contract-drawer"
            >
              <BoxArrowUpRightLeft fill={secondaryBlue[700]} />
            </IconButton>
          </Stack>
          <Stack direction="row" justifyContent="start" alignItems="start" spacing={10} mt={5}>
            <Stack spacing={1}>
              <Typography variant="heading5" color="primaryDark.500">
                Cooperation with
              </Typography>
              {isBuyer ? (
                <Typography color="primaryDark.600" variant="body2">
                  {item.body.seller.companyName}
                </Typography>
              ) : (
                <Typography color="primaryDark.600" variant="body2">
                  {item.body.buyer.companyName}
                </Typography>
              )}
            </Stack>
            <Stack alignItems="start">
              <Typography variant="heading5" color="primaryDark.500">
                Contract
              </Typography>
              <IconButton
                disabled={!item?.document}
                color="secondary"
                href={item.document}
                target="_blank"
                className="react-joyride-step-4-contract-drawer"
              >
                <FileDownloadIcon fill={!item?.document ? primaryDark[400] : primaryDark[600]} />
              </IconButton>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="start" alignItems="start" spacing={2} mt={5}>
            <Stack spacing={1}>
              <Typography variant="heading5" color="primaryDark.500">
                Created
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CalendarIcon fill={primaryDark[500]} />
                <Typography color="primaryDark.600" variant="body3" pr={1}>
                  {convertDate(item.created)}
                </Typography>
                <ShortArrowRightIcon fill={primaryLight[500]} />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="heading5" color="primaryDark.500">
                Last signed
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <CalendarCheckIcon fill={primaryDark[500]} />
                <Typography color="primaryDark.600" variant="body3">
                  {convertDate(item.signed)}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Typography
            className="react-joyride-step-5-contract-drawer"
            mt={5}
            variant="heading5"
            color="primaryDark.500"
          >
            Annexes
          </Typography>
          <CustomTable
            columns={columns}
            resource="contracts"
            disableTabView
            searchParams={{ project: item.project, limit: 999, type: 'annex' }}
            hideFooter
            headerHeight={0}
            disableSelectionOnClick
            sx={{
              '.MuiDataGrid-row': {
                borderBottom: '1px solid',
                borderColor: 'secondaryBlue.100',
              },
              minHeight: '16rem',
            }}
          />
        </Stack>
      </ClickAwayListener>
    </Drawer>
  );
};
export default ContractDetailsDrawer;

const columns: GridColDef[] = [
  {
    field: 'status',
    flex: 0.2,
    renderCell: (params: GridValueGetterParams) => <StatusChipField text={params.value} />,
  },
  {
    field: 'name',
    flex: 0.2,
    renderCell: (params: GridValueGetterParams) => (
      <Typography variant="heading5">{params.value}</Typography>
    ),
  },
  {
    field: '',
    align: 'left',
    headerAlign: 'left',
    flex: 0.4,
    renderCell: (params: GridValueGetterParams) => (
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="heading5">{convertDate(params.row.created)}</Typography>
        <ShortArrowRightIcon fill={primaryLight[500]} />
        <Typography variant="heading5">{convertDate(params.row.signed)}</Typography>
      </Stack>
    ),
  },
  {
    field: 'document',
    flex: 0.01,
    renderCell: (params: GridValueGetterParams) =>
      params.value && (
        <IconButton href={params.value} target="_blank">
          <ContractDocumentIcon fill={secondaryBlue[500]} />
        </IconButton>
      ),
  },
];
