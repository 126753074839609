import { FunctionComponent } from 'react';
import { SxProps, Theme } from '@mui/system';
import SignContractsSellerImage from '../../../assets/img/public-pages/sign-contracts-seller.svg';
import ManageMilestonesAndTasksSellerImage from '../../../assets/img/public-pages/manage-milestones-and-tasks-seller.svg';
import ProjectReportsSellerImage from '../../../assets/img/public-pages/project-reports-seller.svg';
import TrackAndProcessPaymentsSellerImage from '../../../assets/img/public-pages/track-and-process-payments-seller.svg';
import KeepInTouchSellerImage from '../../../assets/img/public-pages/keep-in-touch-seller.svg';
import SignContractsBuyerImage from '../../../assets/img/public-pages/sign-contracts-buyer.svg';
import ManageMilestonesAndTasksBuyerImage from '../../../assets/img/public-pages/manage-milestones-and-tasks-buyer.svg';
import ProjectReportsSellerBuyerImage from '../../../assets/img/public-pages/project-reports-buyer.svg';
import TrackAndProcessPaymentsBuyerImage from '../../../assets/img/public-pages/track-and-process-payments-buyer.svg';
import KeepInTouchSellerBuyerImage from '../../../assets/img/public-pages/keep-in-touch-buyer.svg';
import { ReactComponent as CentralizeIcon } from '../../../assets/img/public-pages/centralize.svg';
import { ReactComponent as StandardizeIcon } from '../../../assets/img/public-pages/standardize.svg';
import { ReactComponent as SafeguardIcon } from '../../../assets/img/public-pages/safeguard.svg';
import { ReactComponent as OptimizeIcon } from '../../../assets/img/public-pages/optimize.svg';
import { secondaryBlue, secondaryOrange } from '../../../color';

export interface TextAndImageProps {
  title: string;
  content: string;
  image: string;
  contentButton?: {
    text: string;
    color?: string;
    withArrowRightIcon?: boolean;
    to: string;
  };
  sx?: SxProps<Theme>;
  contentOrder?: 'textThenImage' | 'imageThenText';
  contentStyles?: SxProps<Theme>;
  contentSpacing?: Record<string, number>;
  alt?: string;
}

export type TextAndIcon = {
  title: string;
  content: string;
  Icon: FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  iconColor: string;
};

export const textsAndImagesSeller: TextAndImageProps[] = [
  {
    title: 'Sign Contracts in Real Time',
    content:
      'Spona offers prebuilt digital contract fields so you can quickly finalize the negotiation phase to get a stress-free start on the project. Get full transparency between you and the buyer, sign your contract digitally, edit it at any time, and more.',
    image: SignContractsSellerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryOrange[601],
      to: 'digital-contracts',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Track Your Milestones and Tasks',
    content:
      'Simplify communication with your buyer by tracking and updating your tasks and milestones. Determine and resolve issues before they escalate with our flagging and approval system.',
    image: ManageMilestonesAndTasksSellerImage,
    contentOrder: 'imageThenText',
    contentButton: {
      text: 'Learn more',
      color: secondaryOrange[601],
      to: 'milestones-and-tasks',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Get Project Reports',
    content:
      'Make data-driven estimates on your future schedule and progress. Keeping all parties updated has never been easier thanks to Spona’s project health overviews.',
    image: ProjectReportsSellerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryOrange[601],
      to: 'monitor-your-kpis',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Track & Process Payments',
    content:
      'Get scheduled payments determined in your digital contract for milestones containing a precise amount of completed tasks. Keep track of your invoices and incoming revenue by checking the payment data on your dashboard.',
    image: TrackAndProcessPaymentsSellerImage,
    contentOrder: 'imageThenText',
    contentButton: {
      text: 'Learn more',
      color: secondaryOrange[601],
      to: 'payment-processing',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Keep in Touch',
    content:
      'Stay perfectly in sync with your buyers with updates and notifications from project activities. Contact them at any time through our integrated messaging tool.',
    image: KeepInTouchSellerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryOrange[601],
      to: 'communication',
      withArrowRightIcon: true,
    },
  },
];

export const theFutureOfSmartManagingTextAndIcons = (iconColor: string): TextAndIcon[] => [
  {
    title: 'Centralize your projects and sellers.',
    content:
      'Keep all your contracts and projects in one place, simplifying management, problem solving, and insight gathering.',
    Icon: CentralizeIcon,
    iconColor,
  },
  {
    title: 'Standardize your work.',
    content:
      'Across single and multiple projects, keep all facets of your work uniform and easy to understand through our streamlined services.',
    Icon: StandardizeIcon,
    iconColor,
  },
  {
    title: 'Safeguard your data.',
    content:
      'No matter which external or internal changes occur, your data will always be safely stored and accessible on your dashboard.',
    Icon: SafeguardIcon,
    iconColor,
  },
  {
    title: 'Optimize your management.',
    content:
      'Less excel sheets, emails and calls, plus streamlined payments and contracts means less overhead on projects.',
    Icon: OptimizeIcon,
    iconColor,
  },
];

export const textsAndImagesBuyer: TextAndImageProps[] = [
  {
    title: 'Sign Contracts in Real Time',
    content:
      'Spona offers prebuilt digital contract fields so you can start working on your project in a matter of days. Get full transparency between you and your digital service seller, sign your contract digitally, edit it at any time, and more.',
    image: SignContractsBuyerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryBlue[601],
      to: 'digital-contracts',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Track Your Milestones and Tasks',
    content:
      'Get full control of your project operations with milestones and tasks, from the big picture to all day-to-day activities. Spona helps you pinpoint and resolve issues before they escalate.',
    image: ManageMilestonesAndTasksBuyerImage,
    contentOrder: 'imageThenText',
    contentButton: {
      text: 'Learn more',
      color: secondaryBlue[601],
      to: 'milestones-and-tasks',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Get Project Reports',
    content:
      'Get immediate feedback on your seller’s performance. Access consolidated, fully digital data on all your projects, from budget, schedule and quality to scope.',
    image: ProjectReportsSellerBuyerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryBlue[601],
      to: 'monitor-your-kpis',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Track & Process Payments',
    content:
      'Allocate your payments in your digital contract around the scope of your milestones. Get valuable insights on optimal cost allocation and maximize your investments with payment reports.',
    image: TrackAndProcessPaymentsBuyerImage,
    contentOrder: 'imageThenText',
    contentButton: {
      text: 'Learn more',
      color: secondaryBlue[601],
      to: 'payment-processing',
      withArrowRightIcon: true,
    },
  },
  {
    title: 'Keep in Touch',
    content:
      'All of your digital service sellers are available for contact on your platform. Get daily updates and notifications and keep your finger on the pulse of your project.',
    image: KeepInTouchSellerBuyerImage,
    contentOrder: 'textThenImage',
    contentButton: {
      text: 'Learn more',
      color: secondaryBlue[601],
      to: 'communication',
      withArrowRightIcon: true,
    },
  },
];
