import { Divider, Stack, Typography, useMediaQuery } from '@mui/material';
import PricingCards from '../components/PricingCard';
import LogoSection from '../components/LogoSection';
import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { primaryLight } from '../../../../color';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import AccordionGroups, {
  MyAccordionProps,
} from '../../../../components/public-screen-components/accordion-groups/AccordionGroups';
import StandardPlanManage from '../components/StandardPlan';
import EnterprisePlanManage from '../components/EnterprisePlan';
import theme from '../../../../theme';

export const manageBuyerArray: MyAccordionProps[] = [
  {
    summary: 'Is there a limit to the number of projects we can manage through Spona?',
    details:
      'No, there are no limits to projects, milestones, or tasks that can be managed through Spona.',
  },
  {
    summary: 'What happens when my trial expires?',
    details:
      'Your account will be downgraded to the free version until you decide whether to continue to a paid subscription or not. A Spona representative will be able to talk you through your options.',
  },
  {
    summary: 'Which payment options do you accept?',
    details:
      'Get in touch with a Spona representative to discuss your subscription options. You can pay for monthly or annual subscriptions by credit card or bank transfer.',
  },
];

const BuyerManageView = () => {
  const isLessThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
        <Stack
          direction={{ lg: 'row' }}
          alignItems={{ xs: 'center', lg: 'stretch' }}
          width={{ lg: '65%' }}
          minHeight="100%"
          my={8}
        >
          <PricingCards purple sx={{ ml: '0px', width: '90%' }}>
            <StandardPlanManage />
          </PricingCards>
          <PricingCards sx={{ ml: { lg: '-3rem' }, width: '90%' }}>
            <EnterprisePlanManage />
          </PricingCards>
        </Stack>
        <LogoSection />
        <Stack justifyContent="space-between" direction={{ lg: 'row' }} gap={10} mt={10}>
          <Stack width="100%" gap={3}>
            <Typography variant="publicHeading3" color="primaryDark.900">
              Frequently asked questions
            </Typography>
            <Typography variant="publicBody4Regular" color="primaryDark.700" width="90%">
              Got more questions? We're here to answer them! Drop us a message any time.
            </Typography>
            <CustomButton
              variant="primaryText"
              endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
              href="/contact-us"
            >
              Contact us
            </CustomButton>
          </Stack>
          <AccordionGroups group={manageBuyerArray} />
        </Stack>
      </Stack>
      <Stack
        justifyContent="space-between"
        width="100%"
        height="100%"
        px={2}
        p={{ lg: 10 }}
        py={10}
        direction={{ lg: 'row' }}
        gap={10}
        mt={10}
        bgcolor="primaryLight.50"
      >
        <Stack width="100%" gap={3} justifyContent={{ lg: 'center' }} alignItems={{ lg: 'center' }}>
          <Typography
            variant="publicHeading3"
            color="primaryDark.900"
            sx={{
              textAlign: { lg: 'center' },
            }}
          >
            Get a demo
          </Typography>
          <Typography
            variant="publicBody4Regular"
            color="primaryDark.700"
            sx={{
              textAlign: { lg: 'center' },
            }}
          >
            Not sure which plan is right for you? Let us talk you through the options
          </Typography>
          <CustomButton
            href={process.env.REACT_APP_CALENDLY_URL as string}
            sx={{ width: 'fit-content' }}
          >
            Book your demo
          </CustomButton>
        </Stack>
        {isLessThanLarge ? (
          <Divider flexItem>Or</Divider>
        ) : (
          <Divider orientation="vertical" flexItem>
            Or
          </Divider>
        )}
        <Stack
          width="100%"
          gap={3}
          justifyContent="center"
          alignItems={{ lg: 'center' }}
          sx={{
            textAlign: { lg: 'center' },
          }}
        >
          <Typography
            variant="publicHeading3"
            color="primaryDark.900"
            sx={{
              textAlign: { lg: 'center' },
            }}
          >
            Start a free trial
          </Typography>
          <Typography
            variant="publicBody4Regular"
            color="primaryDark.700"
            sx={{
              textAlign: { lg: 'center' },
            }}
          >
            We offer a 30-day free trial to all companies interested in using our End-to-End Project
            Management tool
          </Typography>
          <CustomButton
            variant="primaryLight"
            href={process.env.REACT_APP_CALENDLY_URL as string}
            sx={{ width: 'fit-content' }}
          >
            Try for free
          </CustomButton>
        </Stack>
      </Stack>
    </>
  );
};

export default BuyerManageView;
