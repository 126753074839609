export interface NavPage {
  id: string;
  title: string;
  links: { id: string; title: string; to: string }[];
}

export const pages: NavPage[] = [
  {
    id: '200',
    title: 'For Buyers',
    links: [
      { id: '201', title: 'Hire a seller', to: '/how-to-hire' },
      { id: '202', title: 'Manage your project', to: '/how-to-manage-buyer' },
    ],
  },
  {
    id: '400',
    title: 'For Sellers',
    links: [
      { id: '401', title: 'Find work', to: '/how-to-find-work' },
      { id: '402', title: 'Manage your project', to: '/how-to-manage-seller' },
      { id: '403', title: 'Seller archive', to: '/seller-archive' },
      { id: '404', title: 'Case study archive', to: '/case-study-archive' },
    ],
  },
  {
    id: '500',
    title: 'About Spona',
    links: [
      { id: '503', title: 'About us', to: '/about-us' },
      { id: '501', title: 'Spona Media', to: '/spona-media' },
      { id: '502', title: 'Work with us', to: '/work-with-us' },
    ],
  },
];
