import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import laptop from '../../../assets/img/public-pages/laptop.png';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/project-icons/ArrowRightIcon.svg';
import CustomButton from '../../../components/button/CustomButton';
import { primaryLight } from '../../../color';
import LAYOUT_DEFAULTS from '../../../layout/utils';

const EvolveYourBusiness = () => {
  const navigate = useNavigate();

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        bgcolor: 'primaryLight.100',
        px: { xs: 4, lg: 7 },
        py: { xs: 9, xl: 7 },
        pb: { xs: 22, sm: 6 },
        my: 8,
        borderRadius: '20px',
        width: { xs: 'fit-content', lg: '80%', xl: 'auto' },
        overflow: { xs: 'hidden', lg: 'visible' },
        mt: { xs: '60px', md: '-20px' },
        ...LAYOUT_DEFAULTS,
      }}
    >
      {/* Evolve Your Business text & description text & button */}
      <Stack spacing="36px" sx={{ maxWidth: { xs: '100%', lg: '55%' } }}>
        <Typography
          color="common.black"
          whiteSpace="pre-line"
          sx={{
            typography: { xs: 'publicHeading4', lg: 'publicHeading3' },
            maxWidth: { xs: '100%', lg: '65%' },
          }}
        >
          Evolve Your Business
        </Typography>

        <Typography
          color="common.black"
          variant="publicBody3Light"
          sx={{
            maxWidth: { xs: '100%', sm: '50%', lg: '90%' },
            zIndex: 10,
          }}
        >
          End-to-End Project Management is the next step in providing an all-encompassing, efficient
          project management solution. Sign digital contracts, create project milestones and
          allocate your budgets, get project reports and chat with your sellers, all within a single
          tool - find out how simple and stress-free project management can be.
        </Typography>

        <CustomButton
          onClick={() => navigate('/how-to-manage-buyer')}
          variant="primaryText"
          endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
          // agreed with Arneo and Kat to increase this font size to 16px
          sx={{ fontSize: '1rem' }}
        >
          explore e2e
        </CustomButton>

        <Box
          alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
          component="img"
          src={laptop}
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: { xs: 'absolute' },
            bottom: 20,
            right: 0,
            width: { xs: '90%', lg: 'auto' },
          }}
        />
      </Stack>

      <Box
        alt="Mock-up of a laptop showing a screenshot of Spona’s tool "
        component="img"
        src={laptop}
        sx={{
          display: { xs: 'none', sm: 'block' },
          position: { sm: 'absolute', xl: 'relative' },
          right: { xs: 10, bg: 0, lg: -110, xxl: -150 },
          width: '50%',
        }}
      />
    </Stack>
  );
};

export default EvolveYourBusiness;
