import { Stack } from '@mui/material';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import DoItYourself from './components/DoItYourself';
import HowToHireHero from './components/HowToHireHero';
import LetSponaHandleIt from './components/LetSponaHandleIt';
import MetaTags from '../../../components/seo/MetaTags';
import CallToAction from './components/CallToAction';

const metaTags = {
  description: `See how to use Spona to find great service sellers and get matched up with the right ones.`,
};

const HowToHire = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <HowToHireHero />

      <LetSponaHandleIt />

      <DoItYourself />

      <CallToAction
        firstTitle="Let's Plan Together"
        firstSubtitle="Not sure which plan is right for you? Let us talk you through the options"
        secondTitle="Get Started"
        secondSubtitle="Become a part of Spona to get started on your next big project"
        bgcolor="secondaryBlue.101"
      />
    </Stack>
  </>
);
export default HowToHire;
