import { Stack, Typography } from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { secondaryOrange } from '../../../../color';
import ReusableHeroSection from '../../../../components/public-screen-components/ReusableHeroSection';
import TextAndImage from '../../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from '../components/HowMuchDoesItCost';
import CommunicationImage from '../../../../assets/img/public-pages/communication.png';
import BecomeAPartOfSpona from '../../../../components/public-screen-components/BecomeAPartOfSpona';
import { textsAndImagesCommunication } from './utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../../components/seo/MetaTags';

const metaTags = {
  description: `Discover the easy way to stay perfectly in sync with your clients throughout the life of all projects.`,
};

const Communication = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <ReusableHeroSection
        title="Communication"
        content="Staying in touch with buyers is key to avoiding a variety of miscommunication-based issues. Spona’s End-to-End Project Management facilitates transparency with a chatting tool that lets you connect with your contacts, share files, start video calls, and more."
        image={CommunicationImage}
        sx={{ height: { xs: `calc(64vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        titleSx={{ fontSize: { xs: '2.4rem', lg: '5.8rem' }, alignSelf: { xs: 'start' } }}
        alt="Close-up of hands typing on a computer with message notifications popping up"
      />

      <Stack spacing={10} sx={{ mt: { xs: '-70px', lg: 12 } }}>
        {textsAndImagesCommunication.map(({ title, content, image, contentOrder, alt }) => (
          <TextAndImage
            key={content}
            alt={alt}
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentStyles={{ justifyContent: 'center' }}
            contentSpacing={{ xs: 2, lg: 3 }}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        firstCard={{
          title: 'Traditional Communication',
          sx: { alignItems: 'start' },
          bgcolor: 'white',
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Inconvenient to get in contact with multiple buyers</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Requires effort to safeguard relevant data</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CancelSharpIcon />

                <Typography>Frequent check-ins needed for updates</Typography>
              </Stack>
            </Stack>
          ),
        }}
        secondCard={{
          title: 'With Spona',
          sx: { alignItems: 'start' },
          bgcolor: secondaryOrange[101],
          color: 'black',
          content: (
            <Stack spacing={1.5}>
              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>All data recorded</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>All your contacts on one platform</Typography>
              </Stack>

              <Stack spacing={1.5} direction="row" alignItems="center">
                <CheckCircleSharpIcon sx={{ color: 'secondaryOrange.501' }} />

                <Typography>Automatic notifications for daily project activities</Typography>
              </Stack>
            </Stack>
          ),
        }}
      />

      <BecomeAPartOfSpona
        title="Get started with End to End"
        bgcolor="secondaryYellow.100"
        color="black"
      />
    </Stack>
  </>
);

export default Communication;
