import { Typography, Stack, IconButton } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { EnumCurrency, EnumTimedProjectStatus } from 'tdc-web-backend/enums/enums';
import { convertDate, formatCurrency } from '../../../utils/helpers';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import { EditIcon } from '../../Dashboard/Homescreen/SellerHomescreen/components/CompanyDetailSubsectionItem';
import BaseTable from '../../../components/table/BaseTable';
import useTable from '../../../components/table/useTable';
import CustomButton from '../../../components/button/CustomButton';

const TimedProjectsListTable = () => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'published',
      headerName: 'Published',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Typography>{convertDate(params.value) ?? '-'}</Typography>
      ),
    },
    {
      field: 'pitchCount',
      headerName: 'Pitch Count',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="heading2" sx={{ color: 'primaryDark.700' }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'budget',
      headerName: 'Budget',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="body2" sx={{ color: 'primaryDark.600' }}>
          {formatCurrency(params.value as number, params.row.currency as EnumCurrency)}
        </Typography>
      ),
    },
    {
      field: 'edit',
      headerName: 'Approved',
      flex: 0.1,
      renderCell: (params: GridValueGetterParams) => {
        const showPending = params.row.status === EnumTimedProjectStatus.Pending || params.row.edit;
        return (
          <StatusChipField
            text={
              showPending
                ? EnumTimedProjectStatus.Pending
                : params.row.status === EnumTimedProjectStatus.Draft
                ? EnumTimedProjectStatus.Draft
                : 'approved'
            }
            componentReturnType="dot"
          />
        );
      },
    },
    {
      field: 'editIcon',
      headerName: '',
      flex: 0.03,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridValueGetterParams) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            navigate(`./edit/${params.row.id}`);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const panelConfig = [
    {
      title: 'All',
    },
    {
      title: 'Pending',
      search: { status: EnumTimedProjectStatus.Pending },
    },
    {
      title: 'Active',
      search: { status: EnumTimedProjectStatus.Active },
    },
    {
      title: 'Closed',
      search: { status: EnumTimedProjectStatus.Closed },
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'timed-projects',
  });

  return (
    <Stack direction="column" sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        onRowClick={(params) =>
          params.row.edit ? navigate(`./${params.row.edit}`) : navigate(`./${params.row.content}`)
        }
        withElements={{
          withTitle: 'Projects (hire) (tabs & filters will be added)',
          withCta: (
            <CustomButton onClick={() => navigate('./create-timed-project')}>
              Create timed project
            </CustomButton>
          ),
        }}
        {...props}
      />
    </Stack>
  );
};

export default TimedProjectsListTable;
