/* eslint-disable react/no-unescaped-entities */
import { Paper } from '@material-ui/core';
import {
  Divider, Modal, Stack, Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import Button from '../../../../../components/button/Button';
import { ProjectOverview } from '../ProjectOverview';
import useCreateProjectStore from '../useCreateProjectStore';

export interface StepSecondProps {
    shouldModalOpen: boolean;
    handleCloseModal: (type: boolean) => void;
}

export function StepSecond({ shouldModalOpen, handleCloseModal }: StepSecondProps) {
  const createProjectInput = useCreateProjectStore((state) => state.createProjectInput);

  return (
    <Box>
      <ProjectOverview createProjectInput={createProjectInput} />
      <Modal open={shouldModalOpen} sx={{ width: '30em', margin: '3.5% auto' }}>
        <Paper>
          <Stack spacing={2} sx={{ padding: '2em' }}>
            <Stack spacing={2}>
              <Typography sx={{ fontSize: '1.25em', fontWeight: 600 }}>Confirmation of cooperation</Typography>
              <Typography>
                Your first step for creating a new project has been successfully completed.
                In order for the project to be created, TDC and
                the
                {' '}
                {createProjectInput?.company?.name ?? 'Company name'}
                {' '}
                need to confirm your mutual agreement.
                When the confirmations are done you will be notified
                and you will be able to sign the digital contract and start the project.
              </Typography>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'end' }}>
              <Button onButtonClick={() => handleCloseModal(false)} variant="outlined">Cancel</Button>
              <Button onButtonClick={() => handleCloseModal(true)} variant="contained">Confirm</Button>
            </Stack>
          </Stack>
        </Paper>
      </Modal>
    </Box>
  );
}
