import {
  Typography,
  Popper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Box,
  Button as MuiButton,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import { NavPage } from '../utils';

interface LargeNavPagesProps {
  pages: NavPage[];
}

const LargeNavPages = ({ pages }: LargeNavPagesProps) => {
  const [openMenuItem, setOpenMenuItem] = useState<Record<string, boolean>>({});

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>, id: string) => {
    const clone = { ...openMenuItem };
    clone[id] = !clone[id];
    setOpenMenuItem(clone);
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = (id: string) => {
    const clone = { ...openMenuItem };
    clone[id] = false;
    setOpenMenuItem(clone);
  };

  return (
    <>
      {pages.map((page) => (
        <Box key={page.id}>
          <MuiButton
            onClick={(event) => handleClick(event, page.id)}
            endIcon={openMenuItem[page.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            sx={{ ml: 2 }}
          >
            <Typography variant="bodyMedium1" color="common.black">
              {page.title}
            </Typography>
          </MuiButton>

          <Popper
            open={openMenuItem[page.id] ?? false}
            anchorEl={anchorEl || null}
            placement="bottom-start"
            disablePortal
          >
            <ClickAwayListener onClickAway={() => handleClose(page.id)}>
              <MenuList
                sx={{
                  background: 'white',
                  borderRadius: '8px',
                  boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.2)',
                  p: 0,
                  minWidth: '145px',
                }}
                id={page.id}
              >
                {page.links?.map((link) => (
                  <Link
                    underline="none"
                    key={link.id}
                    onClick={() => navigate(link.to)}
                    color="inherit"
                  >
                    <MenuItem onClick={() => handleClose(page.id)} sx={{ py: 1.5, pr: 8 }}>
                      <Typography variant="bodyMedium2" color="primaryDark.500">
                        {link.title}
                      </Typography>
                    </MenuItem>
                  </Link>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Popper>
        </Box>
      ))}
    </>
  );
};

export default LargeNavPages;
