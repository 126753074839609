import { Box } from '@mui/material';
import { MyAccordionProps } from '../../components/public-screen-components/accordion-groups/AccordionGroups';
import MuseumOfIllusionsLogoSmall from '../../assets/img/public-pages/moi.svg';
import GameboostLogoSmall from '../../assets/img/public-pages/gameboost.svg';
import LightFilmLogoBig from '../../assets/img/public-pages/lightfilm-big.png';
import MuseumOfIllusionsLogoBig from '../../assets/img/public-pages/moi-big.svg';
import BlvckLogoBig from '../../assets/img/public-pages/blvck-big2.png';
import GameboostLogoBig from '../../assets/img/public-pages/gameboost-big.svg';
import flwPr from '../../assets/img/public-pages/flw-pr.svg';
import gruntek from '../../assets/img/public-pages/gruntek.png';
import icestudio from '../../assets/img/public-pages/ice-studio.svg';
import impress from '../../assets/img/public-pages/impress.png';
import museum from '../../assets/img/public-pages/museum-of-illusions.svg';
import weeve from '../../assets/img/public-pages/weeve.svg';
import { Testimonial } from '../../components/public-screen-components/Testimonials';
import office from '../../assets/img/public-pages/office.png';
import office2 from '../../assets/img/public-pages/office2.png';
import office3 from '../../assets/img/public-pages/office3.png';
import office4 from '../../assets/img/public-pages/office4.png';
import office5 from '../../assets/img/public-pages/office5.png';

export const companyLogosLessThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'blvck', width: '55px' },
  { src: GameboostLogoSmall, alt: 'gameboost', width: '55px' },
  { src: LightFilmLogoBig, alt: 'lightfilm', width: '103px' },
  { src: MuseumOfIllusionsLogoSmall, alt: 'museum-of-illusions', width: '55px' },
];

export const companyLogosGreaterThanGreat: { src: string; alt: string; width?: string }[] = [
  { src: BlvckLogoBig, alt: 'blvck', width: '100px' },
  { src: GameboostLogoBig, alt: 'gameboost', width: '100px' },
  { src: LightFilmLogoBig, alt: 'lightfilm', width: '170px' },
  { src: MuseumOfIllusionsLogoBig, alt: 'museum-of-illusions', width: '100px' },
];

export const whyUsArray = [
  {
    subtitle: 'Access Global Talent',
    content:
      'Discover our network of vetted experts in a diverse range of fields. Get inspired by industry-leading projects and build long-lasting connections with companies all over the world.',
  },
  {
    subtitle: 'Reduce Your Stress',
    content:
      'Get a smooth project journey with Spona - from finding a seller, to the hiring process, all the way to project execution, we’ve got you covered with a wide range of user-friendly services. ',
  },
  {
    subtitle: 'Get Platform-Wide Support',
    content:
      'Our team at Spona has extensive experience in seeing projects through their entire life cycle, and will be happy to provide you with the support you need to become our next success story.',
  },
  {
    subtitle: 'Optimize Your Finances',
    content:
      'No more multitasking between multiple platforms, tools and services. Spona lets you standardize and centralize your work so you can focus your money and energy where it is needed the most.',
  },
  {
    subtitle: 'Flexible Payments',
    content:
      'Our flexible payment model lets us accommodate your business to ensure we can provide the best option for you. Make sure that you are getting exactly what you’re paying for with Spona.',
  },
];

export const whyUsCarouselElements = [
  <Box
    src={office}
    component="img"
    width="100%"
    height="100%"
    alt="Creative professional brainstorming ideas with iPad"
  />,
  <Box
    src={office2}
    component="img"
    width="100%"
    height="100%"
    alt="Creative department brainstorming"
  />,
  <Box
    src={office3}
    component="img"
    width="100%"
    height="100%"
    alt="Leadership team reviewing metrics"
  />,
  <Box
    src={office4}
    component="img"
    width="100%"
    height="100%"
    alt="Diverse group of employees working together"
  />,
  <Box
    src={office5}
    component="img"
    width="100%"
    height="100%"
    alt="Close-up of hands while typing on laptop"
  />,
];

export const projectBenefitsArray: MyAccordionProps[] = [
  {
    summary: 'Build relationships',
    details:
      'Posting projects on Spona opens up pitching only to our pool of verified, reputable companies. Sellers receive unique new opportunities, while buyers get a guarantee of their credibility. This is how Spona manages a perfect ecosystem for buyer-seller satisfaction.',
  },
  {
    summary: 'Streamline processes',
    details:
      'Project creation and pitching are structured with tried and tested templates to allow for a quick application-to-selection process. Furthermore, seller profiles and case studies can be integrated into the vetting process, keeping everything centralized on one platform.',
  },
  {
    summary: 'Easy resolutions',
    details:
      'Are you posting a project, or maybe pitching for one? Have you reached an impasse during negotiations? Our team at Spona is fully equipped to provide you with assistance for every step of the process, so that your experience is not only speedy, but also as smooth as possible.',
  },
];

export const testimonialsArray: Testimonial[] = [
  {
    icon: flwPr,
    subject:
      "We have never had a middleman support like we had with Spona. Your project managers provided us with answers to several questions that we had been unable to obtain for months. It's a great support system and a good buffer; if something goes wrong, paying someone a bit more to do your work for you is definitely worth it.",
    author: 'Jennifer Peros',
    title: 'Director of Talent at Famous Last Words PR',
    alt: 'Famous Last Words logo - representing the brand',
  },
  {
    icon: gruntek,
    subject:
      'The platform offers a very quick and efficient service that really does save your time and money. We were a bit strapped for time as the project was being planned during the holiday season and we wanted to kick it off as soon as possible, but thanks to TDA, everything worked out as fast as possible for us.',
    author: 'Nora Mlinarić',
    title: 'PR & Marketing Specialist at Gruntek',
    alt: 'Gruntek logo - representing the brand',
  },
  {
    icon: icestudio,
    subject:
      'There are many benefits of working with Spona, especially payment processing and support, but also the extensive access to new potential clients. The platform is easy to use and Spona has provided us with excellent support during the entire process.',
    author: 'Ivana Cvetković Lakoš',
    title: 'Designer and Creative Director at ICE STUDIO',
    alt: 'iceStudio logo - representing the brand',
  },
  {
    icon: impress,
    subject:
      'We were especially happy with the amount of pitches we received thanks to Spona. Talking with different companies helped us refine and structure our project needs precisely. During the second round of interviews, we were able to find the perfect seller right away, which saved us a lot of time.',
    author: 'Tamara Budhan Caldwell',
    title: 'Founder and CEO at Impress Education',
    alt: 'Impress Education logo - representing the brand',
  },
  {
    icon: museum,
    subject:
      "We enjoyed working with Spona - it was great to get help with centralizing our outsourcing and resolving any issues that might arise. For us, Spona's level of support and the wide pool of diverse talent are the main advantages of using the platform.",
    author: 'Kata Prolić',
    title: 'CMO at Museum of Illusions',
    alt: 'Museum of Illusions logo - representing the brand',
  },
  {
    icon: weeve,
    subject:
      'The Spona team has done a great job supporting us during the project and following up on issues, and saved us resources looking for digital service sellers.',
    author: 'Christos Zachiotis',
    title: 'IoT Solutions Manager at weeve',
    alt: 'Weeve logo - representing the brand',
  },
];

export const forSellersArray: MyAccordionProps[] = [
  {
    summary: 'Extend your reach',
    details:
      'Your Spona profile contains all the essential information you need to create an impressive online presence. Build your image by using platform features like pitching for projects and publishing content and attract business opportunities from all over the world.',
  },
  {
    summary: 'Build a portfolio',
    details:
      'Post case studies of your previous project work to showcase your full expertise. Get reviews from clients and verification from Spona to curate a portfolio which provides easy validation of your credibility to potential buyers.',
  },
  {
    summary: 'Discover projects',
    details:
      'Get a chance to pitch for compelling digital projects before anyone else, courtesy of our community at Spona. Experience a fully digitalized pitching process that provides efficiency and transparency for both parties. For everything else, our support team is at your disposal.',
  },
];
