import FormControl, { useFormControl } from '@mui/material/FormControl';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import BaseInputLabel from '../custom-inputs/BaseInputLabel';
import CustomFormHelperText from './CustomFormHelperText';
import styles from './utils';
import CustomInputContext from './CustomInputContext';
import CustomInputText from './inputs/CustomInputText';
import CustomInputSelect from './inputs/CustomInputSelect';
import CustomInputAutocomplete from './inputs/CustomInputAutocomplete';
import CustomInputUserAgreement from './inputs/CustomInputUserAgreement';
import CustomInputSearchSelect from './inputs/CustomInputSearchSelect';
import { CustomInputProps } from './interface';

const FormHelperText = ({
  helper,
  defaultHelperText,
}: {
  helper?: FieldError | undefined;
  defaultHelperText?: string;
}) => {
  const { error } = useFormControl() || {};

  return (
    <>
      {error && (
        <CustomFormHelperText error={error}>
          {helper?.message || defaultHelperText}
        </CustomFormHelperText>
      )}
    </>
  );
};

const CustomInput = ({
  input,
  name,
  type,
  defaultHelperText,
  required = false,
  customRequiredMessage,
  validationRegEx,
  validate,
  rules,
  label,
  sx,
  adornment,
  placeholder,
  choices,
  withChips,
  multiple,
  disabled = false,
}: CustomInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required ? customRequiredMessage || 'This field is required' : false,
        pattern: validationRegEx,
        validate: (value) => validate?.(value),
        ...rules,
      }}
      render={({ field, fieldState, formState }) => {
        const hasError = fieldState.error !== undefined;
        const context = {
          name,
          field,
          type,
          hasError,
          adornment,
          placeholder,
          useFormContext,
          choices,
          withChips,
          multiple,
          disabled,
        };

        return (
          <CustomInputContext.Provider value={context}>
            <FormControl error={hasError} sx={{ ...styles, ...sx }}>
              {label && <BaseInputLabel text={label} />}

              {input}

              <FormHelperText helper={fieldState.error} defaultHelperText={defaultHelperText} />
            </FormControl>
          </CustomInputContext.Provider>
        );
      }}
    />
  );
};

CustomInput.Text = CustomInputText;
CustomInput.Select = CustomInputSelect;
CustomInput.Autocomplete = CustomInputAutocomplete;
CustomInput.UserAgreement = CustomInputUserAgreement;
CustomInput.SearchSelect = CustomInputSearchSelect;

export default CustomInput;
