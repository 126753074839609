import { Stack, Typography } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { format } from 'date-fns';
import { TaskStatus } from '../../../../components/task-status/TaskStatus';
import { formatCurrency } from '../../../../utils/helpers';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import PaymentActionMenu from './PaymentActionMenu';
import useTable from '../../../../components/table/useTable';
import BaseTable from '../../../../components/table/BaseTable';

export interface PaymentsListProps {
  payments: MilestoneDto[];
}

export function PaymentsList({ payments }: PaymentsListProps) {
  const columns: GridColDef[] = [
    {
      field: 'paymentStatus',
      headerName: 'Status',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => <TaskStatus text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Milestone',
      flex: 0.5,
    },
    {
      field: 'project',
      headerName: 'Project',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <CustomReferenceField
          sx={{ fontSize: '1rem' }}
          resource="projects"
          id={params.value}
          displayName="name"
        />
      ),
    },
    {
      field: 'buyerName',
      headerName: 'Buyer company',
      flex: 0.5,
    },
    {
      field: 'sellerName',
      headerName: 'Seller company',
      flex: 0.5,
    },

    {
      field: 'budget',
      headerName: 'Budget',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '1rem' }}>
          {params.value ? formatCurrency(params.value, params.row.currency) : '-'}
        </Typography>
      ),
    },
    {
      field: 'sellerInvoiceDueDate',
      headerName: 'Due date (seller)',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {params.value !== null ? format(new Date(params.value), 'MM.dd.yyyy') : '-'}
        </Typography>
      ),
    },
    {
      field: 'buyerInvoiceDueDate',
      headerName: 'Due date (buyer)',
      flex: 0.5,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>
          {params.value !== null ? format(new Date(params.value), 'MM.dd.yyyy') : '-'}
        </Typography>
      ),
    },
    {
      field: 'invoiceAction',
      headerName: 'Invoice',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => <PaymentActionMenu params={params} />,
    },
  ];

  const { resourceLimit, ...props } = useTable({
    resourceUrl: 'milestones',
  });

  return (
    <Stack spacing={2}>
      <BaseTable
        disableSelectionOnClick
        numberOfRowsPerPage={resourceLimit}
        columns={columns}
        withElements={{
          withTitle: 'Payments (tabs & filters will be added)',
        }}
        {...props}
      />
    </Stack>
  );
}
