/* eslint-disable import/no-unresolved */
import { Box, Stack, Typography } from '@mui/material';
import { FeaturedCompaniesDto } from 'tdc-web-backend/featured-companies/schema';
import { CompanyDto } from 'tdc-web-backend/companies/schemas';
import CenterCarousel, { SliderWrapperItem } from '../components/CenterCarousel';
import CompaniesPerCategory from '../components/CompaniesPerCategory';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';
import useGetMany from '../../../../utils/hooks/crud-hooks/useGetMany';
import HeroSection from '../components/HeroSection';
import archiveHero from '../../../../assets/img/public-pages/archive-hero.jpg';
import LaptopSection from '../components/LaptopSection';
import macbook from '../../../../assets/img/public-pages/macbook-seller-archive.png';
import BrowseByService from '../components/BrowseByService';
import CustomReferenceField from '../../../../components/custom-fields/CustomReferenceField';
import CompanyCard from '../../../../components/public-screen-components/CompanyCard';

const SellerArchive = () => {
  const { data: response } = useGetMany<FeaturedCompaniesDto>({
    resource: `/featured-companies`,
  });

  const featuredSections = response?.data.results;

  const mainSection = featuredSections?.find((section) => section.main === true);
  const restSections = featuredSections?.filter((section) => section.main !== true);
  return (
    <Stack overflow="hidden" maxWidth="100%" alignSelf="center" mt={`${PUBLIC_APPBAR_HEIGHT}px`}>
      <HeroSection
        imgUrl={archiveHero}
        title="Seller Catalog"
        description="Find the best in the digital service business today"
        link="/seller-subarchive"
        linkText="View all sellers"
      />
      <Stack direction="column" justifyContent="center" alignItems="center" pb="2rem" spacing={1}>
        <Typography variant="publicHeading4">{mainSection?.title}</Typography>
        <Typography
          variant="publicBody5Light"
          sx={{ textAlign: 'center' }}
          maxWidth={{ xs: '90%', sm: '60%', md: '58%', lg: '50%', xl: '30%' }}
        >
          Get to know the service sellers that have gone above and beyond with their unparalleled
          expertise, quality, and project track record.
        </Typography>
      </Stack>
      <Box sx={{ px: '0.625rem', mb: { xs: '5rem', gr: '7.5rem' } }}>
        <CenterCarousel
          data={mainSection?.companies}
          renderCustomComponent={(data) =>
            data?.map((company: Partial<CompanyDto>) => (
              <CustomReferenceField
                resource="companies"
                key={company.id}
                displayName={company.name as string}
                id={company.id as string}
                renderComponent={(item) => (
                  <SliderWrapperItem key={item.id}>
                    <CompanyCard carouselItem companyData={item} key={item.id} />
                  </SliderWrapperItem>
                )}
              />
            ))
          }
        />
      </Box>
      <CompaniesPerCategory
        bgcolor="secondaryOrange.50"
        sectionTitle={restSections?.[0].title as string}
        data={restSections?.[0]}
      />
      <LaptopSection
        imgUrl={macbook}
        title="Find your perfect match"
        description="Check out our full catalog of digital service sellers, filtered by their services, industry
        focus, location, technology expertise, and more."
        link="/seller-subarchive"
        linkText="View all sellers"
        location="seller-archive"
      />
      <CompaniesPerCategory
        sectionTitle={restSections?.[1].title as string}
        data={restSections?.[1]}
      />
      <CompaniesPerCategory
        bgcolor="secondaryOrange.50"
        sectionTitle={restSections?.[2].title as string}
        data={restSections?.[2]}
      />
      <Stack>
        <BrowseByService link="/seller-subarchive" />
      </Stack>
    </Stack>
  );
};
export default SellerArchive;
