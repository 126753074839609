import { Stack } from '@mui/material';
import { secondaryBlue } from '../../../color';
import TextAndImage from '../../../components/public-screen-components/TextAndImage';
import HowMuchDoesItCost from './components/HowMuchDoesItCost';
import HowToManageHero from './components/HowToManageHero';
import TheFutureOfSmartManaging from './components/TheFutureOfSmartManaging';
import { textsAndImagesBuyer, theFutureOfSmartManagingTextAndIcons } from './utils';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  description: `How many tabs do you need to open to see how your projects are going? Manage your projects in one place with Spona's End to End Management tool.`,
};

const HowToManageBuyer = () => (
  <>
    <MetaTags {...metaTags} />
    <Stack overflow="hidden" width="100%">
      <HowToManageHero
        bgcolor={secondaryBlue[501]}
        title="The Ultimate Project Management Tool Is Here"
        subtitle="End-to-End Project Management is our all-in-one project management tool. It is designed to support successful project completion and smooth development with comprehensive features that make all phases of the process streamlined and convenient."
      />

      <Stack spacing={10} sx={{ mt: 12 }}>
        {textsAndImagesBuyer.map(({ title, content, image, contentOrder, contentButton }) => (
          <TextAndImage
            title={title}
            content={content}
            image={image}
            contentOrder={contentOrder}
            contentButton={contentButton}
          />
        ))}
      </Stack>

      <HowMuchDoesItCost
        title="How much does it cost?"
        firstCard={{
          title: 'For Spona users',
          content:
            'End-to-End Project Management is a free service for users that pitched for a project and were hired through Spona, for the duration of that project.',
          bgcolor: 'white',
          color: 'black',
        }}
        secondCard={{
          title: 'For End-to-End Project Management users',
          content:
            'End-to-End Project Management is a paid service for users that already have a contract with a buyer and are interested only in investing in a management tool.',
          bgcolor: secondaryBlue[50],
          color: 'black',
        }}
      />

      <TheFutureOfSmartManaging
        textAndIcons={theFutureOfSmartManagingTextAndIcons(secondaryBlue[501])}
        bgcolor={secondaryBlue[101]}
      />
    </Stack>
  </>
);

export default HowToManageBuyer;
