import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import DefaultHeroImage from '../../assets/img/public-pages/paint-waves.png';
import { ReactComponent as ArrowRightIcon } from '../../assets/icons/project-icons/ArrowRightIcon.svg';
import { PUBLIC_APPBAR_HEIGHT } from '../../layout/public-layout/navigation/Navigation';
import LAYOUT_DEFAULTS from '../../layout/utils';
import CustomButton from '../button/CustomButton';

export interface ReusableHeroSectionProps {
  title: string;
  content: string;
  image?: string;
  contentButton?: {
    text: string;
    color?: string;
    withArrowRightIcon?: boolean;
    onClick: () => void;
  };
  sx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  alt?: string;
  key?: number;
}

const ReusableHeroSection = ({
  title,
  content,
  image = DefaultHeroImage,
  contentButton,
  sx,
  titleSx,
  alt,
}: ReusableHeroSectionProps) => {
  const isScreenHeightMoreThan1200 = useMediaQuery('(min-height:1200px)');

  return (
    <Stack
      spacing={{ xs: 2.5, sm: 7, lg: 0 }}
      sx={{
        ...LAYOUT_DEFAULTS,
        height: isScreenHeightMoreThan1200
          ? '78vh'
          : { xs: `calc(79vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' },
        mb: isScreenHeightMoreThan1200 ? '12%' : 0,
        ...sx,
      }}
      justifyContent={{ xs: 'center', lg: 'end' }}
      alignItems={{ xs: 'center', sm: 'start' }}
    >
      <Typography
        maxWidth="60%"
        sx={{
          typography: { xs: 'publicHeadline9', lg: 'publicHeadline6' },
          pb: { xs: 0, lg: 7 },
          maxWidth: { xs: '100%', lg: '60%' },
          ...titleSx,
        }}
      >
        {title}
      </Typography>

      <Stack
        spacing={2}
        sx={{
          p: { xs: 0, lg: 2 },
          bgcolor: 'common.white',
          width: { xs: '100%', lg: '81%' },
          zIndex: 2,
          boxShadow: { xs: 'none', lg: '1px 10px 30px rgba(56, 69, 114, 0.1)' },
          borderRadius: { xs: 'none', lg: '20px 20px 0px 20px' },
        }}
      >
        <Typography sx={{ typography: { xs: 'publicBody4Light', lg: 'publicBody3Light' } }}>
          {content}
        </Typography>

        {contentButton && (
          <CustomButton
            onClick={() => contentButton.onClick()}
            variant="primaryText"
            endIcon={
              contentButton.withArrowRightIcon ? (
                <ArrowRightIcon fill={contentButton.color} />
              ) : null
            }
            sx={{ color: contentButton.color }}
          >
            {contentButton.text}
          </CustomButton>
        )}
      </Stack>

      <Box
        component="img"
        alt={alt}
        src={image}
        sx={{
          display: { xs: 'none', lg: 'block' },
          width: { xs: '50%', sm: '40%', bg: '25%', lg: '28%', xl: '26%' },
          borderRadius: '20px',
          alignSelf: { sm: 'center' },
          margin: '0 auto!important',
          mt: { xs: '40px !important', sm: '112px !important', lg: 0 },
          left: 0,
          right: 0,
          mr: { lg: '5% !important', xxl: '17% !important' },
          position: { xs: 'relative', lg: 'absolute' },
          zIndex: 1,
        }}
      />
    </Stack>
  );
};

export default ReusableHeroSection;
