import { Card, SxProps, Theme } from '@mui/material';

type Props = {
  sx?: SxProps<Theme>;
  purple?: boolean;
  children: JSX.Element;
};

const PricingCards = ({ sx, purple, children }: Props) => (
  <Card
    sx={{
      p: 5,
      px: { xs: 2, md: 5 },
      boxShadow: purple ? '3px 3px 12px 0px #6D51F666' : '1px 10px 30px 0px #3845721A',
      borderRadius: '20px',
      width: '50%',
      minHeight: '100% !important',
      zIndex: purple ? '2' : '0',
      bgcolor: purple ? 'primaryLight.50' : 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      ...sx,
    }}
  >
    {children}
  </Card>
);

export default PricingCards;
