import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { TabPanel } from '@mui/lab';
import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { SubtaskDto } from 'tdc-web-backend/subtasks/schemas';
import AssistantPhotoOutlinedIcon from '@mui/icons-material/AssistantPhotoOutlined';
import { red } from '@mui/material/colors';
import { CompanyRole } from 'tdc-web-backend/enums/enums';
import { convertDate } from '../../../../utils/helpers';
import SubtaskActionsMenu from './SubtaskActionsMenu';
import SubtasksTableCheckboxController from './SubtasksTableCheckboxController';
import useDetermineCompanyRoles from '../../../../utils/hooks/useDetermineCompanyRoles';

type TablePanelGrid = {
  value: string;
  rows: SubtaskDto[];
  tabIndex: number;
}

// TODO: as in this component's parent is written,
// re-factor this list to be implemented the same way as all lists in end-to-end screens are
// and all of its children components
const SubtasksTablePanelDataGrid = ({
  value,
  rows,
  tabIndex,
}: TablePanelGrid) => {
  const userCompanyRoles = useDetermineCompanyRoles();
  const isUserBuyer = userCompanyRoles?.includes(CompanyRole.Buyer);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: ' ',
      flex: 0.03,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridValueGetterParams) => (
        <SubtasksTableCheckboxController
          className="react-joyride-step-7-task"
          data={params.row}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      editable: true,
      flex: 0.9,
      renderCell: (params: GridValueGetterParams) => (
        <Stack spacing={1} direction="row">
          <Typography sx={{ fontSize: '0.875rem' }}>{params.row.name}</Typography>

          {
            // render the red flag if a current user is buyer and he has flagged a task
            // OR if user is seller and he has flagged a task
            ((isUserBuyer && params.row.buyerFlagged) || (!isUserBuyer && params.row.sellerFlagged))
            && (
              <IconButton
                disableRipple
                color="primary"
                component="span"
                sx={{
                  '&.MuiIconButton-root': {
                    backgroundColor: red[50],
                    padding: 1.25,
                    width: '1rem',
                    height: '1rem',
                  },
                }}
              >
                <AssistantPhotoOutlinedIcon sx={{ height: '1.1rem', color: red[700] }} />
              </IconButton>
            )

          }
        </Stack>
      ),

    },
    {
      field: 'end',
      headerName: 'End date',
      editable: false,
      width: 180,
      renderCell: (params: GridValueGetterParams) => (
        <Typography sx={{ fontSize: '0.875rem' }}>{convertDate(params.value)}</Typography>
      ),
    },
    {
      field: 'menu',
      headerName: '',
      flex: 0.01,
      align: 'center',
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params: GridValueGetterParams) => (<SubtaskActionsMenu params={params} />),
    },
  ];

  const dataGridStyles = {
    height: '400px',
  };

  return (
    <TabPanel
      sx={{
        overflow: 'hidden',
        bgcolor: 'white',
        p: 0,
        borderRadius: '16px',
        border: '1px solid #C5C7C3',
      }}
      value={value}
      tabIndex={tabIndex}
    >
      <DataGrid
        columns={columns}
        rows={rows}
        getRowId={(e) => e.id}
        disableSelectionOnClick
        hideFooter
        sx={dataGridStyles}
      />
    </TabPanel>
  );
};

export default SubtasksTablePanelDataGrid;
