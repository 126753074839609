import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import {
  Card,
  Typography,
} from '@mui/material';
import { MilestoneDto } from 'tdc-web-backend/milestones/schemas';
import { EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { convertDate, formatCurrency } from '../../../../../utils/helpers';
import CustomChip from '../../../../../components/custom-chip/CustomChip';

type TablePanelGrid = {
  rows: MilestoneDto[];
}

const MilestoneDataGrid = ({
  rows,
}: TablePanelGrid) => (
  <Card sx={{
    padding: '16px',
    borderRadius: '16px',
    width: '60%',
  }}
  >
    <Typography sx={{
      fontWeight: 500,
      fontSize: '1.2em',
      marginBottom: '12px',
    }}
    >
      Milestones
    </Typography>
    <DataGrid
      columns={columns}
      rows={rows}
      getRowId={(e) => e.id}
      disableSelectionOnClick
        // onRowClick={(params) => navigate(`./${params.id}`)}
      hideFooter
      sx={{ height: '400px' }}
    />
  </Card>
);

export default MilestoneDataGrid;

export const mapTempMilestoneStatus = new Map([
  [EnumProjectMilestoneStatus.Approved, 'Approved'],
  [EnumProjectMilestoneStatus.Completed, 'Completed'],
  [EnumProjectMilestoneStatus.InProgress, 'In progress'],
  [EnumProjectMilestoneStatus.Negotiation, 'Negotiation'],
  [EnumProjectMilestoneStatus.Pending, 'Pending'],
  [EnumProjectMilestoneStatus.UnderReview, 'Under review'],

]);

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{
        fontSize: '0.875rem',
        fontWeight: 600,
      }}
      >
        {params.row.name}
      </Typography>
    ),

  },
  {
    field: 'status',
    headerName: 'Status',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <CustomChip label={mapTempMilestoneStatus.get(params.value)} />
    ),
  },
  {
    field: 'start',
    headerName: 'Start date',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{ fontSize: '0.875rem', paddingLeft: !params.value ? '38px' : 0 }}>{convertDate(params.value)}</Typography>
    ),
  },
  {
    field: 'end',
    headerName: 'End date',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{ fontSize: '0.875rem', paddingLeft: !params.value ? '38px' : 0 }}>{convertDate(params.value)}</Typography>
    ),
  },
  {
    field: 'budget',
    headerName: 'Budget',
    editable: false,
    flex: 0.3,
    renderCell: (params: GridValueGetterParams) => (
      <Typography sx={{
        fontSize: '0.875rem',
        fontWeight: 600,
      }}
      >
        {formatCurrency(params.value, params.row.currency)}
      </Typography>
    ),

  },
];
