import { Stack, useMediaQuery } from '@mui/material';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { useState } from 'react';
import SponaMakesImage from '../../../assets/img/public-pages/spona-makes.png';
import YouAreYouGet from './components/YouAreYouGet';
import SponaOptions from '../../../components/public-screen-components/SponaOptions';
import ReusableHeroSection from '../../../components/public-screen-components/ReusableHeroSection';
import { primaryLight } from '../../../color';
import CustomModal from '../../../components/modal/CustomModal';
import BecomeAPartOfSpona from '../../../components/public-screen-components/BecomeAPartOfSpona';
import OurPartners from './components/OurPartners';
import CustomButton from '../../../components/button/CustomButton';
import Testimonials from '../../../components/public-screen-components/Testimonials';
import testimonialsArray from './utils';
import SponaMediaHeroImage from '../../../assets/img/public-pages/spona-media-hero.png';
import { PUBLIC_APPBAR_HEIGHT } from '../../../layout/public-layout/navigation/Navigation';
import MetaTags from '../../../components/seo/MetaTags';

const metaTags = {
  description: `Looking for a better way to manage your social media advertising? We offer advertising solutions for Google, Snapchat, Twitter, TikTok and Facebook.`,
};

const SponaMedia = () => {
  const [openModal, setOpenModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const isScreenHeightMoreThan1200 = useMediaQuery('(min-height:1200px)');
  const isScreenWidthMoreThan600 = useMediaQuery('(min-width:600px)');
  const isScreenWidthMoreThan1490 = useMediaQuery('(min-width:1490px)');

  const CalendlyModal = ({ openModal }: { openModal: boolean }) => (
    <CustomModal
      open={openModal}
      width="auto"
      paperStyleProps={{ p: { xs: 0, lg: '2rem' } }}
      sx={{ alignItems: 'end' }}
      onClose={() => setOpenModal(false)}
    >
      <>
        <InlineWidget
          styles={{
            height: isScreenHeightMoreThan1200 ? '600px' : '480px',
            width: isScreenWidthMoreThan1490
              ? '490px'
              : isScreenWidthMoreThan600
              ? '380px'
              : '310px',
          }}
          url={process.env.REACT_APP_CALENDLY_URL as string}
        />
        {showButton && (
          <CustomButton
            onClick={() => {
              setOpenModal(false);
              setShowButton(false);
            }}
          >
            Close
          </CustomButton>
        )}
      </>
    </CustomModal>
  );

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setShowButton(true);
    },
  });

  return (
    <>
      <MetaTags {...metaTags} />
      <Stack overflow="hidden" width="100%">
        {openModal && <CalendlyModal openModal={openModal} />}

        <ReusableHeroSection
          title="Grow Your Advertising"
          content="Interested in enhancing your social media advertising? Whether you’re a business owner with tech verticals, a marketer interested in growing your online presence or an entrepreneur with a long-term vision, Spona Media has the answer for you. Get unique advertising solutions for Google, Snapchat, Twitter, TikTok and Facebook."
          image={SponaMediaHeroImage}
          alt="A person smiling and looking on a side while working on his computer."
          contentButton={{
            text: 'Learn more',
            color: primaryLight[700],
            onClick: () => setOpenModal(true),
            withArrowRightIcon: true,
          }}
          sx={{ height: { xs: `calc(89vh + ${PUBLIC_APPBAR_HEIGHT}px)`, lg: '87vh' } }}
        />

        <SponaOptions
          title="How does it work?"
          optionsProps={[
            {
              title: 'Rapid business growth',
              content:
                'Get access to exclusive ad accounts from day one and increase your spending in step with your growth. The Spona team will support you by making sure your content meets platform requirements, so you can get creative without worrying about compliance.',
              image: SponaMakesImage,
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
            {
              title: 'Comprehensive support',
              content:
                'Spona Media clients have access to full support from the Spona team, from expert tips on how to use certain tools, information about partnered platforms to solutions for any issues. Enjoy unhindered communication with our platform representatives and get all the benefits of on-hand support.',
              image: SponaMakesImage,
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
            {
              title: 'Hassle-free payments',
              content:
                "Low-cost bank transfers let you fully focus on your business, without having to worry about credit card fees and other financial burdens. We also support payments in cryptocurrencies, so you can benefit from Spona Media whether you're a Web2 or Web3 business.",
              image: SponaMakesImage,
              alt: 'Drawing of a growing chart with blurred people at the workplace in the background.',
            },
          ]}
          sx={{ mt: { xs: '-65px', lg: '110px' }, mb: { xs: 3, md: 10 } }}
        />

        <YouAreYouGet />

        <Testimonials
          testimonials={testimonialsArray}
          subtext="Find out what members of our community have to say about their experience with Spona Media."
          sx={{ my: 10 }}
        />

        <BecomeAPartOfSpona
          title="Interested in Spona Media?"
          subtitle="Schedule a meeting and find out what Spona Media can do for your business."
          bgcolor="secondaryYellow.100"
          color="common.black"
          onButtonClick={() => setOpenModal(true)}
        />

        <OurPartners />
      </Stack>
    </>
  );
};
export default SponaMedia;
