import { Typography, Stack } from '@mui/material';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { EnumProjectMilestoneStatus } from 'tdc-web-backend/enums/enums';
import { format } from 'date-fns';
import { calculateProjectProgress, convertDate } from '../../../utils/helpers';
import ProgressBar from '../../../components/progress-bar/ProgressBar';
import StatusChipField from '../../../components/StatusChipField/StatusChipField';
import CustomTable from '../../../components/custom-table/CustomTable';
import useGetLocalStorageUserData from '../../../utils/hooks/useGetLocalStorageUserData';

export interface ProjectListTableProps {
  dataGridHeight?: string | number;
}

const ProjectListTable = ({ dataGridHeight = '300px' }: ProjectListTableProps) => {
  const navigate = useNavigate();
  const userData = useGetLocalStorageUserData();

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => <StatusChipField text={params.value} />,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.3,
      renderCell: (params: GridValueGetterParams) => (
        <Typography variant="body2" fontWeight={600} sx={{ color: 'primaryDark.600' }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'start',
      headerName: 'Start date',
      flex: 0.15,
      renderCell: (params: GridValueGetterParams) => {
        if (params.value !== null) {
          return <Typography>{format(new Date(params.value), 'MM.dd.yyyy')}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'end',
      headerName: 'End date',
      flex: 0.15,
      renderCell: (params: GridValueGetterParams) => {
        if (params.value !== null) {
          return <Typography>{format(new Date(params.value), 'MM.dd.yyyy')}</Typography>;
        }

        return <Typography>-</Typography>;
      },
    },
    {
      field: 'milestoneCompletedCount',
      headerName: 'Progress',
      flex: 0.2,
      renderCell: (params: GridValueGetterParams) => {
        const {
          row: {
            tasksCount,
            milestoneCount,
            tasksCompletedCount,
            tasksCanceledCount,
            milestoneCompletedCount,
            milestoneApprovedCount,
          },
        } = params;
        const percentageOfCompletion = calculateProjectProgress(
          tasksCount,
          milestoneCount,
          tasksCompletedCount,
          tasksCanceledCount,
          milestoneCompletedCount,
          milestoneApprovedCount,
        );

        return (
          <ProgressBar
            value={Number.isFinite(percentageOfCompletion) ? percentageOfCompletion : 0}
          />
        );
      },
    },
  ];

  const panelConfig = [
    {
      id: 'project_list-all',
      title: 'All',
    },
    {
      id: 'project_list-in_progress',
      title: 'In progress',
      search: { status: EnumProjectMilestoneStatus.InProgress },
    },
    {
      id: 'project_list-completed',
      title: 'Completed',
      search: { status: EnumProjectMilestoneStatus.Completed },
    },
    {
      id: 'project_list-pending',
      title: 'Pending',
      search: { status: EnumProjectMilestoneStatus.Pending },
    },
  ];

  return (
    <Stack direction="column" sx={{ height: 'fit-content', width: '100%' }} spacing={2}>
      <CustomTable
        resource="projects"
        columns={columns}
        tabsClassName="react-joyride-step-9"
        className="react-joyride-step-10"
        panelConfig={panelConfig}
        searchParams={{ company: userData?.membership?.company }}
        onRowClick={(params) => navigate(`./project/${params.id}`)}
        height={dataGridHeight as string}
        hideFooter
      />
    </Stack>
  );
};

export default ProjectListTable;
