import { Stack, Typography } from '@mui/material';
import PunctationMarkedText from '../../../../components/public-screen-components/PunctationMarkedText';
import LAYOUT_DEFAULTS from '../../../../layout/utils';

const YouAreYouGet = () => (
  <Stack
    spacing={{ xs: 8, md: 5 }}
    direction={{ xs: 'column', md: 'row' }}
    justifyContent="space-between"
    sx={{ ...LAYOUT_DEFAULTS, mt: 3, mb: 14 }}
  >
    <Stack spacing={3}>
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        You Are...
      </Typography>

      <PunctationMarkedText
        content={[
          'A business that spends $2,000+ per month on paid social ads',
          'Running tried-and-tested ad campaigns, but need more accounts',
          'Promoting white hat products within guidelines',
        ]}
      />
    </Stack>

    <Stack spacing={3}>
      <Typography sx={{ typography: { xs: 'publicHeading4', lg: 'publicHeading3' } }}>
        You Get...
      </Typography>

      <PunctationMarkedText
        content={[
          'On-hand guidance for achieving your advertising goals',
          'Expert support for meeting international compliance standards',
          'Help in scaling operations to extend your reach and optimize results',
        ]}
      />
    </Stack>
  </Stack>
);
export default YouAreYouGet;
