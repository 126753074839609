import { Card, Divider, InputAdornment, Stack, Typography } from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { APPBAR_HEIGHT } from '../../../../../layout/dashboard-navigation/NavigationDashboard';
import ControlledTextInput from '../../../../../components/custom-inputs/ControlledTextInput';
import ControlledTextEditor from '../../../../../components/custom-inputs/ControlledTextEditor';
import { TimedProjectEditorMenu } from '../../../../../components/editor/menu-option-lists/menu-item-lists';
import BaseInputLabel from '../../../../../components/custom-inputs/BaseInputLabel';
import ControlledRatingInput from '../../../../../components/custom-inputs/ControlledRatingInput';
import ControlledCheckboxInput from '../../../../../components/custom-inputs/ControlledCheckboxInput';
import CustomButton from '../../../../../components/button/CustomButton';
import { ReactComponent as ArrowRight } from '../../../../../assets/icons/arrow-right-short.svg';
import { useNavigate, useParams } from 'react-router-dom';
import useCreate from '../../../../../utils/hooks/crud-hooks/useCreate';
import { SubmitCaseStudyReviewDto } from 'tdc-web-backend/case-studies-review/schemas';
import useReferenceOne from '../../../../../utils/hooks/crud-hooks/useReferenceOne';
import { CaseStudyContentDto } from 'tdc-web-backend/case-studies-content/schemas';
import { convertDate } from '../../../../../utils/helpers';
import { useEffect, useState } from 'react';
import ConfirmCaseStudyReviewModal from './ConfirmCaseStudyReviewModal';
import CustomModal from '../../../../../components/modal/CustomModal';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import useGetOne from '../../../../../utils/hooks/crud-hooks/useGetOne';
import { CaseStudy } from 'tdc-web-backend/case-studies/entities';
import PitchFeedbackInput from '../../../TimedProjects/TimedProjectsDetails/components/PitchFeedbackInput';
import CalendlyModal from './components/CalendlyModal';
import ReviewModal from './components/ReviewModal';

const CaseStudyReviewForm = () => {
  const [openModal, setIsOpenModal] = useState(false);

  const form = useForm<SubmitCaseStudyReviewDto>({
    defaultValues: {
      reviewer: {
        firstName: '',
        lastName: '',
        jobTitle: '',
        companyName: '',
        isAnonymous: false,
      },
      summarization: '',
      score: {
        qualityScore: undefined,
        qualityText: '',
        budgetScore: undefined,
        budgetText: '',
        scheduleScore: undefined,
        scheduleText: '',
        recommendationScore: undefined,
        recommendationText: '',
      },
      contactNumber: '',
    },
    mode: 'onChange',
  });
  const { caseStudyId, token } = useParams();

  const { data: caseStudy } = useGetOne<CaseStudy>({
    enabled: !!caseStudyId,
    resource: `case-studies/${caseStudyId}`,
  });

  const { data: caseStudyContent } = useReferenceOne<CaseStudyContentDto>({
    resource: `case-studies-content`,
    enabled: !!caseStudy?.data.content,
    id: caseStudy?.data.content as any,
  });

  useEffect(() => {
    const reviewer = caseStudyContent?.review?.reviewer;
    form.reset({ reviewer: { ...reviewer } });
  }, [caseStudyContent]);

  return (
    <Stack mt={APPBAR_HEIGHT + 'px'} width={'100%'}>
      <FormProvider {...form}>
        <form>
          <Stack px={'10%'} direction={'row'} width={'100%'} justifyContent={'center'}>
            <ScoreInputs data={caseStudyContent as CaseStudyContentDto} />
            <GeneralInputs
              caseStudyId={caseStudyId as string}
              token={token as string}
              contentId={caseStudyContent?.id as string}
            />
          </Stack>
        </form>
      </FormProvider>
      <ConfirmCaseStudyReviewModal isOpen={openModal} setIsOpen={setIsOpenModal} />
    </Stack>
  );
};

export default CaseStudyReviewForm;

const ScoreInputs = ({ data }: { data: CaseStudyContentDto }) => {
  const feedbackFields = [
    {
      title: 'quality',
      description: 'Summarize your working relationship.',
      ratingInputName: 'score.qualityScore',
      textInputName: 'score.qualityText',
    },
    {
      title: 'cost',
      description: 'Overall value and conformance to cost estimates.',
      ratingInputName: 'score.budgetScore',
      textInputName: 'score.budgetText',
    },
    {
      title: 'schedule',
      description: 'Ability to meet deadlines and meet agreed on project.',
      ratingInputName: 'score.scheduleScore',
      textInputName: 'score.scheduleText',
    },
    {
      title: 'Willingness to refer',
      description: 'Willingness to refer colleague to the company.',
      ratingInputName: 'score.recommendationScore',
      textInputName: 'score.recommendationText',
    },
  ];

  return (
    <Stack spacing={1} width={'60%'} sx={{ border: '1px solid', borderColor: 'primaryDark.200' }}>
      <Stack p={4}>
        <Typography variant="heading1" p={1} color={'primaryDark.600'}>
          {data?.title}
        </Typography>
        <Typography variant="body3" p={1} py={3} color={'primaryDark.500'}>
          {convertDate(data?.published)}
        </Typography>
      </Stack>
      <Divider />
      <Stack p={4}>
        <ControlledTextInput
          required
          multiline
          minRows={5}
          name="summarization"
          placeholder="type"
          label="quote"
        />
        {feedbackFields.map((item) => (
          <Stack py={2}>
            <PitchFeedbackInput
              description={item.description}
              title={item.title}
              ratingInputName={item.ratingInputName}
              textInputName={item.textInputName}
              showEditor
            />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const GeneralInputs = ({
  caseStudyId,
  token,
  contentId,
}: {
  caseStudyId: string;
  token: string;
  contentId: string;
}) => {
  const { formState, getValues } = useFormContext();
  const [openModal, setIsOpenModal] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [openReviewModal, setIsOpenReviewModal] = useState(false);

  const handleConfirm = () => {
    setIsOpenModal(true);
    setIsOpenReviewModal(false);
  };

  const { mutate: submit, isLoading } = useCreate({
    resource: `/case-studies/${caseStudyId}/submit-review?token=${token}`,
  });

  const submitReview = () => {
    const data = getValues();
    submit({
      ...data,
      caseStudyContent: contentId,
    });
  };

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      setShowActionBtn(true);
      submitReview();
    },
  });

  return (
    <Stack
      spacing={4}
      width={'40%'}
      sx={{ border: '1px solid', borderColor: 'primaryDark.200' }}
      p={3}
      pt={20}
    >
      <ControlledTextInput
        required
        name="reviewer.firstName"
        placeholder="Add first name"
        label="First name"
      />
      <ControlledTextInput
        required
        name="reviewer.lastName"
        placeholder="Add Last name"
        label="last name"
      />
      <ControlledTextInput
        required
        name="reviewer.jobTitle"
        placeholder="Add job title"
        label="Job title"
      />
      <ControlledTextInput
        required
        name="reviewer.companyName"
        placeholder="Add company name"
        label="Company name"
      />
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'primaryDark.150',
          p: 1,
        }}
      >
        <ControlledCheckboxInput
          name="reviewer.isAnonymous"
          label={
            <Typography ml={1} variant="body3" color="primaryDark.600">
              I prefer to remain anonymous on the public review
            </Typography>
          }
        />
      </Card>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'primaryDark.200',
          p: 2,
        }}
      >
        <Typography ml={1} variant="body3" color="primaryDark.500">
          Please make sure that this information is correct, as it will be displayed publicly
          alongside your review. If you prefer all your details to remain hidden, please check the
          box above.
        </Typography>
      </Card>
      <CalendlyModal open={openModal} showActionBtn={showActionBtn} caseStudyId={caseStudyId} />
      <ReviewModal open={openReviewModal} handleConfirm={handleConfirm} />
      <CustomButton
        disabled={!formState.isValid}
        loading={isLoading}
        onClick={() => setIsOpenReviewModal(true)}
        endIcon={<ArrowRight fill="white" />}
      >
        Submit review
      </CustomButton>
    </Stack>
  );
};
