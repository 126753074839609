import { Tooltip, TooltipProps } from '@mui/material';

type Props = {
  title: string;
  children: JSX.Element;
} & TooltipProps;

const CustomTooltip = ({ title, children, ...props }: Props) => (
  <Tooltip arrow title={title} {...props}>
    {children}
  </Tooltip>
);

export default CustomTooltip;
