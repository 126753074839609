import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { SliderEventCardProps } from '../interface';
import {
  convertDate,
  convertDateToTime,
  formatCurrency,
  truncate,
} from '../../../../utils/helpers';
import { mapEventTypes } from '../../../../enums';
import { EnumCurrency, EnumEventType } from 'tdc-web-backend/enums/enums';

const SliderEventCard = ({ event }: SliderEventCardProps) => {
  return (
    <Card
      elevation={1}
      sx={{
        width: '100%',
        height: '9rem',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'space-between',
        boxShadow: '4px 4px 5px 0px rgba(56, 69, 114, 0.05)',
        p: 2,
        m: 1,
        gap: 2,
        '&:hover': {
          boxShadow: '4px 4px 6px 0px rgba(56, 69, 114, 0.1)',
        },
      }}
    >
      <Stack width={'100%'} gap={1}>
        <Stack
          width={'100%'}
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="heading4" color={'primaryDark.500'}>
            {event?.type && mapEventTypes.get(event.type)}
          </Typography>
          <Typography variant="heading4" color={'primaryDark.700'}>
            {convertDate(event.end)}
          </Typography>
        </Stack>
        <Typography variant="heading4" color={'primaryLight.500'}>
          {event?.projectTitle || event?.name}
        </Typography>
      </Stack>
      <Stack width={'100%'} gap={1}>
        {event.type !== EnumEventType.Event ? (
          <Typography variant="body3" color={'primaryDark.500'}>
            {truncate(event.name, 120)}
          </Typography>
        ) : (
          <Stack direction={'row'} gap={1}>
            <Typography variant="heading4" color={'primaryDark.500'}>
              event time:
            </Typography>
            <Typography variant="body3" color={'primaryDark.500'}>
              {convertDateToTime(event?.start)}
            </Typography>
          </Stack>
        )}
        <Stack width={'100%'} gap={1} direction={'row'}>
          <Typography variant="heading4" color={'primaryDark.500'}>
            Amount due:
          </Typography>
          <Typography variant="heading4" color={'primaryDark.700'}>
            {event.type === EnumEventType.Payment
              ? formatCurrency(event.budget!, EnumCurrency.Usd)
              : ' '}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export default SliderEventCard;
