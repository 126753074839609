import { Typography } from '@mui/material';
import ZagrebImageMobile from '../../../assets/img/public-pages/zagreb-mobile.jpg';
import DublinImageMobile from '../../../assets/img/public-pages/dublin-mobile.jpg';
import UKImageMobile from '../../../assets/img/public-pages/uk-mobile.jpg';
import USAImageMobile from '../../../assets/img/public-pages/usa-mobile.jpg';
import HungaryImageMobile from '../../../assets/img/public-pages/hungary-mobile.jpg';
import ZagrebImage from '../../../assets/img/public-pages/zagreb.jpg';
import DublinImage from '../../../assets/img/public-pages/dublin.jpg';
import UKImage from '../../../assets/img/public-pages/uk.jpg';
import USAImage from '../../../assets/img/public-pages/usa.jpg';
import HungaryImage from '../../../assets/img/public-pages/hungary.jpg';

export interface LocationI {
  id: number;
  state: string;
  address: JSX.Element;
  imgMobile: string;
  img: string;
  alt?: string;
}

const locations: LocationI[] = [
  {
    id: 1,
    state: 'Croatia',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        Ivana Lučića 2a
        <br />
        (Eurotower)
        <br />
        10000, Zagreb,
        <br />
        Croatia
      </Typography>
    ),
    imgMobile: ZagrebImageMobile,
    img: ZagrebImage,
    alt: 'Zagreb, ban Jelačić square',
  },
  {
    id: 2,
    state: 'Ireland',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        43 O'Connell Street Upper North City,
        <br />
        Dublin, D01 DK81 <br />
        <br />
        Ireland
      </Typography>
    ),
    imgMobile: DublinImageMobile,
    img: DublinImage,
    alt: 'Galway, Galway cathedral',
  },
  {
    id: 3,
    state: 'UK',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        I7 Bell Yard <br /> London, <br /> WC2A 2JR <br /> United Kingdom
      </Typography>
    ),
    imgMobile: UKImageMobile,
    img: UKImage,
    alt: 'London, street',
  },
  {
    id: 4,
    state: 'USA',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        111 NE 1st Street, 8th <br /> Floor 88962, <br /> Miami, Florida 33132, <br /> United States
      </Typography>
    ),
    imgMobile: USAImageMobile,
    img: USAImage,
    alt: 'Miami beach',
  },
  {
    id: 5,
    state: 'Hungary',
    address: (
      <Typography variant="publicBody5Regular" textAlign="end">
        71173 Budapest, <br /> Pesti út 237.A/7, <br /> Hungary
      </Typography>
    ),
    imgMobile: HungaryImageMobile,
    img: HungaryImage,
    alt: 'Budapest, Hungary',
  },
];

export default locations;
