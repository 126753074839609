import { Stack, Typography } from '@mui/material';
import HeroImg from '../../../../assets/img/public-pages/how-to-hire-hero.png';
import LAYOUT_DEFAULTS from '../../../../layout/utils';
import { PUBLIC_APPBAR_HEIGHT } from '../../../../layout/public-layout/navigation/Navigation';

const HowToHireHero = () => (
  <Stack
    justifyContent="center"
    sx={{
      ...LAYOUT_DEFAULTS,
      height: {
        xs: `calc(42vh + ${PUBLIC_APPBAR_HEIGHT}px)`,
        sm: `calc(100vh + ${PUBLIC_APPBAR_HEIGHT}px)`,
      },
    }}
  >
    <Stack
      title="Group of colleagues sitting at the desk and discussing business strategy"
      sx={{
        display: { xs: 'none', sm: 'block' },
        backgroundImage: `url(${HeroImg})`,
        height: '70vh',
        boxShadow: { xs: 'none', lg: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
        backgroundSize: 'cover',
        borderRadius: '20px',
        overflow: 'hidden',
      }}
    >
      <Stack
        bgcolor="common.white"
        justifyContent="center"
        sx={{
          width: '50%',
          height: '65%',
          borderBottomRightRadius: '24px',
          boxShadow: '1px 10px 30px 0px rgba(56, 69, 114, 0.10)',
        }}
      >
        <Typography
          sx={{
            typography: { xs: 'publicHeadline9', lg: 'publicHeadline6' },
            px: { xs: '0', lg: '48px' },
          }}
          color="common.black"
          whiteSpace="pre-wrap"
        >
          Get Project Solutions
        </Typography>
      </Stack>
    </Stack>

    <Typography
      sx={{
        display: { xs: 'block', sm: 'none' },
        typography: { xs: 'publicHeadline9', lg: 'publicHeadline6' },
        px: { xs: '0', lg: '48px' },
      }}
      color="common.black"
      whiteSpace="pre-wrap"
    >
      Get Project Solutions
    </Typography>
  </Stack>
);

export default HowToHireHero;
