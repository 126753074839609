import Stack from '@mui/material/Stack';
import { Box, SxProps, Theme } from '@mui/system';
import { IconButton } from '@mui/material';
import EllipseImage from '../../../assets/img/login-ellipse.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left-short.svg';
import SponaDashboardImage from '../../../assets/img/login-carousel-img1.png';
import { primaryDark } from '../../../color';

export interface RegisterAndLoginSharedSectionProps {
  sx?: SxProps<Theme> | undefined;
}

export const RegisterAndLoginSharedSection = ({ sx }: RegisterAndLoginSharedSectionProps) => (
  <Stack
    sx={{
      position: 'relative',
      display: { xs: 'block', lg: 'flex' },
      justifyContent: { xs: 'center' },
      alignItems: { xs: 'center' },
      backgroundColor: '#EA7440',
      height: '100%',
      overflow: { xs: 'hidden', lg: 'visible' },
      ...sx,
    }}
  >
    <IconButton
      href="/"
      sx={{
        display: { xs: 'flex', lg: 'none' },
        backgroundColor: 'common.white',
        alignItems: 'center',
        zIndex: 10,
        top: '10%',
        left: '5%',
        justifyContent: 'center',
        position: 'absolute',
        border: '1px solid',
        borderColor: 'primaryDark.300',
        p: '16px',
      }}
    >
      <ArrowLeft style={{ height: '0.9rem', width: '0.9rem', fill: primaryDark[500] }} />
    </IconButton>

    <Box
      component="img"
      src={EllipseImage}
      sx={{
        position: 'absolute',
        zIndex: 5,
        pointerEvents: 'none',
        top: { xs: '-16%', lg: 'auto', xxl: 'auto' },
        left: { xs: '0%' },
        overflowX: 'hidden',
        height: { xs: '42vh', lg: '75vh', xxl: '70vh' },
        maxHeight: { xs: '100%', lg: '70%', xl: '50%', xxl: '100%' },
        maxWidth: '90%',
        minWidth: '75%',
      }}
    />

    <Box
      component="img"
      src={SponaDashboardImage}
      sx={{
        zIndex: 10,
        position: { xs: 'absolute', lg: 'relative' },
        width: { xs: '81%', sm: '55%', bg: '45%', lg: '50vw' },
        bottom: -25,
        overflowX: 'visible',
        m: '0 auto',
        left: 0,
        right: 0,
      }}
    />
  </Stack>
);

export default RegisterAndLoginSharedSection;
