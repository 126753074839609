/* eslint-disable import/no-unresolved */
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { EnumTimedProjectPitchStatus } from 'tdc-web-backend/enums/enums';
import { TimedProjectPitchContentCustomDto } from 'tdc-web-backend/timed-project-pitch-contents/schemas';
import { ReactComponent as AppliedIcon } from '../../../../../assets/icons/timed-projects-pitches/AppliedIcon.svg';
import { ReactComponent as ChosenIcon } from '../../../../../assets/icons/timed-projects-pitches/ChosenIcon.svg';
import { ReactComponent as ScreeningIcon } from '../../../../../assets/icons/timed-projects-pitches/ScreeningIcon.svg';
import PitchesPanelColumnsContent from './PitchesPanelColumnsContent';
import { deepCopy, dndFindContainer, dndGetPitchById } from '../../../../../utils/helpers';
import PitchesPanelCard from './PitchesPanelCard';
import PitchesPanelColumnsHeader from './PitchesPanelColumnsHeader';
import { primaryDark } from '../../../../../color';
import useUpdate from '../../../../../utils/hooks/crud-hooks/useUpdate';
import { PitchesPanelData } from '../../../../../utils/types';
import ConfirmPitchModal from './ConfirmPitchModal';
import { useRefresh } from '../../../../../utils/hooks/crud-hooks/useRefresh';
import { set } from 'date-fns';

interface PitchesPanelProps {
  pitches: PitchesPanelData;
}

type DndMoveMetaData = {
  pichesBeforeMove: PitchesPanelData | null;
  activePitchId: string | null;
  startContainer: string | null;
  overContainer: string | null;
};

const TimedProjectsDetailsPitchesPanel = ({ pitches: initPitches }: PitchesPanelProps) => {
  const refresh = useRefresh();
  const [pitches, setPitches] = useState<PitchesPanelData>(initPitches);
  const [isOpenConfirmMoveModal, setIsOpenConfirmMoveModal] = useState(false);
  const [dndMoveMetadata, setDndMoveMetadata] = useState<DndMoveMetaData | null>(null);
  const [patchStatus, setPatchStatus] = useState<'idle' | 'loading' | 'succeeded' | 'failed'>(
    'idle',
  );

  const [projectId, setProjectId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const dropAnimation = {
    ...defaultDropAnimation,
  };

  const selectedPitch = dndMoveMetadata?.activePitchId
    ? dndGetPitchById(pitches, dndMoveMetadata?.activePitchId)
    : null;

  useEffect(() => {
    setPitches(initPitches);
  }, [initPitches]);

  const { mutate: mutateUpdatePitch } = useUpdate({
    resource: '/timed-project-pitches',
  });

  const checkAndUpdatePitchStatus = (
    pitch: TimedProjectPitchContentCustomDto,
    overContainer: string,
  ) => {
    if (!pitch) {
      return;
    }
    setPatchStatus('loading');
    const mutateOptions = {
      endpoint: {
        id: pitch.timedProjectPitch,
      },
      callbacks: {
        onError: () => {
          setPatchStatus('failed');
          refresh();
        },
        onSuccess: (data: any) => {
          if (data) {
            setProjectId(data.data.id);
          }
          setPatchStatus('succeeded');
          refresh();
        },
      },
    };

    if (pitch.status === EnumTimedProjectPitchStatus.FirstRound && overContainer === 'round2') {
      pitch.status = EnumTimedProjectPitchStatus.SecondRound;
      mutateUpdatePitch(
        {
          ...mutateOptions.endpoint,
          suffix: 'second-round',
        },
        { ...mutateOptions.callbacks },
      );
    } else if (
      (pitch.status === EnumTimedProjectPitchStatus.FirstRound ||
        pitch.status === EnumTimedProjectPitchStatus.SecondRound) &&
      overContainer === 'round3'
    ) {
      pitch.status = EnumTimedProjectPitchStatus.Chosen;
      mutateUpdatePitch(
        {
          ...mutateOptions.endpoint,
          suffix: 'hire',
        },
        { ...mutateOptions.callbacks },
      );
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDndMoveMetadata({
      pichesBeforeMove: deepCopy<PitchesPanelData>(pitches),
      activePitchId: active.id as string,
      startContainer: active.data.current?.sortable.containerId as string,
      overContainer: null,
    });
  };

  const handleDragOver = ({ over, active }: DragOverEvent) => {
    const activeContainer = dndFindContainer(pitches, active.id as string);
    const overContainer = dndFindContainer(pitches, over?.id as string);

    if (!activeContainer || !overContainer || activeContainer === overContainer) {
      return;
    }
    if (!active.data.current?.supports.includes(overContainer)) {
      return;
    }

    setPitches((pitches: PitchesPanelData) => {
      const activeItems = pitches[activeContainer];
      const overItems = pitches[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        (pitch: TimedProjectPitchContentCustomDto) => pitch.id === active.id,
      );
      const overIndex = overItems.findIndex(
        (pitch: TimedProjectPitchContentCustomDto) => pitch.id !== over?.id,
      );

      return {
        ...pitches,
        [activeContainer]: [
          ...pitches[activeContainer].filter(
            (pitch: TimedProjectPitchContentCustomDto) => pitch.id !== active.id,
          ),
        ],
        [overContainer]: [
          ...pitches[overContainer].slice(0, overIndex),
          pitches[activeContainer][activeIndex],
          ...pitches[overContainer].slice(overIndex, pitches[overContainer].length),
        ],
      };
    });
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    const overContainer = dndFindContainer(pitches, over?.id as string);
    const activeContainer = dndFindContainer(pitches, active.id as string);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer ||
      overContainer === dndMoveMetadata?.startContainer ||
      !active.data.current?.supports.includes(overContainer)
    ) {
      setDndMoveMetadata(null);
      return;
    }

    const activeIndex = pitches[activeContainer].findIndex(
      (pitch: TimedProjectPitchContentCustomDto) => pitch.id === active.id,
    );
    const overIndex = pitches[overContainer].findIndex(
      (pitch: TimedProjectPitchContentCustomDto) => pitch.id === over?.id,
    );

    setDndMoveMetadata({
      ...dndMoveMetadata,
      overContainer,
    } as DndMoveMetaData);

    setPitches((pitches: PitchesPanelData) => ({
      ...pitches,
      [overContainer]: arrayMove(pitches[overContainer], activeIndex, overIndex),
    }));
    setIsOpenConfirmMoveModal(true);
  };

  const handleMoveConfim = () => {
    const pitch = dndMoveMetadata?.activePitchId
      ? dndGetPitchById(pitches, dndMoveMetadata.activePitchId)
      : null;
    if (pitch && dndMoveMetadata?.overContainer) {
      checkAndUpdatePitchStatus(pitch, dndMoveMetadata.overContainer);
    }
  };

  const handleMoveOnClose = (revert: boolean) => {
    setIsOpenConfirmMoveModal(false);
    if (revert && dndMoveMetadata?.pichesBeforeMove) {
      setPitches(deepCopy<PitchesPanelData>(dndMoveMetadata.pichesBeforeMove));
    }
    setDndMoveMetadata(null);
    setPatchStatus('idle');
  };

  return (
    <DndContext
      collisionDetection={closestCorners}
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <Stack spacing={3} direction="row" width="100%" maxWidth="1800px">
        <Stack spacing={3} direction="column" width="33%">
          <PitchesPanelColumnsHeader
            title="first round"
            itemsCount={pitches.round1.length}
            icon={<AppliedIcon style={{ fill: primaryDark[500] }} />}
          />
          <PitchesPanelColumnsContent
            id="round1"
            pitches={pitches.round1}
            disabled={pitches.round3.length > 0}
          />
        </Stack>
        <Stack spacing={3} direction="column" width="33%">
          <PitchesPanelColumnsHeader
            title="second round"
            itemsCount={pitches.round2.length}
            icon={<ScreeningIcon style={{ fill: primaryDark[500] }} />}
          />
          <PitchesPanelColumnsContent
            id="round2"
            pitches={pitches.round2}
            disabled={pitches.round3.length > 0}
          />
        </Stack>
        <Stack spacing={3} direction="column" width="33%">
          <PitchesPanelColumnsHeader
            title="hired"
            itemsCount={pitches.round3.length}
            icon={<ChosenIcon style={{ fill: primaryDark[500] }} />}
            showArrow={false}
          />
          <PitchesPanelColumnsContent
            id="round3"
            pitches={pitches.round3}
            disabled={pitches.round3.length > 0}
          />
        </Stack>
        <DragOverlay dropAnimation={dropAnimation}>
          {selectedPitch ? <PitchesPanelCard pitch={selectedPitch} /> : null}
        </DragOverlay>
      </Stack>
      <ConfirmPitchModal
        type={dndMoveMetadata?.overContainer === 'round3' ? 'hire' : 'confirm'}
        isOpen={isOpenConfirmMoveModal}
        handleOnClose={handleMoveOnClose}
        handleConfirm={handleMoveConfim}
        patchStatus={patchStatus}
        projectId={projectId}
      />
    </DndContext>
  );
};

export default TimedProjectsDetailsPitchesPanel;
