import { Card, Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as NoRecord } from '../../assets/icons/NoRecord.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/layout-icons/CalendarIcon.svg';
import { primaryLight } from '../../color';

interface CustomNoRowsOverlayProps {
  sx?: SxProps<Theme> | undefined;
  type?: 'default' | 'list' | 'events';
}

const CustomNoRowsOverlay = ({ sx, type = 'default' }: CustomNoRowsOverlayProps) => {
  const overlayTypes = {
    default: <DefaultOverlay />,
    events: <EventOverlay />,
    list: <ListOverlay />,
  };
  return (
    <Stack
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        width: '100%',
        height: '100%',
        transform: 'translate(-50%,-50%)',
        background: 'rgba(255, 255, 255, 0.75)',
        ...sx,
      }}
      justifyContent="center"
      alignItems="center"
    >
      {overlayTypes[type]}
    </Stack>
  );
};

export default CustomNoRowsOverlay;

const DefaultOverlay = () => {
  return (
    <Card
      sx={{
        p: 1,
        height: '5rem',
        width: '8rem',
        borderRadius: '0.5rem',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
        border: '1px solid',
        borderColor: 'secondaryBlue.100',
      }}
    >
      <Stack
        spacing={1}
        direction="column"
        alignItems="center"
        width="100%"
        justifyContent="center"
        height="100%"
      >
        <Stack spacing={1} direction="row" alignItems="center" justifyContent="center" width="80%">
          <NoRecord />
          <Stack direction="column" width="80%">
            <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} width="100%" />
            <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} width="50%" />
          </Stack>
        </Stack>
        <Typography variant="body3" color="primaryDark.500">
          No Records
        </Typography>
      </Stack>
    </Card>
  );
};

const EventOverlay = () => {
  return (
    <Stack position={'relative'}>
      <Card
        sx={{
          ...cardStyle,
          transform: 'translate(-50%,-50%)',
          zIndex: 1,
        }}
      >
        <Stack
          spacing={1}
          direction="column"
          alignItems="center"
          width="100%"
          justifyContent="center"
          height="100%"
        >
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="80%"
          >
            <CalendarIcon fill={primaryLight[500]} />
          </Stack>
          <Typography variant="body3" color="primaryDark.500">
            No Events
          </Typography>
        </Stack>
      </Card>
      <Card
        sx={{
          ...cardStyle,
          transform: 'translate(-30%,-30%)',
          zIndex: 0,
        }}
      >
        <Stack direction="column" width="100%">
          <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="90%" />
          <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="100%" />
          <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="90%" />
        </Stack>
      </Card>
    </Stack>
  );
};

const ListOverlay = () => {
  return (
    <Stack position={'relative'}>
      <Card
        sx={{
          ...cardStyle,
          transform: 'translate(-50%,-55%)',
          zIndex: 0,
          width: '12rem',
          height: '3rem',
        }}
      >
        <Stack
          spacing={1}
          direction="column"
          alignItems="start"
          width="100%"
          justifyContent="start"
          height="100%"
        >
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="80%"
          >
            <NoRecord />
            <Stack direction="column" width="60%">
              <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="100%" />
              <Skeleton variant="text" sx={{ fontSize: '0.8rem' }} width="50%" />
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Card
        sx={{
          ...cardStyle,
          transform: 'translate(-40%,-10%)',
          zIndex: 1,
          width: '8rem',
        }}
      >
        <Stack
          spacing={1}
          direction="column"
          alignItems="center"
          width="100%"
          justifyContent="center"
          height="100%"
        >
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            justifyContent="center"
            width="80%"
          >
            <NoRecord />
            <Stack direction="column" width="80%">
              <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} width="100%" />
              <Skeleton variant="text" sx={{ fontSize: '0.5rem' }} width="50%" />
            </Stack>
          </Stack>
          <Typography variant="body3" color="primaryDark.500">
            No Records
          </Typography>
        </Stack>
      </Card>
    </Stack>
  );
};

const cardStyle = {
  p: 1,
  height: '5rem',
  width: '6rem',
  borderRadius: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '1px 10px 30px rgba(56, 69, 114, 0.1)',
  border: '1px solid',
  borderColor: 'secondaryBlue.100',
  position: 'absolute',
  top: '50%',
  left: '50%',
};
