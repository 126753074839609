import { Stack, Typography } from '@mui/material';
import PricingCards from '../components/PricingCard';
import LogoSection from '../components/LogoSection';
import LAYOUT_DEFAULTS from '../../../../layout/utils';

import CustomButton from '../../../../components/button/CustomButton';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/icons/project-icons/ArrowRightIcon.svg';
import { primaryLight } from '../../../../color';
import AccordionGroups, {
  MyAccordionProps,
} from '../../../../components/public-screen-components/accordion-groups/AccordionGroups';
import SelfServiceHire from '../components/SelfServiceHire';
import SponaConnectHire from '../components/SponaConnectHire';

export const hireBuyerArray: MyAccordionProps[] = [
  {
    summary: 'Is there a limit to the number of projects I can post on Spona?',
    details:
      'No, a flat fee is applied per project. You are free to run as many projects as you need to.',
  },
  {
    summary: 'What are the benefits of Spona Connect?',
    details:
      'Spona Connect lets businesses save time and effort by letting a Spona advisor take over the hiring process. We collect your project requirements and curate a list of best-fit candidates - you simply review the pitches and choose your favorite.',
  },
  {
    summary: 'What if I am not satisfied with the pitches?',
    details:
      'You aren’t obliged to move forward with any of the seller pitches if you feel that they aren’t the right fit for your project. Simply ask your Spona Connect advisor to create a new list.',
  },
  {
    summary: 'What are the benefits of managing my project through Spona?',
    details:
      'When you hire a digital service seller through Spona, you are given inclusive access to our End-to-End Project Management tool. Your business can manage the entire lifecycle of the project all in one place - from hiring through to contracting and payments.',
  },
];

const BuyerHireView = () => (
  <>
    <Stack sx={{ ...LAYOUT_DEFAULTS }} alignItems="center">
      <Stack
        direction={{ lg: 'row' }}
        alignItems={{ xs: 'center', lg: 'stretch' }}
        width={{ lg: '65%' }}
        minHeight="100%"
        my={8}
      >
        <PricingCards sx={{ mr: { lg: '-3rem' }, width: '90%' }}>
          <SelfServiceHire />
        </PricingCards>
        <PricingCards purple sx={{ mr: '0px', width: '90%' }}>
          <SponaConnectHire />
        </PricingCards>
      </Stack>
      <LogoSection />
      <Stack justifyContent="space-between" direction={{ lg: 'row' }} gap={10} mt={10}>
        <Stack width="100%" gap={3}>
          <Typography variant="publicHeading3" color="primaryDark.900">
            Frequently asked questions
          </Typography>
          <Typography variant="publicBody4Regular" color="primaryDark.700" width="90%">
            Got more questions? We're here to answer them! Drop us a message any time.
          </Typography>
          <CustomButton
            variant="primaryText"
            endIcon={<ArrowRightIcon fill={primaryLight[500]} />}
            href="/contact-us"
          >
            Contact us
          </CustomButton>
        </Stack>
        <AccordionGroups group={hireBuyerArray} />
      </Stack>
    </Stack>
    <Stack
      justifyContent="space-between"
      width="100%"
      height="100%"
      px={2}
      p={{ lg: 10 }}
      py={10}
      direction={{ lg: 'row' }}
      gap={10}
      mt={10}
      bgcolor="primaryLight.50"
    >
      <Stack width="100%" gap={2}>
        <Typography variant="publicHeading3" color="primaryDark.900">
          Not sure which plan is right for you?
        </Typography>
        <Typography variant="publicBody4Regular" color="primaryDark.700">
          Schedule some time with a Spona advisor and let us talk you through the options
        </Typography>
        <CustomButton
          sx={{ width: 'fit-content' }}
          href={process.env.REACT_APP_CALENDLY_URL as string}
          endIcon={<ArrowRightIcon fill={primaryLight[50]} />}
        >
          Book your demo
        </CustomButton>
      </Stack>
    </Stack>
  </>
);

export default BuyerHireView;
