import { Card, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactComponent as CalendarCheckIcon } from '../../assets/icons/project-icons/CalendarCheckIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/layout-icons/CalendarIcon.svg';
import { convertDate } from '../../utils/helpers';
import { format } from 'date-fns';

type DateDisplayCardProps = {
  variant?: 'start' | 'end';
  date: Date | null;
  text?: string;
  sx?: SxProps<Theme> | undefined;
};

const DateDisplayCard = ({ variant = 'start', date, text, sx }: DateDisplayCardProps) => {
  const textToDisplay = text ?? (variant === 'end' ? 'Due date' : 'Started');
  const sxDefault: SxProps = {
    width: 127,
    height: 75,
    borderRadius: '6px',
    p: '14px',
    bgcolor: '#F9F9F9',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
  };

  const sxFinal = {
    ...sxDefault,
    ...sx,
  };

  return (
    <Card elevation={0} sx={sxFinal}>
      <Stack direction="row" alignItems="center" spacing={1}>
        {variant === 'end' ? <CalendarCheckIcon fill="#79829B" /> : <CalendarIcon fill="#79829B" />}
        <Typography variant="heading4" color="primaryDark.500">
          {textToDisplay}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1} justifyContent="center">
        <Typography variant="heading3" color="primaryDark.600">
          {date ? format(new Date(date), 'MM.dd.yyyy') : '---'}
        </Typography>
      </Stack>
    </Card>
  );
};

export default DateDisplayCard;
